import React from 'react';
import { useGetSharedCollectionsQuery } from '../../graphql/generated/graphql';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas, faUserFriends, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../layout/LoadingSpinner';
import ErrorDisplay from '../layout/ErrorDisplay';
import { Card, Container } from 'react-bootstrap';

const SharedCollections: React.FC = () => {
    const { data, loading, error } = useGetSharedCollectionsQuery();
    const navigate = useNavigate();

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error.message} />;

    if (data?.sharedCollections.length === 0) {
        return (
            <Container className="mt-4">
                <Card className="banner">
                    <h2>No Shared Collections</h2>
                    <p className="mb-4">No one has shared their collections with you yet.</p>
                </Card>
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            <h1 className="section-title mb-4">Shared Collections</h1>
            <div className="list-container">
                <div className="list-grid">
                    {data?.sharedCollections.map((collection) => (
                        <div key={collection.id} className="card d-flex flex-column">
                            <div className="card-header">
                                <h4 className="card-title">{collection.user.handle}&apos;s Collection</h4>
                                <div className="card-meta">
                                    Last updated: {new Date(collection.updatedAt).toLocaleDateString()}
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-between align-items-center mt-auto">
                                <FontAwesomeIcon
                                    icon={collection.publiclyShared ? faGlobeAmericas : faUserFriends}
                                    title={collection.publiclyShared ? "Shared with everyone" : "Shared privately"}
                                    className="text-muted"
                                />
                                <button
                                    className="btn btn-primary"
                                    onClick={() => navigate(`/collections/${collection.user.handle}`)}
                                >
                                    <FontAwesomeIcon icon={faExternalLinkAlt} className="me-2" />
                                    View
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default SharedCollections;
