import React, { useState, useEffect } from 'react';
import { Form, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';

interface CardCountSettingProps {
    label: string;
    value: number;
    onChange: (count: number) => Promise<void>;
    name: string;
}

const CardCountSetting: React.FC<CardCountSettingProps> = ({ label, value, onChange, name }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    // Clear success indicator after animation
    useEffect(() => {
        if (showSuccess) {
            const timer = setTimeout(() => setShowSuccess(false), 1500);
            return () => clearTimeout(timer);
        }
    }, [showSuccess]);

    const handleChange = async (newCount: number) => {
        if (newCount === value) return;

        setIsSaving(true);
        try {
            await onChange(newCount);
            setShowSuccess(true);
        } catch {
            // Could add error handling here
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div style={{ width: '220px' }}>
            <div className="d-flex align-items-center gap-2 mb-2">
                <Form.Label className="mb-0">
                    {label}
                </Form.Label>
                <div style={{ width: '20px', transition: 'opacity 0.15s' }}>
                    {isSaving && (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className="text-primary"
                        />
                    )}
                    {showSuccess && !isSaving && (
                        <FontAwesomeIcon
                            icon={faCheck}
                            className="text-success"
                            style={{
                                animation: 'fadeOut 1.5s ease-in-out'
                            }}
                        />
                    )}
                </div>
            </div>
            <ButtonGroup className="w-full">
                {[0, 1, 2, 3, 4].map(count => (
                    <ToggleButton
                        key={count}
                        id={`${name}-${count}`}
                        type="radio"
                        variant="outline-primary"
                        name={`count-${name}`}
                        value={count}
                        checked={value === count}
                        onChange={() => handleChange(count)}
                        disabled={isSaving}
                        className="flex-1"
                    >
                        {count}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </div>
    );
};

export default CardCountSetting;
