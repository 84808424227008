import React, { createContext, useContext, ReactNode } from 'react'
import { useGetCardSetsQuery } from '../graphql/generated/graphql';
import type { CardSet } from '../types/cardSetType'
import { mapApiCardSetCodeToCardSetCode } from '../utils/apiMapperUtils'

interface CardSetsContextType {
    cardSets: CardSet[] | undefined
    loading: boolean
    error: Error | null
}

const CardSetsContext = createContext<CardSetsContextType | undefined>(undefined)

export function CardSetsProvider({ children }: { children: ReactNode }) {
    const { data, loading, error } = useGetCardSetsQuery()

    // Map GraphQL data to app types
    const cardSets: CardSet[] = data?.cardSets?.nodes
        ?.filter((set): set is NonNullable<typeof set> => Boolean(set))
        .map((set): CardSet => ({
            id: set.id,
            name: set.name,
            code: mapApiCardSetCodeToCardSetCode(set.code),
            description: set.description ?? '',
            imageUrl: set.imageUrl ?? '',
        })) ?? []

    return (
        <CardSetsContext.Provider value={{ cardSets, loading, error: error || null }}>
            {children}
        </CardSetsContext.Provider >
    )
}

export function useCardSets() {
    const context = useContext(CardSetsContext)
    if (context === undefined) {
        throw new Error('useCardSets must be used within a CardSetsProvider')
    }
    return context
}
