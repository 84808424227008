import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import CardPicker from './card_picker/CardPicker';
import EditableSaleListItemsTable from './EditableSaleListItemsTable';
import { SaleListProvider, VariantState } from './SaleListContext';
import { SaleListRoundingType, SaleListSettingsType, SaleListType } from '../../../../types/saleListTypes';
import EditableSaleListSettings from './EditableSaleListSettings';
import { RoundingTypeEnum, useUpdateSaleListMutation } from '../../../../graphql/generated/graphql';
interface SaleListEditorProps {
    effectiveHandle: string | undefined;
    onItemsUpdated?: () => void;
    saleList: SaleListType;
}

const SaleListEditor: React.FC<SaleListEditorProps> = ({
    effectiveHandle,
    onItemsUpdated,
    saleList
}) => {
    const [showModal, setShowModal] = useState(false);
    const [shakeButtons, setShakeButtons] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [isSettingsDirty, setIsSettingsDirty] = useState(false);
    const [settingsKey, setSettingsKey] = useState(0);
    const [pendingSettings, setPendingSettings] = useState<SaleListSettingsType | undefined>();
    const [settingsErrors, setSettingsErrors] = useState<Record<string, string>>({});
    const [updateSaleList] = useUpdateSaleListMutation();
    const handleSettingsDirtyChange = (isDirty: boolean, settings?: SaleListSettingsType) => {
        setIsSettingsDirty(isDirty);
        setPendingSettings(settings);
    };

    const handleDiscardSettings = () => {
        setSettingsKey(k => k + 1);
        setSettingsErrors({});
    };

    const handleSaveSettings = async () => {
        if (!pendingSettings) return;

        try {
            const result = await updateSaleList({
                variables: {
                    input: {
                        saleListId: saleList.id,
                        banner: pendingSettings.banner,
                        marketPricingSettings: pendingSettings.marketPricingSettings ? {
                            percentageAdjustment: pendingSettings.marketPricingSettings.percentageAdjustment,
                            rounding: {
                                type: pendingSettings.marketPricingSettings.rounding.type === SaleListRoundingType.NO_ROUNDING
                                    ? RoundingTypeEnum.NoRounding
                                    : RoundingTypeEnum.Up,
                                value: pendingSettings.marketPricingSettings.rounding.value
                            }
                        } : null
                    }
                }
            });
            if (result.data?.updateSaleList?.success) {
                setSettingsErrors({});
                setIsSettingsDirty(false);
                onItemsUpdated?.();
            } else {
                const newErrors: Record<string, string> = {};
                result.data?.updateSaleList?.errors?.forEach(error => {
                    newErrors[error.field] = error.message;
                });
                setSettingsErrors(newErrors);
            }
        } catch (error) {
            console.error('Error updating sale list:', error);
            setSettingsErrors({ general: 'Failed to update settings' });
        }
    };

    const handleSettingsClick = () => {
        if (isSettingsDirty) {
            setShakeButtons(true);
            setTimeout(() => setShakeButtons(false), 400); // Match animation duration

            return;
        }
        setShowSettings(!showSettings);
    };

    const handleItemsUpdated = () => {
        onItemsUpdated?.();
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    if (!saleList) return null;

    const initialVariants = saleList.cards.reduce((acc, saleCard) => {
        Object.values(saleCard.variants).forEach(variant => {
            if (variant.id) {
                acc[variant.id] = {
                    saleCount: variant.saleCount,
                    priceType: variant.priceType,
                    fixedPriceInCents: variant.fixedPriceInCents || null
                };
            }
        });
        return acc;
    }, {} as Record<string, VariantState>);

    return (
        <SaleListProvider initialVariants={initialVariants}>
            <div>
                <div className="d-flex justify-content-end mb-3">
                    <Button
                        onClick={() => setShowModal(true)}
                        variant="add"
                    >
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        Add Cards
                    </Button>
                </div>

                <div className="mb-4">
                    <div className={`section__title section__title--collapsible ${showSettings ? '' : 'collapsed'} position-relative d-flex`}>
                        <div
                            className={`flex-grow-1 ${showSettings ? '' : 'collapsed'}`}
                            onClick={handleSettingsClick}
                        >
                            Settings
                            {isSettingsDirty && (
                                <span className="ms-2 text-warning">*</span>
                            )}
                        </div>
                        {showSettings && isSettingsDirty && (
                            <div className={`ms-3 ${shakeButtons ? 'shake' : ''}`}>
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={handleDiscardSettings}
                                    title="Discard Changes"
                                    className="me-2"
                                >
                                    <FontAwesomeIcon icon={faXmark} />
                                </Button>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={handleSaveSettings}
                                    title="Save Changes"
                                >
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </div>
                        )}
                    </div>
                    {showSettings && (
                        <div className="section__content p-3">
                            <EditableSaleListSettings
                                key={settingsKey}
                                saleList={saleList}
                                onDirtyStateChange={handleSettingsDirtyChange}
                                errors={settingsErrors}
                            />
                        </div>
                    )}
                </div>

                <div className="section__title">
                    Cards for sale
                </div>

                <EditableSaleListItemsTable
                    saleList={saleList}
                    onItemUpdated={handleItemsUpdated}
                />

                <CardPicker
                    show={showModal}
                    onHide={handleCloseModal}
                    effectiveHandle={effectiveHandle}
                    onItemUpdated={handleItemsUpdated}
                    existingSaleList={saleList}
                />
            </div>
        </SaleListProvider>
    );
};

export default SaleListEditor;
