import React from 'react';
import { orderedVariantTypes, getCardVariantTypeDisplay } from '../../../../utils/cardUtils';
import SaleCard from './SaleCard';
import { SaleCardData } from '../../../../types/saleListTypes';
import { CardVariantType } from '../../../../types/cardType';

interface GroupedSaleCardsProps {
    cards: SaleCardData[];
    wantListVariantIds: Set<string>;
    onCardClick?: (saleCard: SaleCardData) => void;
}

const GroupedSaleCards: React.FC<GroupedSaleCardsProps> = ({ cards, wantListVariantIds, onCardClick }) => {
    const groupedCards = orderedVariantTypes.reduce((acc, variantType) => {
        const cardsWithVariant = cards.filter(card =>
            card.variants[variantType]?.saleCount && card.variants[variantType]?.saleCount > 0
        );

        if (cardsWithVariant.length > 0) {
            acc[variantType] = cardsWithVariant;
        }
        return acc;
    }, {} as Record<CardVariantType, SaleCardData[]>);

    return (
        <div>
            {orderedVariantTypes.map(variantType => {
                const variantCards = groupedCards[variantType];
                if (!variantCards?.length) return null;

                return (
                    <section key={variantType} className="section__subsection">
                        <h3 className="section__subsection-title">
                            {getCardVariantTypeDisplay(variantType)}
                        </h3>
                        <div className="list-grid">
                            {variantCards.map(card => (
                                <SaleCard
                                    key={`${card.card.id}-${variantType}`}
                                    saleCard={card}
                                    wantListVariantIds={wantListVariantIds}
                                    variantType={variantType}
                                    onCardClick={onCardClick}
                                />
                            ))}
                        </div>
                    </section>
                );
            })}
        </div>
    );
};

export default GroupedSaleCards;
