import { useGetAspectsQuery, Aspect } from '../graphql/generated/graphql';
import { useMemo } from 'react';
import { AspectType } from '../types/aspectTypes';
import { mapAspect } from '../utils/apiMapperUtils';

export const useAspects = () => {
    const { data, loading, error } = useGetAspectsQuery();

    const { aspects, aspectImageMap } = useMemo(() => {
        const mappedAspects = data?.aspects.map((aspect: Aspect) => ({
            value: mapAspect(aspect.aspectType),
            label: mapAspect(aspect.aspectType),
            image: aspect.image.url
        })) || [];

        const imageMap = mappedAspects.reduce((acc, aspect) => ({
            ...acc,
            [aspect.value]: aspect.image
        }), {} as Record<AspectType, string>);

        return { aspects: mappedAspects, aspectImageMap: imageMap };
    }, [data]);

    return { aspects, aspectImageMap, loading, error };
};
