import React, { useState, useRef } from 'react';

interface MissingFieldProps {
    initialValue: { count: number | null; isDefault: boolean };
    onUpdate: (newValue: number | null) => Promise<void>;
}

const MissingField: React.FC<MissingFieldProps> = ({ initialValue, onUpdate }) => {
    const [inputValue, setInputValue] = useState(initialValue.isDefault ? '' : initialValue.count?.toString() ?? '');
    const [isDefault, setIsDefault] = useState(initialValue.isDefault);
    const originalStateRef = useRef({ value: '', isDefault: initialValue.isDefault });
    const [status, setStatus] = useState<'idle' | 'saving' | 'success' | 'error'>('idle');

    const handleFocus = () => {
        originalStateRef.current = { value: inputValue, isDefault };
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        if (status === 'error') setStatus('idle');
    };

    const handleBlur = () => {
        if (inputValue === '') {
            setIsDefault(true);
            onUpdate(-1);
        } else {
            const newValue = parseInt(inputValue, 10);
            if (!isNaN(newValue) && newValue >= 0) {
                setIsDefault(false);
                setStatus('saving');
                onUpdate(newValue)
                    .then(() => {
                        setStatus('success');
                        setTimeout(() => setStatus('idle'), 1000);
                    })
                    .catch(() => {
                        setStatus('error');
                        setInputValue(originalStateRef.current.value);
                        setIsDefault(originalStateRef.current.isDefault);

                        inputRef.current?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        });
                    });
            } else {
                // Invalid input, revert to original state
                setInputValue(originalStateRef.current.value);
                setIsDefault(originalStateRef.current.isDefault);
            }
        }
    };

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <input
            ref={inputRef}
            type="number"
            value={inputValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            min="0"
            className={`inventory-edit-input ${status} ${isDefault ? 'missing-default-value' : 'missing-custom-value'}`}
            placeholder={initialValue.count?.toString() ?? ''}
        />
    );
};

export default MissingField;
