import React, { useState } from 'react';
import OfferModal, { CardItem, SelectedCard, VariantItem } from './_shared/OfferModal';
import { OfferTypeEnum, useCreateOfferMutation } from '../../graphql/generated/graphql';
import { SaleListType, SaleListVariantPriceType, CardVariantData } from '../../types/saleListTypes';
import { CardVariantType } from '../../types/cardType';
import { mapSaleListVariantPriceTypeToApi } from '../../utils/apiMapperUtils';

interface CreateBuyOfferModalProps {
    show: boolean;
    onHide: () => void;
    recipientHandle: string;
    saleList: SaleListType;
    wantedCardVariants: Set<string>;
}

const CreateBuyOfferModal: React.FC<CreateBuyOfferModalProps> = ({
    show,
    onHide,
    recipientHandle,
    saleList,
    wantedCardVariants
}) => {
    const [createOffer] = useCreateOfferMutation();
    const [selectedCards, setSelectedCards] = useState<Array<SelectedCard>>([]);
    const [note, setNote] = useState('');

    const handleAddCard = (card: CardItem, variant: VariantItem) => {
        setSelectedCards(prev => [...prev, {
            cardId: card.id,
            cardName: card.name,
            cardNumber: card.standardFullCardNumber,
            variantId: variant.id,
            variantType: variant.type,
            quantity: 1,
            priceType: variant.priceType,
            fixedPriceInCents: variant.fixedPriceInCents,
            marketPriceInCents: variant.marketPriceInCents,
            maxQuantity: variant.quantity
        }]);
    };

    const handleSubmit = async () => {
        try {
            const result = await createOffer({
                variables: {
                    input: {
                        recipientHandle,
                        offerType: OfferTypeEnum.Buy,
                        note,
                        offerItems: selectedCards.map(card => ({
                            variantId: card.variantId,
                            quantity: card.quantity,
                            priceType: mapSaleListVariantPriceTypeToApi(card.priceType),
                            fixedPriceInCents: card.priceType === SaleListVariantPriceType.MARKET
                                ? card.marketPriceInCents
                                : card.priceType === SaleListVariantPriceType.FIXED
                                    ? card.fixedPriceInCents
                                    : null
                        }))
                    }
                }
            });

            if (result.data?.createOffer?.offer) {
                onHide();
            } else if (result.data?.createOffer?.errors) {
                // Handle validation errors
                console.error('Validation errors:', result.data.createOffer.errors);
                // TODO show errors in modal
            }
        } catch (error) {
            // Handle GraphQL/network errors
            console.error('Failed to create offer:', error);
        }
    };

    const availableCards: Array<CardItem> = saleList.cards.map(saleCard => ({
        id: saleCard.card.id,
        name: saleCard.card.name,
        setId: saleCard.card.cardSetId,
        standardFullCardNumber: saleCard.card.standardCardNumber,
        variants: (Object.entries(saleCard.variants) as [CardVariantType, CardVariantData][]).map(([variantType, variant]) => ({
            id: variant.id,
            type: variantType,
            isWanted: wantedCardVariants.has(variant.id),
            quantity: variant.saleCount,
            priceType: variant.priceType,
            fixedPriceInCents: variant.priceType === SaleListVariantPriceType.FIXED ? variant.fixedPriceInCents || 0 : null,
            marketPriceInCents: variant.priceType === SaleListVariantPriceType.MARKET ? variant.marketAdjustedPriceInCents || 0 : null
        }))
    }));

    return (
        <OfferModal
            show={show}
            onHide={onHide}
            title="Create Buy Offer"
            availableCards={availableCards}
            selectedCards={selectedCards}
            onAddCard={handleAddCard}
            onRemoveCard={(variantId) => setSelectedCards(prev => prev.filter(c => c.variantId !== variantId))}
            onQuantityChange={(variantId, quantity) =>
                setSelectedCards(prev => prev.map(c =>
                    c.variantId === variantId ? { ...c, quantity } : c
                ))
            }
            onSubmit={handleSubmit}
            mode="buy"
            onNoteChange={setNote}
        />
    );
};

export default CreateBuyOfferModal;
