import React, { useMemo } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import { CardVariantType } from '../../../types/cardType';
import { UserCardInventory } from '../../../types/inventoryTypes';
import Skeleton from '../../layout/Skeleton';

interface ProgressStats {
    collected: number;
    total: number;
    percentage: number;
}

interface CollectionProgressProps {
    inventory: UserCardInventory[];
    loading: boolean;
}

const CollectionProgressSkeleton: React.FC = () => (
    <Card>
        <Card.Body>
            <Card.Title>Collection Progress</Card.Title>
            <div className="d-flex flex-wrap gap-4">
                {Object.values(CardVariantType).map((variant) => (
                    <div key={variant} className="progress-item">
                        <div className="variant-label">
                            <Skeleton width="60px" height="20px" />
                        </div>
                        <div className="progress rounded-pill">
                            <Skeleton height="100%" />
                        </div>
                        <div className="progress-text">
                            <Skeleton width="80px" height="20px" />
                        </div>
                    </div>
                ))}
            </div>
        </Card.Body>
    </Card>
);

const CollectionProgress: React.FC<CollectionProgressProps> = ({ inventory, loading }) => {
    const stats = useMemo(() => {
        const variantStats = new Map<CardVariantType, ProgressStats>();

        // Initialize stats for each variant type
        Object.values(CardVariantType).forEach(variant => {
            variantStats.set(variant, { collected: 0, total: 0, percentage: 0 });
        });

        // Calculate stats
        inventory.forEach(card => {
            Object.entries(card.inventories).forEach(([variant, details]) => {
                const currentStats = variantStats.get(variant as CardVariantType)!;
                currentStats.total++;
                if (details.missing.count === 0) {
                    currentStats.collected++;
                }
            });
        });

        // Calculate percentages
        variantStats.forEach((stats) => {
            stats.percentage = stats.total > 0 ? (stats.collected / stats.total) * 100 : 0;
        });

        return variantStats;
    }, [inventory]);

    if (loading) {
        return <CollectionProgressSkeleton />;
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Collection Progress</Card.Title>
                <div className="d-flex flex-wrap gap-4">
                    {Array.from(stats.entries()).map(([variant, stats]) => (
                        <div key={variant} className="progress-item">
                            <div className="variant-label">{variant}</div>
                            <ProgressBar
                                now={stats.percentage}
                                label={`${Math.round(stats.percentage)}%`}
                                visuallyHidden
                                className="rounded-pill"
                            />
                            <div className="progress-text">
                                {stats.collected}/{stats.total} ({Math.round(stats.percentage)}%)
                            </div>
                        </div>
                    ))}
                </div>
            </Card.Body>
        </Card>
    );

};

export default CollectionProgress;
