import React from 'react';
import { getCardVariantTypeDisplay } from '../../../../utils/cardUtils';
import { WantListCardData, CardVariantData } from '../../../../types/wantListTypes';
import { CardVariantType } from '../../../../types/cardType';
import { useAspectImages } from '../../../../hooks/useAspectImages';

interface WantCardProps {
    wantedCard: WantListCardData;
    isOwnWantList: boolean;
    variantType?: CardVariantType;
    saleListVariantIds?: Set<string>;
    onCardClick: (card: WantListCardData) => void;
}

const WantCard: React.FC<WantCardProps> = ({ wantedCard, variantType, saleListVariantIds, onCardClick }) => {
    const { getAspectImageUrl, loading: aspectsLoading } = useAspectImages();

    const renderQuantityBadge = () => {
        if (!variantType) return null;

        const count = wantedCard.variants[variantType]?.wantCount;
        if (!count || count <= 1) return null;

        return (
            <div className="want-count-badge">
                ×{count}
            </div>
        );
    };

    const renderVariants = () => {
        if (variantType) {
            const count = wantedCard.variants[variantType]?.wantCount;
            if (!count) return null;

            return count > 1 ? (
                <div className="want-count want-count--grouped">
                    <span className="want-count__value">×{count}</span>
                </div>
            ) : null;
        }

        return (
            <div className="want-card__variants">
                {(Object.entries(wantedCard.variants) as [CardVariantType, CardVariantData][]).map(([variantType, variantData]) => (
                    <span
                        key={variantData.id}
                        className={`variant-pill ${saleListVariantIds?.has(variantData.id) ? 'variant-pill--for-sale' : ''}`}
                    >
                        {getCardVariantTypeDisplay(variantType)}
                        {variantData.wantCount > 1 ? ` (${variantData.wantCount}x)` : ''}
                        {saleListVariantIds?.has(variantData.id) && (
                            <span className="for-sale-badge" title="In your sale list">★</span>
                        )}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <div className={`want-card want-card--grouped clickable ${variantType && wantedCard.variants[variantType]?.id && saleListVariantIds?.has(wantedCard.variants[variantType].id)
            ? 'want-card--for-sale'
            : ''
            }`} onClick={() => onCardClick(wantedCard)}>
            <header className="want-card__header">
                <span className="want-card__number" aria-label="Card Number">
                    {wantedCard.card.standardCardNumber}
                </span>
                <h3 className="want-card__name">
                    {wantedCard.card.name}
                    {variantType && wantedCard.variants[variantType]?.id && saleListVariantIds?.has(wantedCard.variants[variantType].id) && (
                        <span className="for-sale-badge" title="In your sale list">★</span>
                    )}
                </h3>
                <div className="want-card__aspects">
                    {!aspectsLoading && wantedCard.card.aspects?.map((aspect, index) => (
                        <img
                            key={index}
                            src={getAspectImageUrl(aspect)}
                            alt={aspect}
                            title={aspect}
                            className="aspect-icon"
                        />
                    ))}
                </div>

                {renderQuantityBadge()}
            </header>
            {renderVariants()}
        </div>
    );
};

export default WantCard;
