import { Card, CardVariantType } from './cardType';
import { SaleListVariantPriceType } from './saleListTypes';

export enum OfferStatusType {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    RESCINDED = 'RESCINDED',
    DECLINED = 'DECLINED'
}

export interface OfferItem {
    id: string;
    variant: OfferVariantData;
    quantity: number;
    priceType: SaleListVariantPriceType;
    fixedPriceInCents: number;
}

export enum OfferType {
    BUY = 'BUY',
    SELL = 'SELL'
}

export interface Offer {
    id: string;
    offerType: OfferType;
    initiator: User;
    recipient: User;
    status: OfferStatusType;
    offerItems: OfferItem[];
    notes: OfferNote[];
    createdAt: string;
    updatedAt: string;
}

export interface User {
    id: number;
    handle: string;
}

export interface OfferNote {
    id: string;
    content: string;
    user: User;
    createdAt: string;
}

export interface OfferVariantData {
    id: string;
    variantType: CardVariantType;
    card: Card;
}
