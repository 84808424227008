import React, { useState } from 'react';
import CardHover from './CardHover';
import CardModal from './CardModal';
import { Card } from '../../types/cardType';
import { useCards } from '../../contexts/CardContext';
import { useSort } from '../../hooks/useCardSort';
import { CardDetailKeys } from '../../hooks/useSortedCardInventory';
import { useAspectImages } from '../../hooks/useAspectImages';

const columnMap: Record<string, CardDetailKeys> = {
    'Number': 'standardCardNumber',
    'Name': 'name',
    'Rarity': 'rarity',
    'Cost': 'cost',
    'Power': 'power',
    'HP': 'hp',
    'Type': 'cardType',
    'Arena': 'arena',
    'Aspects': 'aspects',
    'Traits': 'traits',
    'Keywords': 'keywords'
} as const;

interface CardTableProps {
    cards: Card[];
}

const CardTable: React.FC<CardTableProps> = ({ cards }) => {
    const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
    const { sortedItems: sortedCards, sortColumn, sortDirection, handleSort } = useSort<Card>(cards, 'standardCardNumber');

    const { getAspectImageUrl, loading: aspectsLoading } = useAspectImages();
    const { cardsById, ensureCardLoaded } = useCards();
    const [isLoadingCard, setIsLoadingCard] = useState(false);

    const handleCardClick = async (card: Card) => {
        setSelectedCardId(card.id);
        setIsLoadingCard(true);
        try {
            await ensureCardLoaded(card.id);
        } finally {
            setIsLoadingCard(false);
        }
    };

    const handleCloseModal = () => {
        setSelectedCardId(null);
    };

    const renderSortIcon = (column: keyof Card) => {
        if (column !== sortColumn) return null;
        return <span className="sort-icon">{sortDirection === 'asc' ? '▲' : '▼'}</span>;
    };

    return (
        <div className="table-container">
            <table className="table">
                <thead className="table-header">
                    <tr>
                        {Object.entries(columnMap).map(([header, sortKey]) => (
                            <th
                                key={header}
                                onClick={() => handleSort(sortKey)}
                                role="button"
                                tabIndex={0}
                                className="table-header-cell"
                            >
                                {header} {renderSortIcon(sortKey)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sortedCards.map((card) => (
                        <tr
                            key={card.id}
                            onClick={() => handleCardClick(card)}
                            className="table-row clickable"
                        >
                            <td className="card-number-cell">
                                <div className="standard-number">{card.standardCardNumber}</div>
                                {card.hyperspaceCardNumber && (
                                    <div className="variant-number">HS: {card.hyperspaceCardNumber}</div>
                                )}
                                {card.showcaseCardNumber && (
                                    <div className="variant-number">SC: {card.showcaseCardNumber}</div>
                                )}
                            </td>
                            <td className="table-cell">
                                <CardHover cardId={card.id}>
                                    <span className="table-link">{card.name}</span>
                                </CardHover>
                            </td>
                            <td className="table-cell">{card.rarity}</td>
                            <td className="table-cell">{card.cost}</td>
                            <td className="table-cell">{card.power}</td>
                            <td className="table-cell">{card.hp}</td>
                            <td className="table-cell">{card.cardType}</td>
                            <td className="table-cell">{card.arena}</td>
                            <td className="table-cell">
                                <div className="aspect-icons">
                                    {!aspectsLoading && card.aspects?.map((aspect, index) => (
                                        <img
                                            key={index}
                                            src={getAspectImageUrl(aspect)}
                                            alt={aspect}
                                            title={aspect}
                                            className="aspect-icon"
                                        />
                                    ))}
                                </div>
                            </td>
                            <td className="table-cell">{card.traits?.join(', ')}</td>
                            <td className="table-cell">{card.keywords?.join(', ')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {selectedCardId && (
                <CardModal
                    card={cardsById[selectedCardId]}
                    loading={isLoadingCard}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default CardTable;
