import { AspectType } from "./aspectTypes"

export type CardImageType = {
    id: string
    url: string
    orientation: OrientationType
}

export enum CardRarity {
    COMMON = 'Common',
    UNCOMMON = 'Uncommon',
    RARE = 'Rare',
    LEGENDARY = 'Legendary',
    SPECIAL = 'Special'
}

export enum CardType {
    LEADER = 'Leader',
    BASE = 'Base',
    UNIT = 'Unit',
    EVENT = 'Event',
    UPGRADE = 'Upgrade',
}

export enum OrientationType {
    LANDSCAPE = 'Landscape',
    PORTRAIT = 'Portrait',
}

export enum ArenaType {
    GROUND = 'Ground',
    SPACE = 'Space'
}

export enum CardVariantType {
    STANDARD = 'Standard',
    FOIL = 'Foil',
    HYPERSPACE = 'Hyperspace',
    HYPERSPACE_FOIL = 'Hyperspace Foil',
    SHOWCASE = 'Showcase'
}

export interface CardVariant {
    id: string
    variantType: CardVariantType
    images: {
        front: CardImageType
        back?: CardImageType
    }
    currentMarketPrice: VariantMarketPrice | null
}

export interface Card {
    id: string
    name: string
    subtitle?: string
    cardType: CardType
    rarity: CardRarity
    cost?: number
    power?: number
    hp?: number
    arena?: ArenaType
    aspects: AspectType[]
    traits: string[]
    frontText?: string
    backText?: string
    unique: boolean
    keywords: string[]
    standardCardNumber: string
    hyperspaceCardNumber: string
    showcaseCardNumber: string
    standardFullCardNumber: string
    cardSetId: string
    images: {
        front: CardImageType
        back?: CardImageType
    }
    variants: Partial<Record<CardVariantType, CardVariant>>
}

export interface VariantMarketPrice {
    priceCents: number;
    recordedAt: string;
}
