import React from 'react';
import CardHover from '../../../../card/CardHover';
import CardVariantCell from './CardVariantCell';
import { CardTableCard } from './CardTable';
import { CardVariantType } from '../../../../../types/cardType';
import { SaleListCardFilterMode } from '../../../../../types/saleListTypes';

interface CardTableRowProps {
    card: CardTableCard;
    variantColumns: CardVariantType[];
    saleListId: string;
    filterMode: SaleListCardFilterMode;
    onItemUpdated: () => void;
}

const CardTableRow: React.FC<CardTableRowProps> = ({
    card,
    variantColumns,
    saleListId,
    filterMode,
    onItemUpdated
}) => {
    return (
        <tr>
            <td>
                {card.standardCardNumber}
            </td>
            <td>
                <CardHover cardId={card.id}>
                    <span>{card.name}</span>
                </CardHover>
            </td>
            {variantColumns.map((variantType) => (
                <CardVariantCell
                    key={variantType}
                    cardName={card.name}
                    variant={card.variants[variantType]}
                    variantType={variantType}
                    saleListId={saleListId}
                    filterMode={filterMode}
                    onItemUpdated={onItemUpdated}
                />
            ))}
        </tr>
    );
};

export default CardTableRow;
