import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Container, Button, Spinner, Dropdown, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faEye, faFileExport, faShare } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../contexts/AuthContext';
import {
    useGetUserCardSetCollectionQuery,
    useUpdateCollectionSharingMutation,
    useGetCollectionShareSettingsLazyQuery,
    UserCardInventory as ApiUserCardInventory,
    useGetUserWantListsQuery
} from '../../../graphql/generated/graphql';
import { useCards } from '../../../contexts/CardContext';
import { useCardSets } from '../../../contexts/CardSetContext';
import { mapApiInventoryToUserCardInventory, mapCardSetCodeToApiCardSetCode } from '../../../utils/apiMapperUtils';
import CardSetTabs from '../../shared/CardSetTabs';
import InventoryCardTable from './InventoryCardTable';
import NotFound from '../../layout/NotFound';
import ErrorDisplay from '../../layout/ErrorDisplay';
import ShareCollectionModal from '../_shared/ShareListModal';
import { useShareSettings } from '../../../hooks/useShareSettings';
import { CardSet, CardSetCodeEnum } from '../../../types/cardSetType';
import { CardVariantType } from '../../../types/cardType';
import { InventoryDetails } from '../../../types/inventoryTypes';
import CardFilters from '../../filters/CardFilters';
import { useCardFilters } from '../../../hooks/useCardFilters';
import { ArenaType } from '../../../types/cardType';
import CollectionProgress from './CollectionProgress';
import { useApolloClient } from '@apollo/client';

const useCardSetSelection = (cardSets: CardSet[]) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const setCodeFromUrl = searchParams.get('set')?.toUpperCase();

    // Get initial state from URL, then localStorage, then default
    const [selectedCardSetCode, setSelectedCardSetCode] = useState<CardSetCodeEnum>(() => {
        if (setCodeFromUrl && Object.values(CardSetCodeEnum).includes(setCodeFromUrl as CardSetCodeEnum)) {
            localStorage.setItem('lastViewedCollectionCardSet', setCodeFromUrl);
            return setCodeFromUrl as CardSetCodeEnum;
        }
        const stored = localStorage.getItem('lastViewedCollectionCardSet');
        if (stored && Object.values(CardSetCodeEnum).includes(stored as CardSetCodeEnum)) {
            return stored as CardSetCodeEnum;
        }
        return CardSetCodeEnum.SOR;
    });

    const handleSetCardSetCode = React.useCallback((code: CardSetCodeEnum) => {
        setSelectedCardSetCode(code);
        setSearchParams({ set: code.toLowerCase() }, { replace: true });
        localStorage.setItem('lastViewedCollectionCardSet', code);
    }, [setSearchParams]);

    useEffect(() => {
        if (cardSets?.length && !selectedCardSetCode) {
            handleSetCardSetCode(cardSets[0]?.code ?? CardSetCodeEnum.SOR);
        }
    }, [cardSets, selectedCardSetCode, handleSetCardSetCode]);

    return [selectedCardSetCode, handleSetCardSetCode] as const;
};

export interface WantListInfo {
    id: string;
    variantIds: Set<string>;
}

const CollectionPage: React.FC = () => {
    const { handle: urlHandle } = useParams<{ handle: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, user } = useAuth();
    const [isEditMode, setIsEditMode] = useState(false);
    const { cardsById, ensureSetLoaded, isLoadingSet, cardsBySetCode } = useCards();
    const [showWantedOnly, setShowWantedOnly] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const client = useApolloClient();
    const [hasInventoryChanges, setHasInventoryChanges] = useState(false);


    const isMyCollection = /^\/collections\/my(\/edit)?$/.test(location.pathname);
    const isOwnCollection = isMyCollection || (isAuthenticated && user?.handle === urlHandle);
    const effectiveHandle = isMyCollection ? user?.handle : urlHandle;

    const { cardSets, loading: cardSetsLoading, error: cardSetsError } = useCardSets();

    const [selectedCardSetCode, setSelectedCardSetCode] = useCardSetSelection(cardSets ?? []);
    const { data: wantListData, loading: wantListLoading, refetch: refetchWantList } = useGetUserWantListsQuery({
        skip: !isAuthenticated || (isOwnCollection && !isEditMode)
    });

    // Load card set data
    useEffect(() => {
        if (selectedCardSetCode) {
            ensureSetLoaded(selectedCardSetCode);
        }
    }, [selectedCardSetCode, ensureSetLoaded]);

    useEffect(() => {
        return () => {
            if (hasInventoryChanges) {
                client.cache.evict({ fieldName: 'userCardSetCollection' });
                client.cache.gc();
            }
        };
    }, [hasInventoryChanges, client.cache]);

    const onInventoryUpdate = useCallback(() => {
        setHasInventoryChanges(true);
    }, []);

    const { loading: inventoryLoading, error: inventoryError, data: inventoryData } = useGetUserCardSetCollectionQuery({
        variables: {
            cardSetCode: mapCardSetCodeToApiCardSetCode(selectedCardSetCode!),
            userHandle: effectiveHandle
        },
        skip: !selectedCardSetCode || (effectiveHandle === undefined),
    });

    // Map inventory data using card cache
    const mappedInventory = useMemo(() => {
        if (!inventoryData?.userCardSetCollection?.nodes) return [];
        if (!selectedCardSetCode) return [];

        // Ensure the set is loaded before mapping
        ensureSetLoaded(selectedCardSetCode);

        // If set isn't loaded yet, return empty array
        if (!cardsBySetCode[selectedCardSetCode]) return [];

        return (inventoryData.userCardSetCollection.nodes as ApiUserCardInventory[])
            .map(node => mapApiInventoryToUserCardInventory(node, cardsById));
    }, [
        inventoryData?.userCardSetCollection?.nodes,
        cardsBySetCode,
        cardsById,
        selectedCardSetCode,
        ensureSetLoaded
    ]);

    const { filteredCards: filteredByTypeCards, filters, updateFilter, rarities, cardTypes, arenas, costs, traits, keywords } = useCardFilters(
        mappedInventory.map(inv => inv.card)
    );

    const wantListInfo: WantListInfo | null = useMemo(() => {
        if (!wantListData?.userWantLists?.[0]) return null;

        const variantIds = new Set<string>();
        wantListData.userWantLists[0].items.nodes?.forEach((item) => {
            const variantId = item?.cardVariant?.id;
            if (variantId) variantIds.add(variantId);
        });

        return {
            id: wantListData.userWantLists[0].id,
            variantIds
        };
    }, [wantListData]);

    const filteredInventory = useMemo(() => {
        // First filter by card types
        const cards = mappedInventory.filter(inv =>
            filteredByTypeCards.some(filteredCard => filteredCard.id === inv.card.id)
        );

        if (wantListLoading) return cards;
        if (!showWantedOnly || isOwnCollection || !wantListInfo) return cards;

        return cards
            .map(userCardInventory => ({
                ...userCardInventory,
                inventories: Object.fromEntries(
                    Object.entries(userCardInventory.inventories)
                        .filter(([, details]) =>
                            wantListInfo.variantIds.has(details.variantId.toString()) &&
                            details.inventory > 0
                        )
                ) as Partial<Record<CardVariantType, InventoryDetails>>
            }))
            .filter(userCardInventory => Object.keys(userCardInventory.inventories).length > 0);
    }, [mappedInventory, showWantedOnly, isOwnCollection, wantListLoading, wantListInfo, filteredByTypeCards]);

    // Sharing functionality
    const [getCollectionShareSettings] = useGetCollectionShareSettingsLazyQuery();
    const [updateCollectionSharing] = useUpdateCollectionSharingMutation();
    const {
        showShareModal,
        setShowShareModal,
        shareError,
        isSharingLoading,
        shareSettings,
        handleShowShareModal,
        handleSaveSharing
    } = useShareSettings({
        getShareSettings: async () => {
            const result = await getCollectionShareSettings({
                fetchPolicy: 'network-only'
            });
            return {
                data: {
                    sharedWithAll: result.data?.userCollection?.shareSettings?.sharedWithAll || false,
                    sharedWithUsers: (result.data?.userCollection?.shareSettings?.sharedWithUsers || []).map(user => ({
                        id: String(user.id),
                        handle: user.handle || '',
                        email: user.email
                    }))
                }
            };
        },
        updateSharing: async (variables) => {
            const result = await updateCollectionSharing({
                variables: {
                    input: {
                        ...variables.input
                    }
                }
            });
            return { data: result.data?.updateCollectionSharing || { success: false } };
        },
        id: undefined,
        idFieldName: ''
    });

    useEffect(() => {
        if (isMyCollection && !isAuthenticated) {
            navigate('/login', { state: { from: location.pathname } });
        }
        setIsEditMode(location.pathname.endsWith('/edit'));
    }, [isMyCollection, isAuthenticated, navigate, location.pathname]);

    const toggleEditMode = () => {
        const newMode = !isEditMode;
        setIsEditMode(newMode);
        navigate(newMode ? `/collections/my/edit` : `/collections/my`);
    };

    if (cardSetsError || inventoryError) {
        return <ErrorDisplay message={cardSetsError?.message || inventoryError?.message || 'An error occurred'} />;
    }

    if (cardSets?.length === 0 && !cardSetsLoading) {
        return <NotFound message="No card sets available" />;
    }

    if (!inventoryData?.userCardSetCollection && !inventoryLoading && !cardSetsLoading) {
        return <NotFound message="Collection not found" />;
    }

    const isLoading = cardSetsLoading || inventoryLoading || isLoadingSet(selectedCardSetCode!) || wantListLoading;
    const collectionOwner = isOwnCollection ? 'My' : `${effectiveHandle}'s`;

    const exportCollection = async (format: 'json' | 'csv') => {
        if (!effectiveHandle) return;

        try {
            const response = await fetch(`/api/export_collection?user_handle=${effectiveHandle}&format=${format}`);
            const blob = await response.blob();
            const filename = getFilenameFromResponse(response, format);
            downloadBlob(blob, filename);
        } catch (error) {
            console.error('Error exporting collection:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const getFilenameFromResponse = (response: Response, format: string): string => {
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="?(.+?)"?(;|$)/i);
            if (filenameMatch) return filenameMatch[1];
        }
        return `${effectiveHandle}_collection_export.${format}`;
    };

    const downloadBlob = (blob: Blob, filename: string) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <Container fluid="lg" className="py-4">
            <Row className="mb-4 align-items-center">
                <Col>
                    <h1 className="mb-0">{collectionOwner} Collection</h1>
                </Col>
                <Col xs="auto">
                    <div className="d-flex gap-2">
                        {isOwnCollection && (
                            <Button
                                onClick={toggleEditMode}
                                disabled={isLoading}
                                variant="outline-primary"
                                className="btn-icon"
                                title={isEditMode ? "View Mode" : "Edit Mode"}
                            >
                                <FontAwesomeIcon icon={isEditMode ? faEye : faPencilAlt} />
                            </Button>
                        )}
                        {isOwnCollection && !isEditMode && user?.handle && (
                            <Button
                                onClick={handleShowShareModal}
                                disabled={isLoading}
                                variant="outline-primary"
                                className="btn-icon"
                                title="Share Collection"
                            >
                                <FontAwesomeIcon icon={faShare} />
                            </Button>
                        )}
                        {!isEditMode && (
                            <Dropdown align="end">
                                <Dropdown.Toggle
                                    variant="outline-primary"
                                    id="dropdown-export"
                                    disabled={isLoading}
                                    title="Export Collection"
                                    className="btn-sm"
                                >
                                    <FontAwesomeIcon icon={faFileExport} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => exportCollection('json')}>
                                        Export as JSON
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => exportCollection('csv')}>
                                        Export as CSV
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                </Col>
            </Row>

            {isOwnCollection && !isEditMode && (
                <Row className="mb-4">
                    <Col>
                        <CollectionProgress inventory={mappedInventory} loading={isLoading} />
                    </Col>
                </Row>
            )}

            <CardSetTabs
                cardSets={cardSets ?? []}
                selectedCardSet={selectedCardSetCode!}
                onSelectCardSet={setSelectedCardSetCode}
                disabled={isLoading}
            />

            {!isEditMode && (
                <>
                    <div className="d-flex mb-3">
                        <Button
                            onClick={() => setFiltersOpen(!filtersOpen)}
                            aria-controls="game-card-filters"
                            aria-expanded={filtersOpen}
                            variant="outline-primary"
                        >
                            {filtersOpen ? 'Hide Filters' : 'Show Filters'}
                        </Button>
                    </div>

                    <CardFilters
                        filters={filters}
                        updateFilter={updateFilter}
                        rarities={rarities || []}
                        cardTypes={cardTypes}
                        arenas={arenas as ArenaType[]}
                        costs={costs as number[]}
                        traits={traits || []}
                        keywords={keywords || []}
                        disabled={false}
                        isOpen={filtersOpen}
                        onClose={() => setFiltersOpen(false)}
                    />
                </>
            )}

            {!isOwnCollection && isAuthenticated && user?.handle && wantListInfo && wantListInfo.variantIds.size > 0 && (
                <div className="d-flex justify-content-end mb-3">
                    <Form.Check
                        type="switch"
                        id="wanted-only-switch"
                        label="Show Wanted Cards With Inventory Only"
                        checked={showWantedOnly}
                        disabled={wantListLoading}
                        onChange={(e) => setShowWantedOnly(e.target.checked)}
                    />
                </div>
            )}

            <div className="position-relative min-vh-50">
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center p-5">
                        <Spinner animation="border" variant="primary" />
                    </div>
                ) : mappedInventory.length > 0 ? (
                    <InventoryCardTable
                        cards={filteredInventory}
                        isEditMode={isEditMode}
                        isOwner={isOwnCollection}
                        wantListInfo={wantListInfo}
                        onWantListUpdate={refetchWantList}
                        onInventoryUpdate={onInventoryUpdate}
                    />
                ) : null}
            </div>

            <ShareCollectionModal
                show={showShareModal}
                onHide={() => setShowShareModal(false)}
                initialSharedWithAll={shareSettings.sharedWithAll}
                onSave={handleSaveSharing}
                isSaving={isSharingLoading}
                error={shareError}
                sharedWithUsers={shareSettings.sharedWithUsers}
                shareableLink={`${window.location.origin}/collections/${effectiveHandle}`}
            />
        </Container>
    );
};

export default CollectionPage;
