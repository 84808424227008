import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { SortColumn } from '../../../hooks/useSortedCardInventory';
import { variantColumns } from './InventoryCardTable';
import { SortDirection } from '../../../hooks/useCardSort';


interface InventoryCardTableHeaderProps {
    sortColumn: SortColumn;
    sortDirection: SortDirection;
    onSort: (column: SortColumn) => void;
}


export const InventoryCardTableHeader: React.FC<InventoryCardTableHeaderProps> = ({ sortColumn, sortDirection, onSort }) => {
    const renderSortIcon = (column: SortColumn) => {
        if (column === sortColumn) {
            return <FontAwesomeIcon icon={sortDirection === 'asc' ? faSortUp : faSortDown} className="ms-1" />;
        }
        return null;
    };

    return (
        <thead>
            <tr>
                <th onClick={() => onSort('standardCardNumber')}>Number {renderSortIcon('standardCardNumber')}</th>
                <th onClick={() => onSort('name')}>Name {renderSortIcon('name')}</th>
                {variantColumns.map(({ label, sortKey }) => (
                    <th key={label} onClick={() => onSort(sortKey)}>
                        {label} {renderSortIcon(sortKey)}
                    </th>
                ))}
            </tr>
        </thead>
    );
};
