import React from 'react';
import { ButtonGroup, Col, Row, Form, OverlayTrigger, Tooltip, ToggleButton } from 'react-bootstrap';
import { SaleListCardFilterMode } from '../../../../../types/saleListTypes';

interface CardSearchProps {
    searchTerm: string;
    onSearchChange: (term: string) => void;
    filterMode: SaleListCardFilterMode;
    onFilterModeChange: (mode: SaleListCardFilterMode) => void;
    selectedSet: string;
}

const CardSearch: React.FC<CardSearchProps> = ({
    searchTerm,
    onSearchChange,
    filterMode,
    onFilterModeChange,
    selectedSet
}) => {
    const filtersDisabled = !selectedSet;

    const filterOptions = [
        {
            name: 'All',
            value: SaleListCardFilterMode.All,
            tooltip: 'Show all cards in the selected set'
        },
        {
            name: 'Own',
            value: SaleListCardFilterMode.Own,
            tooltip: 'Show only cards you own from this set'
        },
        {
            name: 'Extra',
            value: SaleListCardFilterMode.Extra,
            tooltip: 'Show only cards you have extras of (more than playset)'
        },
    ];

    return (
        <Row className="g-3">
            <Col xs={12}>
                <Form.Control
                    type="text"
                    placeholder="Search for cards..."
                    value={searchTerm}
                    onChange={(e) => onSearchChange(e.target.value)}
                    className={filtersDisabled ? 'disabled' : ''}
                    disabled={filtersDisabled}
                />
            </Col>
            <Col xs={12} md="auto">
                <ButtonGroup>
                    {filterOptions.map((option) => (
                        <OverlayTrigger
                            key={option.value}
                            placement="top"
                            overlay={<Tooltip>{option.tooltip}</Tooltip>}
                        >
                            <ToggleButton
                                key={option.value}
                                id={`filter-${option.value}`}
                                type="radio"
                                variant="outline-primary"
                                name="filter"
                                value={option.value}
                                checked={filterMode === option.value}
                                onChange={(e) => onFilterModeChange(e.currentTarget.value as SaleListCardFilterMode)}
                                disabled={filtersDisabled}
                            >
                                {option.name}
                            </ToggleButton>
                        </OverlayTrigger>
                    ))}
                </ButtonGroup>
            </Col>
        </Row>
    );
};

export default CardSearch;
