import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CardSetSelector from '../../../_shared/card_picker/CardSetSelector';
import CardSearch from './CardSearch';
import CardTable from './CardTable';
import { CardSetCodeEnum } from '../../../../../types/cardSetType';
import { SaleListType } from '../../../../../types/saleListTypes';
import { SaleListCardFilterMode } from '../../../../../types/saleListTypes';

interface CardPickerProps {
    show: boolean;
    onHide: () => void;
    effectiveHandle: string | undefined;
    onItemUpdated: () => void;
    existingSaleList: SaleListType;
}

const CardPicker: React.FC<CardPickerProps> = ({
    show,
    onHide,
    effectiveHandle,
    onItemUpdated,
    existingSaleList
}) => {
    const [selectedSet, setSelectedSet] = useState(() => {
        const saved = localStorage.getItem('saleListCardPickerLastSet')
        return saved ? saved as CardSetCodeEnum : CardSetCodeEnum.SOR
    })
    const [searchTerm, setSearchTerm] = useState('')
    const [filterMode, setFilterMode] = useState(SaleListCardFilterMode.All)

    const onSelectSet = (set: CardSetCodeEnum) => {
        setSelectedSet(set)
        localStorage.setItem('saleListCardPickerLastSet', set)
    }

    const onSearchChange = (term: string) => {
        setSearchTerm(term)
    }

    const onFilterModeChange = (mode: SaleListCardFilterMode) => {
        setFilterMode(mode)
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Cards to Sale List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <CardSetSelector
                        selectedSet={selectedSet ?? CardSetCodeEnum.SOR}
                        onSelectSet={onSelectSet}
                    />
                </div>
                <div className="mb-4">
                    <CardSearch
                        selectedSet={selectedSet ?? CardSetCodeEnum.SOR}
                        searchTerm={searchTerm}
                        onSearchChange={onSearchChange}
                        filterMode={filterMode}
                        onFilterModeChange={onFilterModeChange}
                    />
                </div>

                <CardTable
                    selectedSet={selectedSet ?? CardSetCodeEnum.SOR}
                    searchTerm={searchTerm}
                    filterMode={filterMode}
                    effectiveHandle={effectiveHandle}
                    onItemUpdated={onItemUpdated}
                    existingSaleList={existingSaleList}
                />

            </Modal.Body>
        </Modal>
    );
};

export default CardPicker;
