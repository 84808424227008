import React from 'react';
import CardSetTabs from '../../../shared/CardSetTabs';
import { useCardSets } from '../../../../contexts/CardSetContext';
import { CardSetCodeEnum } from '../../../../types/cardSetType';

interface CardSetSelectorProps {
    selectedSet: CardSetCodeEnum;
    onSelectSet: (set: CardSetCodeEnum) => void;
}

const CardSetSelector: React.FC<CardSetSelectorProps> = ({ selectedSet, onSelectSet }) => {
    const { cardSets, loading } = useCardSets();

    return (
        <div className="card-set-tabs-container mb-4">
            <CardSetTabs
                cardSets={cardSets ?? []}
                selectedCardSet={selectedSet as CardSetCodeEnum}
                onSelectCardSet={onSelectSet}
                disabled={loading}
            />
        </div>
    );
};

export default CardSetSelector;
