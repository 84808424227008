import React from 'react';
import { Form, Button } from 'react-bootstrap';
import AspectFilter from './AspectFilter';
import RarityFilter from './RarityFilter';
import TextFilter from './TextFilter';
import CostFilter from './CostFilter';
import { FilterOptions, FilterValue } from '../../types/cardFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import TraitFilter from './TraitFilter';
import KeywordFilter from './KeywordFilter';
import CardTypeFilter from './CardTypeFilter';
import ArenaFilter from './ArenaFilter';
import { ArenaType } from '../../types/cardType';
import { CardType } from '../../types/cardType';

interface CardFiltersProps {
    filters: FilterOptions;
    updateFilter: (key: keyof FilterOptions, value: FilterValue) => void;
    rarities: string[];
    cardTypes: CardType[];
    arenas: ArenaType[];
    costs: number[];
    traits: string[];
    keywords: string[];
    disabled?: boolean;
    isOpen: boolean;
    onClose: () => void;
}

const CardFilters: React.FC<CardFiltersProps> = ({ filters, updateFilter, rarities, cardTypes, arenas, costs, traits, keywords, disabled, isOpen, onClose }) => {
    return (
        <>
            <div className={`game-card-filters__backdrop ${isOpen ? 'open' : ''}`} onClick={onClose} />
            <div className={`game-card-filters ${isOpen ? 'open' : ''} scrollable-content`}>
                <Form onSubmit={(e) => e.preventDefault()}>
                    <Form.Group className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="mb-0">Filter Cards</h5>
                        <Button
                            variant="link"
                            className="form__close-btn"
                            onClick={onClose}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </Form.Group>
                    <TextFilter
                        value={filters.searchText || ''}
                        onChange={(value) => updateFilter('searchText', value)}
                        disabled={disabled}
                    />
                    <RarityFilter
                        rarities={rarities || []}
                        selected={filters.selectedRarities || []}
                        onChange={(selected) => updateFilter('selectedRarities', selected)}
                        disabled={disabled}
                    />
                    <CardTypeFilter
                        cardTypes={cardTypes}
                        selected={filters.selectedCardTypes}
                        onChange={(selected) => updateFilter('selectedCardTypes', selected)}
                        disabled={disabled}
                    />

                    <ArenaFilter
                        arenas={arenas}
                        selected={filters.selectedArenas}
                        onChange={(selected) => updateFilter('selectedArenas', selected)}
                        disabled={disabled}
                    />

                    <AspectFilter
                        selected={filters.selectedAspects || []}
                        required={filters.requiredAspects || []}
                        onChange={(selected, required) => {
                            updateFilter('selectedAspects', selected);
                            updateFilter('requiredAspects', required);
                        }}
                        disabled={disabled}
                    />

                    <CostFilter
                        costs={costs || []}
                        selectedCosts={filters.selectedCosts || []}
                        onChange={(selectedCosts) => updateFilter('selectedCosts', selectedCosts)}
                        disabled={disabled}
                    />

                    <TraitFilter
                        traits={traits || []}
                        selected={filters.selectedTraits || []}
                        onChange={(selected) => updateFilter('selectedTraits', selected)}
                        disabled={disabled}
                    />

                    <KeywordFilter
                        keywords={keywords || []}
                        selected={filters.selectedKeywords || []}
                        onChange={(selected) => updateFilter('selectedKeywords', selected)}
                        disabled={disabled}
                    />
                </Form>
            </div>
        </>
    );
};

export default CardFilters;
