import { useSort } from './useCardSort';
import { UserCardInventory } from '../types/inventoryTypes';
import { CardVariantType } from '../types/cardType';

export type CardDetailKeys = 'name' | 'standardCardNumber' | 'rarity' | 'cardType' | 'aspects' | 'traits' | 'keywords' | 'power' | 'hp' | 'cost' | 'arena';
type VariantInventoryKeys = `${CardVariantType}Inventory`;
export type SortColumn = CardDetailKeys | VariantInventoryKeys;

export const useSortedCardInventory = (cards: UserCardInventory[]) => {
    return useSort<UserCardInventory>(cards, 'standardCardNumber');
};
