import { useMemo, useState, useCallback } from 'react';
import { FilterOptions, FilterValue } from '../types/cardFilters';
import { ArenaType } from '../types/cardType';
import { CardRarity, CardType } from '../types/cardType';
import { Card } from '../types/cardType';
import { AspectType } from '../types/aspectTypes';

export type FilterAspectType = AspectType | 'NO_ASPECT';
export const FilterAspectType = {
    NO_ASPECT: 'NO_ASPECT',
    ...AspectType
} as const;

type FilterFunction = (card: Card, filters: FilterOptions) => boolean;

const defaultFilters: FilterOptions = {
    searchText: '',
    selectedRarities: [],
    selectedCardTypes: [],
    selectedArenas: [],
    selectedCosts: [],
    selectedAspects: [],
    requiredAspects: [],
    selectedTraits: [],
    selectedKeywords: []
};

export const useCardFilters = (cards: Card[], customFilters: FilterFunction[] = []) => {
    const [filters, setFilters] = useState<FilterOptions>(defaultFilters);

    const textFilter = useCallback((card: Card, searchText: string) => {
        const lowercaseText = searchText.toLowerCase();
        return !searchText ||
            card.name.toLowerCase().includes(lowercaseText) ||
            card.subtitle?.toLowerCase().includes(lowercaseText);
    }, []);

    const rarityFilter = useCallback((card: Card, selectedRarities: CardRarity[]) => {
        return selectedRarities.length === 0 || selectedRarities.includes(card.rarity);
    }, []);

    const cardTypeFilter = useCallback((card: Card, selectedCardTypes: CardType[]) => {
        return selectedCardTypes.length === 0 || selectedCardTypes.includes(card.cardType);
    }, []);

    const arenaFilter = useCallback((card: Card, selectedArenas: ArenaType[]) => {
        return selectedArenas.length === 0 || (card.arena && selectedArenas.includes(card.arena));
    }, []);

    const costFilter = useCallback((card: Card, selectedCosts: number[]) => {
        return selectedCosts.length === 0 || selectedCosts.includes(card.cost ?? 0);
    }, []);

    const aspectFilter = useCallback((card: Card, selectedAspects: FilterAspectType[], requiredAspects: FilterAspectType[]) => {
        if (selectedAspects.length === 0 && requiredAspects.length === 0) {
            return true;
        }

        // Treat a card's aspects as including NO_ASPECT if it has no aspects
        const cardAspects: FilterAspectType[] = card.aspects?.length
            ? [...card.aspects as unknown as FilterAspectType[]]
            : [FilterAspectType.NO_ASPECT];

        // Required aspects must ALL be present (AND)
        const hasRequiredAspects = requiredAspects.every(aspect => cardAspects.includes(aspect));
        if (!hasRequiredAspects) return false;

        // If no selected aspects, any card with ONLY required aspects should pass
        if (selectedAspects.length === 0) {
            return true;
        }

        // For cards with required aspects, they must ALSO have ONLY selected or required aspects
        return cardAspects.every(aspect =>
            requiredAspects.includes(aspect) || selectedAspects.includes(aspect)
        );
    }, []);

    const traitFilter = useCallback((card: Card, selectedTraits: string[]) => {
        return selectedTraits.length === 0 || selectedTraits.every(trait => card.traits?.includes(trait));
    }, []);

    const keywordFilter = useCallback((card: Card, selectedKeywords: string[]) => {
        return selectedKeywords.length === 0 || selectedKeywords.every(keyword => card.keywords?.includes(keyword));
    }, []);

    const filteredCards = useMemo(() => {
        return cards.filter(card => {
            const baseFiltersPass =
                textFilter(card, filters.searchText) &&
                rarityFilter(card, filters.selectedRarities) &&
                cardTypeFilter(card, filters.selectedCardTypes) &&
                arenaFilter(card, filters.selectedArenas) &&
                costFilter(card, filters.selectedCosts) &&
                aspectFilter(card, filters.selectedAspects, filters.requiredAspects) &&
                traitFilter(card, filters.selectedTraits) &&
                keywordFilter(card, filters.selectedKeywords);

            return baseFiltersPass && customFilters.every(filter => filter(card, filters));
        });
    }, [cards, filters, textFilter, rarityFilter, cardTypeFilter, arenaFilter, costFilter, aspectFilter, traitFilter, keywordFilter, customFilters]);

    const updateFilter = useCallback((key: keyof FilterOptions, value: FilterValue): void => {
        setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
    }, []);

    const calculateRarities = useMemo(() => {
        return Array.from(new Set(cards.map(card => card.rarity))).filter(Boolean) as CardRarity[];
    }, [cards]);

    const calculateCardTypes = useMemo(() => {
        return Array.from(new Set(cards.map(card => card.cardType))).filter(Boolean) as CardType[];
    }, [cards]);

    const calculateArenas = useMemo(() => {
        return Array.from(new Set(cards.map(card => card.arena).filter(Boolean))).sort();
    }, [cards]);

    const calculateCosts = useMemo(() => {
        return Array.from(new Set(cards.map(card => card.cost).filter(Boolean))).sort((a, b) => (a ?? 0) - (b ?? 0));
    }, [cards]);

    const calculateTraits = useMemo(() => {
        return Array.from(new Set(cards.flatMap(card => card.traits || []))).sort();
    }, [cards]);

    const calculateKeywords = useMemo(() => {
        return Array.from(new Set(cards.flatMap(card => card.keywords || []))).sort();
    }, [cards]);

    return {
        filteredCards,
        filters,
        updateFilter,
        rarities: calculateRarities,
        cardTypes: calculateCardTypes,
        arenas: calculateArenas,
        costs: calculateCosts,
        traits: calculateTraits,
        keywords: calculateKeywords
    };
};
