import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

interface DashboardCard {
    icon: typeof faUsers
    title: string
    link: string
    linkText: string
}

const AdminDashboard: React.FC = () => {
    const dashboardCards: DashboardCard[] = [
        {
            icon: faUsers,
            title: 'Users',
            link: '/admin/users',
            linkText: 'Manage Users',
        },
    ]

    const renderCard = ({ icon, title, link, linkText }: DashboardCard) => (
        <Col md={4} key={title}>
            <Card className="hover-card">
                <Card.Body>
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={icon} className="fa-2x me-3" />
                        <div>
                            <h3 className="card-title">{title}</h3>
                            <Link to={link} className="btn btn-primary btn-sm">
                                {linkText} →
                            </Link>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )

    return (
        <div className="admin-main">
            <h1 className="section__title">Admin Dashboard</h1>
            <Row className="g-4">{dashboardCards.map(renderCard)}</Row>
        </div>
    )
}

export default AdminDashboard
