import React from 'react';
import { Form } from 'react-bootstrap';
import { useAspects } from "../../hooks/useAspects";
import { FilterAspectType } from '../../hooks/useCardFilters';
import { useAspectImages } from '../../hooks/useAspectImages';

interface AspectFilterProps {
    selected: FilterAspectType[];
    required: FilterAspectType[];
    onChange: (selected: FilterAspectType[], required: FilterAspectType[]) => void;
    disabled?: boolean;
}

const AspectFilter: React.FC<AspectFilterProps> = ({ selected, required, onChange, disabled }) => {
    const { aspects, loading: aspectsLoading } = useAspects();
    const { getAspectImageUrl } = useAspectImages();

    const handleAspectClick = (aspectType: FilterAspectType) => {
        if (required.includes(aspectType)) {
            // If it's required, remove it from required
            onChange(selected, required.filter(a => a !== aspectType));
        } else if (selected.includes(aspectType)) {
            // If it's selected, move it to required
            onChange(
                selected.filter(a => a !== aspectType),
                [...required, aspectType]
            );
        } else {
            // If it's neither, add it to selected
            onChange([...selected, aspectType], required);
        }
    };

    return (
        <Form.Group className="mb-3">
            <Form.Label>Aspects</Form.Label>
            <div className="form-aspect-grid">
                {aspects.map(aspect => {
                    const imageUrl = getAspectImageUrl(aspect.value);
                    const aspectType = aspect.value as FilterAspectType;
                    return (
                        <button
                            key={aspectType}
                            onClick={() => handleAspectClick(aspectType)}
                            disabled={disabled || aspectsLoading}
                            className={`form-aspect-button ${required.includes(aspectType)
                                ? 'is-required'
                                : selected.includes(aspectType)
                                    ? 'is-selected'
                                    : ''
                                }`}
                            title={`${aspect.label}${required.includes(aspectType)
                                ? ' (Required)'
                                : selected.includes(aspectType)
                                    ? ' (Any)'
                                    : ''
                                }`}
                        >
                            <img
                                src={imageUrl}
                                alt={aspect.label}
                                className="form-aspect-image"
                            />
                        </button>
                    );
                })}
                <button
                    onClick={() => handleAspectClick(FilterAspectType.NO_ASPECT)}
                    disabled={disabled || aspectsLoading}
                    className={`form-aspect-button ${required.includes(FilterAspectType.NO_ASPECT)
                        ? 'is-required'
                        : selected.includes(FilterAspectType.NO_ASPECT)
                            ? 'is-selected'
                            : ''
                        }`}
                    title={`No Aspect${required.includes(FilterAspectType.NO_ASPECT)
                        ? ' (Required)'
                        : selected.includes(FilterAspectType.NO_ASPECT)
                            ? ' (Any)'
                            : ''
                        }`}
                >
                    <div className="form-aspect-image no-aspect">
                        <svg viewBox="0 0 24 24" className="no-aspect-icon">
                            <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeWidth="2" />
                            <line x1="6" y1="6" x2="18" y2="18" stroke="currentColor" strokeWidth="2" />
                        </svg>
                    </div>
                </button>
            </div>
            <div className="text-muted small mt-2">
                Tap to cycle: off → any → required
            </div>
        </Form.Group>
    );
};

export default AspectFilter;
