import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOfferDetailsQuery, useAddOfferNoteMutation, Offer as ApiOffer } from '../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../utils/cardUtils';
import { formatDateTime } from "../../utils/dateUtils";
import { useAuth } from '../../contexts/AuthContext';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useCards } from '../../contexts/CardContext';
import { mapApiOfferToOffer } from '../../utils/apiMapperUtils';
import { Offer } from '../../types/offerTypes';
import { SaleListVariantPriceType } from '../../types/saleListTypes';
import { CardVariantType } from '../../types/cardType';

const VARIANT_TYPE_ORDER = [
    CardVariantType.STANDARD,
    CardVariantType.FOIL,
    CardVariantType.HYPERSPACE,
    CardVariantType.HYPERSPACE_FOIL,
    CardVariantType.SHOWCASE
];

const OfferDetails: React.FC = () => {
    const { offerId } = useParams();
    const { user } = useAuth();
    const { cardsById, ensureCardsLoaded } = useCards();
    const [newNote, setNewNote] = useState('');
    const [isLoadingOfferCards, setIsLoadingOfferCards] = useState(true);
    const [addNote] = useAddOfferNoteMutation();


    const { data, loading: offerLoading, error } = useGetOfferDetailsQuery({
        variables: { offerId: offerId! },
        onCompleted: async (data) => {
            if (!data.offer) return;

            setIsLoadingOfferCards(true);
            const cardIds = Array.from(new Set(data.offer.offerItems.map(item => item.cardVariant.card.id)));
            await ensureCardsLoaded(cardIds);
            setIsLoadingOfferCards(false);
        }
    });

    const offer = useMemo(() => {
        if (offerLoading || isLoadingOfferCards || !data?.offer) return null;
        return mapApiOfferToOffer(data.offer as ApiOffer, cardsById);
    }, [data?.offer, cardsById, offerLoading, isLoadingOfferCards]);

    const sortedOfferItems = useMemo(() => {
        if (!offer) return [];
        return [...offer.offerItems].sort((a, b) => {
            const cardA = cardsById[a.variant.card.id];
            const cardB = cardsById[b.variant.card.id];

            if (cardA.cardSetId !== cardB.cardSetId) return cardA.cardSetId.localeCompare(cardB.cardSetId);
            if (cardA.standardCardNumber !== cardB.standardCardNumber) {
                return cardA.standardCardNumber.localeCompare(cardB.standardCardNumber);
            }
            return VARIANT_TYPE_ORDER.indexOf(a.variant.variantType) - VARIANT_TYPE_ORDER.indexOf(b.variant.variantType);
        });
    }, [offer, cardsById]);

    if (offerLoading || isLoadingOfferCards) return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
            <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
    if (error) return <p>Error: {error.message}</p>;
    if (!offer) return <p>Offer not found</p>;

    const handleAddNote = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!newNote.trim()) return;

        try {
            await addNote({
                variables: {
                    offerId: offerId!,
                    content: newNote.trim()
                },
                refetchQueries: ['GetOfferDetails']
            });
            setNewNote('');
        } catch (error) {
            console.error('Error adding note:', error);
        }
    };

    const renderMessages = () => {
        let currentAuthor: string | null = null;

        return [...offer.notes]
            .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
            .map((note, index, array) => {
                const isOwn = note.user.id === user?.id;
                const isNewAuthor = currentAuthor !== note.user.handle;
                const timeDiff = index > 0 ?
                    new Date(note.createdAt).getTime() - new Date(array[index - 1].createdAt).getTime()
                    : 0;
                const showAuthor = !isOwn && (isNewAuthor || timeDiff > 5 * 60 * 1000); // Only show other users' handles

                currentAuthor = note.user.handle || null;

                return (
                    <div key={note.id} className={`message-group ${isOwn ? 'message-group--own' : ''}`}>
                        {showAuthor && (
                            <div className="message-author">
                                {note.user.handle}
                            </div>
                        )}
                        <div className="message">
                            <div className="message__bubble">
                                <div className="message__content">{note.content}</div>
                                <div className="message__meta">{formatDateTime(note.createdAt)}</div>
                            </div>
                        </div>
                    </div>
                );
            });
    };

    const renderPrice = (priceType: SaleListVariantPriceType, fixedPriceInCents: number | null) => {
        switch (priceType) {
            case SaleListVariantPriceType.FIXED:
                return `$${(fixedPriceInCents! / 100).toFixed(2)}`;
            case SaleListVariantPriceType.MARKET:
                return fixedPriceInCents ? `$${(fixedPriceInCents / 100).toFixed(2)} (Market)` : 'Market Price';
            case SaleListVariantPriceType.CONTACT:
                return 'Contact for Price';
        }
    };

    const renderOfferInteraction = (offer: Offer) => {
        const isInitiator = offer.initiator.id === user?.id;
        const action = offer.offerType === 'BUY' ? 'buy from' : 'sell to';
        const otherParty = isInitiator ? offer.recipient.handle : offer.initiator.handle;

        return (
            <div className="offer-interaction">
                {isInitiator ? (
                    <span>You offered to {action} <strong>{otherParty}</strong></span>
                ) : (
                    <span><strong>{offer.initiator.handle}</strong> offered to {action} you</span>
                )}
            </div>
        );
    };

    return (
        <div className="offer-details-page">
            <div className="offer-details">
                <div className="offer-details__header">
                    <h2>Offer Details</h2>
                    <div className="offer-details__meta">
                        {renderOfferInteraction(offer)}
                        <div className={`offer-details__status offer-details__status--${offer.status.toLowerCase()}`}>
                            {offer.status === 'PENDING' ? 'Awaiting response' : `Offer ${offer.status.toLowerCase()}`}
                        </div>
                        <div className="offer-details__timestamps">
                            <time dateTime={offer.createdAt}>Created {formatDateTime(offer.createdAt)}</time>
                            {offer.updatedAt !== offer.createdAt && (
                                <time dateTime={offer.updatedAt}>Updated {formatDateTime(offer.updatedAt)}</time>
                            )}
                        </div>
                    </div>
                </div>

                <div className="offer-details__cards">
                    <h3>Cards</h3>
                    <div className="offer-details__cards-list">
                        {sortedOfferItems.map((item) => {
                            const card = item.variant.card;
                            return (
                                <div key={item.id} className="offer-item">
                                    <div className="offer-item__info">
                                        <span className="offer-item__number">
                                            {card.standardFullCardNumber}
                                        </span>
                                        <span className="offer-item__name">
                                            {card.name}
                                        </span>
                                        <span className="offer-item__variant">
                                            {getCardVariantTypeDisplay(item.variant.variantType)}
                                        </span>
                                    </div>
                                    <div className="offer-item__price">
                                        <div className="offer-item__quantity">
                                            {item.quantity} × {renderPrice(item.priceType, item.fixedPriceInCents || null)}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="offer-discussion">
                <div className="offer-discussion__header">
                    <h3>Offer Discussion</h3>
                </div>
                <div className="offer-discussion__messages">
                    {renderMessages()}
                </div>
                <div className="offer-discussion__input">
                    <Form onSubmit={handleAddNote}>
                        <div className="input-group">
                            <Form.Control
                                type="text"
                                value={newNote}
                                onChange={(e) => setNewNote(e.target.value)}
                                placeholder="Type a message..."
                            />
                            <Button type="submit" disabled={!newNote.trim()} variant="primary">
                                Send
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default OfferDetails;
