import React from 'react';
import { orderedVariantTypes, getCardVariantTypeDisplay } from '../../../../utils/cardUtils';
import WantCard from './WantCard';
import { WantListCardData } from '../../../../types/wantListTypes';
import { CardVariantType } from '../../../../types/cardType';

interface GroupedWantCardsProps {
    cards: WantListCardData[];
    isOwnWantList: boolean;
    saleListVariantIds?: Set<string>;
    onCardClick: (card: WantListCardData) => void;
}

const GroupedWantCards: React.FC<GroupedWantCardsProps> = ({ cards, isOwnWantList, saleListVariantIds, onCardClick }) => {
    const groupedCards = orderedVariantTypes.reduce((acc, variantType) => {
        const cardsWithVariant = cards.filter(card =>
            (card.variants[variantType]?.wantCount ?? 0) > 0
        );

        if (cardsWithVariant.length > 0) {
            acc[variantType] = cardsWithVariant;
        }
        return acc;
    }, {} as Record<CardVariantType, WantListCardData[]>);

    return (
        <div>
            {orderedVariantTypes.map(variantType => {
                const variantCards = groupedCards[variantType];
                if (!variantCards?.length) return null;

                return (
                    <section key={variantType} className="section__subsection">
                        <h3 className="section__subsection-title">
                            {getCardVariantTypeDisplay(variantType)}
                        </h3>
                        <div className="list-grid">
                            {variantCards.map(card => (
                                <WantCard
                                    key={`${card.id}-${variantType}`}
                                    wantedCard={card}
                                    isOwnWantList={isOwnWantList}
                                    variantType={variantType}
                                    saleListVariantIds={saleListVariantIds}
                                    onCardClick={onCardClick}
                                />
                            ))}
                        </div>
                    </section>
                );
            })}
        </div>
    );
};

export default GroupedWantCards;
