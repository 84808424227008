import React from 'react';
import { getCardVariantTypeDisplay } from '../../../../../utils/cardUtils';
import { CardVariantType } from '../../../../../types/cardType';

interface CardTableHeaderProps {
    variantColumns: CardVariantType[];
}

const CardTableHeader: React.FC<CardTableHeaderProps> = ({ variantColumns }) => {
    return (
        <thead>
            <tr>
                <th>#</th>
                <th>Card Name</th>
                {variantColumns.map((type) => (
                    <th key={type}>
                        {getCardVariantTypeDisplay(type)}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default CardTableHeader;
