import React from 'react';
import { Form, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { MarketPricingSettingsType, RoundingValue, SaleListRoundingType } from '../../../../types/saleListTypes';

interface MarketSettingsProps {
    settings: MarketPricingSettingsType;
    onSettingsChange: (settings: MarketPricingSettingsType) => void;
    error?: string;
}

const roundingDescriptions: Record<RoundingValue, string> = {
    0: 'Sale prices match exactly the adjusted market price',
    25: 'Sale prices are the adjusted market prices rounded up to the nearest 25¢.',
    50: 'Sale prices are the adjusted market prices rounded up to the nearest 50¢.',
    100: 'Sale prices are the adjusted market prices rounded up to the nearest dollar.'
};

const roundingExamples: Partial<Record<RoundingValue, string[]>> = {
    25: ['$1.01 → $1.25', '$1.26 → $1.50', '$1.51 → $1.75'],
    50: ['$1.01 → $1.50', '$1.51 → $2.00'],
    100: ['$1.01 → $2.00', '$1.51 → $2.00']
};

const MarketSettingsControl: React.FC<MarketSettingsProps> = ({
    settings,
    onSettingsChange,
    error
}) => {
    const handlePercentageChange = (newPercentage: number) => {
        onSettingsChange({
            ...settings,
            percentageAdjustment: Math.min(400, Math.max(-100, newPercentage))
        });
    };

    const handleRoundingValueChange = (value: RoundingValue) => {
        onSettingsChange({
            ...settings,
            rounding: {
                type: value === 0 ? SaleListRoundingType.NO_ROUNDING : SaleListRoundingType.UP,
                value
            }
        });
    };

    const getPercentageLabel = (value: number) => {
        if (value === 0) return "No % Adjustment";
        return value > 0
            ? `Increase market prices by ${value}%`
            : `Decrease market prices by ${Math.abs(value)}%`;
    };

    return (
        <div className="border rounded p-3">
            <div className="d-flex flex-column gap-3">
                <h6 className="mb-0">Market Price Settings</h6>
                {error && (
                    <div className="alert alert-danger mb-3">{error}</div>
                )}
                <div className="d-flex align-items-center gap-3">
                    <div className="d-flex align-items-center gap-2">
                        <Form.Control
                            type="number"
                            value={settings.percentageAdjustment}
                            onChange={(e) => handlePercentageChange(parseInt(e.target.value) || 0)}
                            min={-100}
                            max={400}
                        />
                        <span className="text-nowrap">
                            {getPercentageLabel(settings.percentageAdjustment)}
                        </span>
                    </div>
                </div>

                <ButtonGroup size="sm" className="mb-2">
                    {[
                        { name: 'No Rounding', value: 0 },
                        { name: '25¢', value: 25 },
                        { name: '50¢', value: 50 },
                        { name: '$1', value: 100 }
                    ].map((option) => (
                        <ToggleButton
                            key={option.value}
                            className="flex-grow-0"
                            id={`rounding-${option.value}`}
                            type="radio"
                            variant="outline-primary"
                            name="rounding"
                            value={option.value}
                            checked={settings.rounding.value === option.value}
                            onChange={() => handleRoundingValueChange(option.value as RoundingValue)}
                        >
                            {option.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>

                <small className="text-muted">
                    {roundingDescriptions[settings.rounding.value]}
                    {roundingExamples[settings.rounding.value] && (
                        <>
                            <br />
                            Examples: {roundingExamples[settings.rounding.value]?.map((example, i, arr) => (
                                <React.Fragment key={i}>
                                    <span>{example}</span>
                                    {i < arr.length - 1 && <span className="mx-3">·</span>}
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </small>
            </div>
        </div>
    );
};

export default MarketSettingsControl;
