import React, { useEffect, useState } from 'react';
import CardCountSetting from './collectionSettings/CardCountSetting';
import { useUpdateUserCollectionPreferencesMutation, useUserCollectionPreferencesQuery } from '../../../graphql/generated/graphql';
import { Spinner } from 'react-bootstrap';

interface CollectionSettings {
    leaderCount: number;
    baseCount: number;
    standardVariantCount: number;
    foilVariantCount: number;
    hyperspaceVariantCount: number;
    hyperspaceFoilVariantCount: number;
    showcaseVariantCount: number;
}

const SETTING_LABELS = {
    leaderCount: 'Leader Cards',
    baseCount: 'Base Cards',
    standardVariantCount: 'Standard Variants',
    foilVariantCount: 'Foil Variants',
    hyperspaceVariantCount: 'Hyperspace Variants',
    hyperspaceFoilVariantCount: 'Hyperspace Foil Variants',
    showcaseVariantCount: 'Showcase Variants',
} as const;

const CollectionSettings: React.FC = () => {
    const { data, loading } = useUserCollectionPreferencesQuery();
    const [settings, setSettings] = useState<CollectionSettings | undefined>(undefined);
    const [updatePreferences] = useUpdateUserCollectionPreferencesMutation({
        refetchQueries: ['GetUserCardSetCollection']
    });

    // Update settings when data is loaded
    useEffect(() => {
        if (data?.userCollectionPreferences) {
            setSettings(data.userCollectionPreferences);
        }
    }, [data]);

    if (loading || !settings) {
        return <Spinner animation="border" role="status" />;
    }

    const handleCountChange = async (setting: keyof CollectionSettings, count: number) => {
        const newSettings = {
            ...settings,
            [setting]: count
        };

        try {
            const result = await updatePreferences({
                variables: {
                    [setting]: count
                }
            });

            if (result.data?.updateUserCollectionPreferences?.errors?.length) {
                console.error('Failed to update preferences:', result.data.updateUserCollectionPreferences.errors);
                return;
            }

            setSettings(newSettings);
        } catch (error) {
            console.error('Error updating preferences:', error);
        }
    };

    return (

        <div className="mb-4">
            <p className="mb-2">
                Set default target counts for your collection
                <br />
                If a card matches multiple categories, the lowest count among them will be used.
            </p>

            {/* Base/Leader Cards Section */}
            <div className="d-flex flex-wrap gap-3 mb-4">
                {(['leaderCount', 'baseCount'] as const).map(setting => (
                    <div className="flex-grow-0" key={setting}>
                        <CardCountSetting
                            label={SETTING_LABELS[setting]}
                            value={settings[setting]}
                            onChange={async (count) => handleCountChange(setting, count)}
                            name={setting}
                        />
                    </div>
                ))}
            </div>

            {/* Variants Section */}
            <h6 className="mb-3">Variant Types:</h6>
            <div className="d-flex flex-wrap gap-3">
                {(['standardVariantCount', 'foilVariantCount', 'hyperspaceVariantCount',
                    'hyperspaceFoilVariantCount', 'showcaseVariantCount'] as const).map(setting => (
                        <div className="flex-grow-0" key={setting}>
                            <CardCountSetting
                                label={SETTING_LABELS[setting]}
                                value={settings[setting]}
                                onChange={async (count) => handleCountChange(setting, count)}
                                name={setting}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default CollectionSettings;
