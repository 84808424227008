import React from 'react';
import { useCardSets } from '../../contexts/CardSetContext';
import CardSetCard, { CardSetSkeleton } from '../shared/CardSetCard';
import { Container } from 'react-bootstrap';

const CardSets: React.FC = () => {
    const { cardSets, loading, error } = useCardSets();

    const renderCardSets = () => {
        if (loading) {
            <div className="grid grid--cards">
                {[...Array(3)].map((_, index) => (
                    <CardSetSkeleton key={index} />
                ))}
            </div>
        }

        if (error) {
            return (
                <div className="banner banner--error">
                    Error: {error.message}
                </div>
            );
        }

        return (
            <div className="grid grid--cards">
                {cardSets?.slice(0, 3).map((set) => (
                    <CardSetCard
                        key={set.id}
                        cardSet={set}
                    />
                ))}
            </div>
        );
    };

    return (
        <Container>
            <section className="section mt-5 mb-4">
                <h2 className="section__title">Card Sets</h2>
                {renderCardSets()}
            </section>
        </Container>
    );
};

export default CardSets;
