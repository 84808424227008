import React from 'react';
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { SaleListVariantPriceType } from '../../../../types/saleListTypes';
import { VariantMarketPrice } from '../../../../types/cardType';
import FixedPriceInput from '../../../shared/FixedPriceInput';

interface SaleCardPriceInputProps {
    priceType: SaleListVariantPriceType;
    fixedPriceInCents: number | null;
    onChange: (priceType: SaleListVariantPriceType, fixedPriceInCents: number | null) => void;
    variantId: string;
    marketPrice: VariantMarketPrice | null;
}

const SaleCardPriceInput: React.FC<SaleCardPriceInputProps> = ({
    priceType,
    fixedPriceInCents,
    onChange,
    variantId,
    marketPrice
}) => {
    const priceTypes = [
        { name: '$', value: SaleListVariantPriceType.FIXED },
        { name: 'Market Price', value: SaleListVariantPriceType.MARKET },
        { name: 'Contact Me', value: SaleListVariantPriceType.CONTACT }
    ];

    const formatDate = (dateString: string) => {
        return new Date(dateString).toLocaleDateString();
    };

    return (
        <div className="sale-card-price-input">
            <ToggleButtonGroup
                type="radio"
                name={`price-type-${variantId}`}
                value={priceType}
                onChange={(value) => onChange(
                    value as SaleListVariantPriceType,
                    value === SaleListVariantPriceType.FIXED ? (fixedPriceInCents || 0) : null
                )}
            >
                {priceTypes.map((type) => (
                    <ToggleButton
                        key={type.value}
                        id={`price-type-${variantId}-${type.value}`}
                        variant="outline-primary"
                        value={type.value}
                        className={`price-type-${type.value.toLowerCase()}`}
                    >
                        {type.name}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>

            {priceType === SaleListVariantPriceType.FIXED && (
                <FixedPriceInput
                    fixedPriceInCents={fixedPriceInCents}
                    onChange={(fixedPriceInCents) => onChange(SaleListVariantPriceType.FIXED, fixedPriceInCents)}
                />
            )}

            {priceType === SaleListVariantPriceType.MARKET && marketPrice && (
                <div className="market-price-info">
                    <span className="market-price-value">
                        <FontAwesomeIcon icon={faDollarSign} />
                        {(marketPrice.priceCents / 100).toFixed(2)}
                    </span>
                    <span className="market-price-date">
                        as of {formatDate(marketPrice.recordedAt)}
                    </span>
                </div>
            )}
        </div>
    );
};

export default SaleCardPriceInput;
