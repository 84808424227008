// app/javascript/components/trade/_shared/SelectedCards.tsx
import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getCardVariantTypeDisplay } from '../../../utils/cardUtils';
import { CardVariantType } from '../../../types/cardType';
import { SaleListVariantPriceType } from '../../../types/saleListTypes';
import FixedPriceInput from '../../shared/FixedPriceInput';

interface SelectedCard {
    cardId: string;
    cardName: string;
    cardNumber: string;
    variantId: string;
    variantType: CardVariantType;
    quantity: number;
    priceType: SaleListVariantPriceType;
    fixedPriceInCents: number | null;
    marketPriceInCents: number | null;
    maxQuantity: number;
}

interface SelectedCardsProps {
    cards: Array<SelectedCard>;
    onRemove: (variantId: string) => void;
    onQuantityChange?: (variantId: string, quantity: number) => void;
    onPriceChange?: (variantId: string, priceType: SaleListVariantPriceType, fixedPriceInCents: number | null) => void;
    mode: 'buy' | 'sell';
}

const renderPrice = (priceType: SaleListVariantPriceType, fixedPriceInCents: number | null, marketPriceInCents: number | null) => {
    switch (priceType) {
        case SaleListVariantPriceType.FIXED:
            return `$${(fixedPriceInCents! / 100).toFixed(2)}`;
        case SaleListVariantPriceType.MARKET:
            return marketPriceInCents ? `$${(marketPriceInCents / 100).toFixed(2)} (Market)` : 'Market Price';
        case SaleListVariantPriceType.CONTACT:
            return 'Contact for Price';
    }
};

export const SelectedCards: React.FC<SelectedCardsProps> = ({
    cards,
    onRemove,
    onQuantityChange,
    onPriceChange,
    mode
}) => {
    // Group cards by cardId
    const groupedCards = cards.reduce((acc, card) => {
        if (!acc[card.cardId]) {
            acc[card.cardId] = {
                cardName: card.cardName,
                cardNumber: card.cardNumber,
                variants: []
            };
        }
        acc[card.cardId].variants.push(card);
        return acc;
    }, {} as Record<string, { cardName: string; cardNumber: string; variants: SelectedCard[] }>);

    const renderPriceControls = (variant: SelectedCard) => {
        return (
            <div className="price-controls">
                <Form.Select
                    value={variant.priceType}
                    onChange={(e) => onPriceChange?.(
                        variant.variantId,
                        e.target.value as SaleListVariantPriceType,
                        e.target.value === SaleListVariantPriceType.FIXED ? (variant.fixedPriceInCents || 0) : null
                    )}
                    className="price-type-select"
                >
                    <option value={SaleListVariantPriceType.MARKET}>Market Price</option>
                    <option value={SaleListVariantPriceType.FIXED}>Fixed Price</option>
                    <option value={SaleListVariantPriceType.CONTACT}>Contact for Price</option>
                </Form.Select>

                {variant.priceType === SaleListVariantPriceType.MARKET && variant.marketPriceInCents && (
                    <div className="market-price-info">
                        <span className="market-price-value">
                            <FontAwesomeIcon icon={faDollarSign} />
                            {(variant.marketPriceInCents / 100).toFixed(2)}
                        </span>
                    </div>
                )}

                {variant.priceType === SaleListVariantPriceType.FIXED && (
                    <FixedPriceInput
                        fixedPriceInCents={variant.fixedPriceInCents}
                        onChange={(fixedPriceInCents) =>
                            onPriceChange?.(
                                variant.variantId,
                                SaleListVariantPriceType.FIXED,
                                fixedPriceInCents
                            )
                        }
                    />
                )}
            </div>
        );
    };

    return (
        <div className="offer-section">
            <h3 className="offer-section__title">Selected Cards</h3>
            <div className="offer-section__content">
                {Object.entries(groupedCards).map(([cardId, card]) => (
                    <div key={cardId} className="offer-card">
                        <div className="offer-card__header">
                            <span className="offer-card__number">
                                {card.cardNumber}
                            </span>
                            <h4 className="offer-card__name">{card.cardName}</h4>
                        </div>

                        <div className="variant-rows">
                            {card.variants.map(variant => (
                                <div key={variant.variantId} className="selected-variant-row">
                                    {mode === 'buy' ? (
                                        // Read-only view for buy mode
                                        <>
                                            <span className="variant-type">
                                                {getCardVariantTypeDisplay(variant.variantType)}
                                            </span>
                                            <span className="quantity">
                                                x{variant.quantity}
                                            </span>
                                            <span className="price-info">
                                                {renderPrice(variant.priceType, variant.fixedPriceInCents, variant.marketPriceInCents)}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="variant-type">
                                                {getCardVariantTypeDisplay(variant.variantType)}
                                            </span>
                                            {variant.maxQuantity > 1 ? (
                                                <Form.Select
                                                    value={variant.quantity}
                                                    onChange={(e) => onQuantityChange?.(variant.variantId, parseInt(e.target.value))}
                                                    className="quantity-select"
                                                >
                                                    {[...Array(variant.maxQuantity)].map((_, i) => (
                                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                                    ))}
                                                </Form.Select>
                                            ) : (
                                                <span className="quantity">x{variant.quantity}</span>
                                            )}
                                            {renderPriceControls(variant)}
                                        </>
                                    )}

                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        className="btn-icon"
                                        onClick={() => onRemove(variant.variantId)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
