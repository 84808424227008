import React, { useState, useEffect, useRef } from 'react';

interface InventoryFieldProps {
    initialValue: number;
    onUpdate: (newValue: number | null) => Promise<void>;
}

const InventoryField: React.FC<InventoryFieldProps> = ({ initialValue, onUpdate }) => {
    const [value, setValue] = useState(initialValue.toString());
    const [originalValue, setOriginalValue] = useState(initialValue.toString());
    const [status, setStatus] = useState<'idle' | 'saving' | 'success' | 'error'>('idle');

    useEffect(() => {
        setOriginalValue(initialValue.toString());
    }, [initialValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        if (status === 'error') setStatus('idle');
    };

    const handleBlur = () => {
        if (value === '') {
            // If the field is blank, revert to the original value
            setValue(originalValue);
        } else {
            const newValue = parseInt(value, 10);
            if (!isNaN(newValue) && newValue >= 0) {
                setStatus('saving');
                onUpdate(newValue)
                    .then(() => {
                        setStatus('success');
                        setTimeout(() => setStatus('idle'), 1000);
                    })
                    .catch(() => {
                        setStatus('error');
                        setValue(originalValue);

                        inputRef.current?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        });
                    });
            } else {
                // If the value is invalid, revert to the original value
                setValue(originalValue);
            }
        }
    };

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <input
            ref={inputRef}
            type="number"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            min="0"
            className={`inventory-edit-input ${status}`}
            title="Inventory"
        />
    );
};

export default InventoryField;
