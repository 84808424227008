import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import CardImage from './CardImage';
import CardModal from './CardModal';
import { Card, OrientationType } from '../../types/cardType';
import { useCards } from '../../contexts/CardContext';
import CardMarketPrices, { CardMarketPricesPrefixType } from './CardMarketPrices';

interface CardImageViewProps {
    cards: Card[];
    showMarketPrices: boolean;
}

const CardImageView: React.FC<CardImageViewProps> = ({ cards, showMarketPrices }) => {
    const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
    const { ensureCardLoaded, cardsById } = useCards();
    const [isLoading, setIsLoading] = useState(false);

    const isLandscape = (card: Card) =>
        card.images.front.orientation === OrientationType.LANDSCAPE;

    const handleCardClick = async (card: Card) => {
        if (card.id && !cardsById[card.id]) {
            setIsLoading(true);
            try {
                await ensureCardLoaded(card.id);
            } finally {
                setIsLoading(false);
            }
        }

        if (cardsById[card.id]) {
            setSelectedCardId(card.id);
        }
    };

    const handleCloseModal = () => {
        setSelectedCardId(null);
    };

    return (
        <div className="game-card-image-view">
            <Row className="game-card-image-grid g-2">
                {cards.map((card) => (
                    <Col
                        key={card.id}
                        xs={isLandscape(card) ? 6 : 4}
                        sm={isLandscape(card) ? 4 : 3}
                        md={isLandscape(card) ? 4 : 3}
                        lg={isLandscape(card) ? 3 : 2}
                        xl={isLandscape(card) ? 3 : 2}
                        className="mb-2"
                    >
                        <div
                            className="game-card-image-clickable"
                            onClick={() => handleCardClick(card)}
                            role="button"
                            tabIndex={0}
                        >
                            <CardImage
                                image={card.images.front}
                                name={card.name}
                            />
                            {showMarketPrices && <CardMarketPrices variants={card.variants} prefixType={CardMarketPricesPrefixType.FULL} />}
                        </div>
                    </Col>
                ))}
            </Row>
            {selectedCardId && (
                <CardModal
                    card={cardsById[selectedCardId]}
                    loading={isLoading}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default CardImageView;
