import React, { useMemo, useState } from 'react';
import EditableSaleCard from './EditableSaleCard';
import { SaleCardData, SaleListType } from '../../../../types/saleListTypes';
import { useCardSets } from '../../../../contexts/CardSetContext';
import { compareCards } from '../../../../utils/saleListCardUtils';

interface EditableSaleListItemsTableProps {
    saleList: SaleListType;
    onItemUpdated?: () => void;
}

const EditableSaleListItemsTable: React.FC<EditableSaleListItemsTableProps> = ({
    saleList,
    onItemUpdated
}) => {
    const { cardSets } = useCardSets();
    const [collapsedSets, setCollapsedSets] = useState<Set<string>>(new Set());
    const setNames = useMemo(() => {
        return cardSets?.reduce((acc, set) => {
            if (set?.id) acc[set.id] = set.name;
            return acc;
        }, {} as Record<string, string>) ?? {};
    }, [cardSets]);

    if (saleList.cards.length === 0) {
        return (
            <div className="text-center p-4">
                <p className="mb-0">No cards in sale list yet.</p>
            </div>
        );
    }

    const toggleSet = (setId: string) => {
        setCollapsedSets(prev => {
            const next = new Set(prev);
            if (next.has(setId)) {
                next.delete(setId);
            } else {
                next.add(setId);
            }
            return next;
        });
    };

    const cardsBySet = saleList.cards.reduce((acc, card) => {
        const setId = card.card.cardSetId;
        if (!acc[setId]) acc[setId] = [];
        acc[setId].push(card);
        return acc;
    }, {} as Record<string, SaleCardData[]>);

    return (
        <div className="list-container">
            {Object.entries(cardsBySet).map(([setId, cards]) => (
                <section key={setId}>
                    <div
                        className={`section__subsection-title section__subsection-title--collapsible ${collapsedSets.has(setId) ? 'collapsed' : ''
                            }`}
                        onClick={() => toggleSet(setId)}
                    >
                        {setNames[setId] || `Set ${setId}`}
                    </div>
                    {!collapsedSets.has(setId) && (
                        <div className="section__content mb-4">

                            <div className="list-grid">
                                {cards
                                    .sort(compareCards)
                                    .map(saleCard => (
                                        <EditableSaleCard key={saleCard.card.id} saleCard={saleCard} saleListId={saleList.id} onItemUpdated={onItemUpdated || (() => { })} />
                                    ))}
                            </div>

                        </div>
                    )}
                </section>
            ))}
        </div>
    );
};

export default EditableSaleListItemsTable;
