import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../layout/LoadingSpinner';
import ErrorDisplay from '../layout/ErrorDisplay';
import SharedListCard from './SharedListCard';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface SharedListData {
    id: string;
    user: {
        handle: string;
    };
    updatedAt: string;
    publiclyShared: boolean;
    matchingCardIcon?: IconDefinition;
}

interface QueryHookResult<T> {
    data?: Record<string, T[]> & {
        matchingVariantCounts?: { [key: string]: number };
    };
    loading: boolean;
    error?: Error;
}

interface SharedListGridProps<T extends SharedListData> {
    useQueryHook: () => QueryHookResult<T>;
    dataKey: string;
    EmptyState: React.FC;
    matchingCardIcon: IconDefinition;
    getNavigationPath: (handle: string) => string;
    matchingCountField: keyof T;
}

const SharedListGrid = <T extends SharedListData>({
    useQueryHook,
    dataKey,
    EmptyState,
    matchingCardIcon,
    getNavigationPath,
    matchingCountField,
}: SharedListGridProps<T>) => {
    const { data, loading, error } = useQueryHook();
    const navigate = useNavigate();

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error.message} />;

    const items = data?.[dataKey] ?? [];

    if (items.length === 0) {
        return (
            <Container className="mt-4">
                <EmptyState />
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            <div className="shared-list__grid">
                {items.map((item) => {
                    const matchingCount = item[matchingCountField] as number | null;
                    return (
                        <SharedListCard
                            key={item.id}
                            handle={item.user.handle}
                            updatedAt={item.updatedAt}
                            publiclyShared={item.publiclyShared}
                            matchingCardProps={
                                matchingCount !== null
                                    ? {
                                        icon: matchingCardIcon,
                                        count: matchingCount,
                                    }
                                    : undefined
                            }
                            onClick={() => navigate(getNavigationPath(item.user.handle))}
                        />
                    );
                })}
            </div>
        </Container>
    );
};

export default SharedListGrid;
