import React, { useMemo, useState } from 'react';
import WantCard from './WantCard';
import EmptyWantList from './EmptyWantList';
import { useAuth } from '../../../../contexts/AuthContext';
import { WantListCardData, WantListGroupBy } from '../../../../types/wantListTypes';
import { useSearchParams } from 'react-router-dom';
import GroupedWantCards from './GroupedWantCards';
import { compareCards } from '../../../../utils/wantListCardUtils';
import { useCardSets } from '../../../../contexts/CardSetContext';
import { WantListType } from '../../../../types/wantListTypes';
import { Button, Form } from 'react-bootstrap';
import { useGetUserSaleListsQuery } from '../../../../graphql/generated/graphql';
import ListGroupControl from '../../_shared/ListGroupControl';
import { Card } from '../../../../types/cardType';
import CardModal from '../../../card/CardModal';
import CardFilters from '../../../filters/CardFilters';
import { useCardFilters } from '../../../../hooks/useCardFilters';
import { ArenaType } from '../../../../types/cardType';


interface WantListDisplayProps {
    effectiveHandle: string | undefined;
    isOwnWantList: boolean;
    wantList: WantListType;
}

const WantListDisplay: React.FC<WantListDisplayProps> = ({ effectiveHandle, isOwnWantList, wantList }) => {
    const [selectedCard, setSelectedCard] = useState<Card | null>(null);
    const { cardSets } = useCardSets();
    const [collapsedSets, setCollapsedSets] = useState<Set<string>>(new Set());
    const [filtersOpen, setFiltersOpen] = useState(false);


    const { isAuthenticated } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showForSaleOnly, setShowForSaleOnly] = useState(false);
    const { filteredCards: filteredByTypeCards, filters, updateFilter, rarities, cardTypes, arenas, costs, traits, keywords } = useCardFilters(wantList.cards.map(card => card.card));

    const { data: saleListData } = useGetUserSaleListsQuery({
        skip: !isAuthenticated || isOwnWantList
    });

    const saleListVariants = useMemo(() => {
        if (!saleListData?.userSaleLists[0]) return new Set<string>();

        const variantIds = new Set<string>();
        saleListData.userSaleLists[0].items.forEach((item) => {
            const variantId = item?.cardVariant?.id;
            if (variantId) variantIds.add(variantId);
        });

        return variantIds;
    }, [saleListData]);

    const filteredCards = useMemo(() => {
        const cards = wantList.cards.filter(card =>
            filteredByTypeCards.some(filteredCard => filteredCard.id === card.card.id)
        );

        if (!showForSaleOnly || isOwnWantList) return cards;

        return cards.map(wantCard => ({
            ...wantCard,
            variants: Object.fromEntries(
                Object.entries(wantCard.variants).filter(([, variant]) =>
                    saleListVariants.has(variant.id)
                )
            )
        })).filter(card => Object.keys(card.variants).length > 0);
    }, [wantList.cards, showForSaleOnly, isOwnWantList, saleListVariants, filteredByTypeCards]);



    const groupBy = (searchParams.get('groupBy') as WantListGroupBy) || WantListGroupBy.CardNumber;

    const setNames = useMemo(() => {
        return cardSets?.reduce((acc, set) => {
            if (set?.id) acc[set.id] = set.name;
            return acc;
        }, {} as Record<string, string>) ?? {};
    }, [cardSets]);

    if (wantList.cards.length === 0) {
        return <EmptyWantList isOwnProfile={isOwnWantList} user={effectiveHandle} isAuthenticated={isAuthenticated} />;
    }

    const toggleSet = (setId: string) => {
        setCollapsedSets(prev => {
            const next = new Set(prev);
            if (next.has(setId)) {
                next.delete(setId);
            } else {
                next.add(setId);
            }
            return next;
        });
    };

    // Add this function before the return statement
    const groupCardsBySet = (cards: WantListCardData[]) => {
        return cards.reduce((acc, card) => {
            const setId = card.card.cardSetId;
            if (!acc[setId]) acc[setId] = [];
            acc[setId].push(card);
            return acc;
        }, {} as Record<string, WantListCardData[]>);
    };

    const groupByOptions = [
        { value: WantListGroupBy.CardNumber, display: 'Card Number' },
        { value: WantListGroupBy.VariantType, display: 'Variant Type' },
    ];

    const handleCardClick = (card: WantListCardData) => {
        setSelectedCard(card.card);
    };

    const handleCloseModal = () => {
        setSelectedCard(null);
    };

    return (
        <div className="list-container">
            <div className="d-flex mb-3">
                <Button
                    onClick={() => setFiltersOpen(!filtersOpen)}
                    aria-controls="game-card-filters"
                    aria-expanded={filtersOpen}
                    variant="outline-primary"
                >
                    {filtersOpen ? 'Hide Filters' : 'Show Filters'}
                </Button>
            </div>

            <CardFilters
                filters={filters}
                updateFilter={updateFilter}
                rarities={rarities || []}
                cardTypes={cardTypes}
                arenas={arenas as ArenaType[]}
                costs={costs as number[]}
                traits={traits || []}
                keywords={keywords || []}
                disabled={false}
                isOpen={filtersOpen}
                onClose={() => setFiltersOpen(false)}
            />
            <div className="d-flex flex-column flex-sm-row justify-content-between mb-3 gap-2">
                <ListGroupControl
                    initialGroupBy={groupBy}
                    options={groupByOptions}
                    onChange={(newGroupBy) => {
                        setSearchParams(prev => {
                            const params = new URLSearchParams(prev);
                            params.set('groupBy', newGroupBy);
                            return params;
                        }, { replace: true });
                    }}
                />
                {!isOwnWantList && isAuthenticated && (
                    <Form.Check
                        type="switch"
                        id="for-sale-only-switch"
                        label="Show Cards I'm Selling Only"
                        checked={showForSaleOnly}
                        onChange={(e) => setShowForSaleOnly(e.target.checked)}
                    />
                )}
            </div>

            {Object.entries(groupCardsBySet(filteredCards)).map(([setId, cards]) => (
                <section key={setId}>
                    <h2
                        className={`section__title section__title--collapsible ${collapsedSets.has(setId) ? 'collapsed' : ''
                            }`}
                        onClick={() => toggleSet(setId)}
                    >
                        {setNames[setId] || `Set ${setId}`}
                    </h2>
                    {!collapsedSets.has(setId) && (
                        <div className="section__content mb-4">
                            {groupBy === WantListGroupBy.VariantType ? (
                                <GroupedWantCards cards={cards.sort(compareCards)}
                                    isOwnWantList={isOwnWantList}
                                    saleListVariantIds={saleListVariants}
                                    onCardClick={handleCardClick}
                                />
                            ) : (
                                <div className="list-grid">
                                    {cards
                                        .sort(compareCards)
                                        .map(card => (
                                            <WantCard
                                                key={card.id}
                                                wantedCard={card}
                                                isOwnWantList={isOwnWantList}
                                                saleListVariantIds={saleListVariants}
                                                onCardClick={handleCardClick}
                                            />
                                        ))}
                                </div>
                            )}
                        </div>
                    )}
                </section>
            ))}
            {selectedCard && (
                <CardModal
                    card={selectedCard}
                    loading={false}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default WantListDisplay;
