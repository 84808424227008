import { Card, CardRarity, CardType, ArenaType, OrientationType, CardVariantType, CardVariant } from '../types/cardType';
import { CardSetCodeEnum } from '../types/cardSetType';
import { ArenaTypeEnum, AspectTypeEnum, CardTypeEnum, RarityTypeEnum, OrientationTypeEnum, Card as ApiCard, CardVariantTypeEnum, CardVariant as ApiCardVariant, UserCardInventory as ApiUserCardInventory, CollectionItemInventory as ApiInventoryDetails, SaleList as ApiSaleList, PriceTypeEnum, CardSetCodeEnum as ApiCardSetCodeEnum, WantList as ApiWantList, OfferStatusEnum, Offer as ApiOffer, OfferItem as ApiOfferItem, OfferTypeEnum, OfferNote as ApiOfferNote, User as ApiUser, RoundingTypeEnum, MarketPricingSettings as ApiMarketPricingSettings, SaleListSettings as ApiSaleListSettings } from '../graphql/generated/graphql';
import { AspectType } from '../types/aspectTypes';
import { InventoryDetails, UserCardInventory } from '../types/inventoryTypes';
import { CardVariantData as SaleCardVariantData, SaleCardData, SaleListType, SaleListVariantPriceType, SaleListRoundingType, SaleListSettingsType, MarketPricingSettingsType, RoundingValue } from '../types/saleListTypes';
import { CardVariantData as WantListCardVariantData, WantListCardData, WantListType } from '../types/wantListTypes';
import { Offer, OfferItem, OfferNote, OfferStatusType, OfferType, User } from '../types/offerTypes';

export const mapApiCardToCard = (apiCard: ApiCard, cardSetId: string): Card => ({
    id: apiCard.id,
    name: apiCard.name,
    subtitle: apiCard.subtitle ?? undefined,
    cardType: mapCardType(apiCard.cardType),
    rarity: mapRarity(apiCard.rarity),
    cost: apiCard.cost,
    power: apiCard.power ?? undefined,
    hp: apiCard.hp ?? undefined,
    arena: apiCard.arena ? mapArena(apiCard.arena) : undefined,
    aspects: apiCard.aspects?.map(mapAspect) ?? [],
    traits: apiCard.traits ?? [],
    frontText: apiCard.frontText ?? undefined,
    backText: apiCard.backText ?? undefined,
    unique: apiCard.unique ?? false,
    keywords: apiCard.keywords ?? [],
    standardCardNumber: apiCard.standardCardNumber ?? '',
    hyperspaceCardNumber: apiCard.hyperspaceCardNumber ?? '',
    showcaseCardNumber: apiCard.showcaseCardNumber ?? '',
    standardFullCardNumber: apiCard.standardFullCardNumber ?? '',
    cardSetId: cardSetId,
    images: {
        front: apiCard.images.front && {
            id: apiCard.images.front.id ?? '',
            url: apiCard.images.front.url ?? '',
            orientation: mapOrientation(apiCard.images.front.orientation)
        },
        back: apiCard.images.back ? {
            id: apiCard.images.back.id ?? '',
            url: apiCard.images.back.url ?? '',
            orientation: mapOrientation(apiCard.images.back.orientation)
        } : undefined
    },
    variants: mapApiCardVariantsToVariantRecord(apiCard.cardVariants)
})

const mapApiCardVariantsToVariantRecord = (cardVariants: ApiCardVariant[] | null | undefined): Partial<Record<CardVariantType, CardVariant>> => {
    return cardVariants?.reduce((acc, variant) => {
        if (!variant) return acc;
        const variantType = mapCardVariantType(variant.variantType);
        acc[variantType] = mapApiCardVariantToCardVariant(variant);
        return acc;
    }, {} as Partial<Record<CardVariantType, CardVariant>>) ?? {};
}

export const mapApiCardVariantToCardVariant = (apiCardVariant: ApiCardVariant): CardVariant => ({
    id: apiCardVariant.id,
    variantType: mapCardVariantType(apiCardVariant.variantType),
    currentMarketPrice: apiCardVariant.currentMarketPrice || null,
    images: {
        front: apiCardVariant.images.front && {
            id: apiCardVariant.images.front.id ?? '',
            url: apiCardVariant.images.front.url ?? '',
            orientation: mapOrientation(apiCardVariant.images.front.orientation)
        },
        back: apiCardVariant.images.back ? {
            id: apiCardVariant.images.back.id ?? '',
            url: apiCardVariant.images.back.url ?? '',
            orientation: mapOrientation(apiCardVariant.images.back.orientation)
        } : undefined
    }
})

export const mapCardVariantType = (variantType: CardVariantTypeEnum): CardVariantType => {
    switch (variantType) {
        case CardVariantTypeEnum.Standard:
            return CardVariantType.STANDARD
        case CardVariantTypeEnum.Foil:
            return CardVariantType.FOIL
        case CardVariantTypeEnum.Hyperspace:
            return CardVariantType.HYPERSPACE
        case CardVariantTypeEnum.HyperspaceFoil:
            return CardVariantType.HYPERSPACE_FOIL
        case CardVariantTypeEnum.Showcase:
            return CardVariantType.SHOWCASE
        default:
            throw new Error(`Unknown card variant type: ${variantType}`)
    }
}

export const mapArena = (arena: ArenaTypeEnum): ArenaType => {
    switch (arena) {
        case ArenaTypeEnum.Ground:
            return ArenaType.GROUND
        case ArenaTypeEnum.Space:
            return ArenaType.SPACE
        default:
            throw new Error(`Unknown arena: ${arena}`)
    }
}

export const mapOrientation = (orientation: OrientationTypeEnum): OrientationType => {
    switch (orientation) {
        case OrientationTypeEnum.Landscape:
            return OrientationType.LANDSCAPE
        case OrientationTypeEnum.Portrait:
            return OrientationType.PORTRAIT
        default:
            throw new Error(`Unknown orientation: ${orientation}`)
    }
}

export const mapCardType = (type: CardTypeEnum): CardType => {
    switch (type) {
        case CardTypeEnum.Leader:
            return CardType.LEADER
        case CardTypeEnum.Unit:
            return CardType.UNIT
        case CardTypeEnum.Event:
            return CardType.EVENT
        case CardTypeEnum.Upgrade:
            return CardType.UPGRADE
        case CardTypeEnum.Base:
            return CardType.BASE
        default:
            throw new Error(`Unknown card type: ${type}`)
    }
}

export const mapRarity = (rarity: RarityTypeEnum): CardRarity => {
    switch (rarity) {
        case RarityTypeEnum.Common:
            return CardRarity.COMMON
        case RarityTypeEnum.Uncommon:
            return CardRarity.UNCOMMON
        case RarityTypeEnum.Rare:
            return CardRarity.RARE
        case RarityTypeEnum.Legendary:
            return CardRarity.LEGENDARY
        case RarityTypeEnum.Special:
            return CardRarity.SPECIAL
        default:
            throw new Error(`Unknown rarity: ${rarity}`)
    }
}

export const mapAspect = (aspect: AspectTypeEnum): AspectType => {
    switch (aspect) {
        case AspectTypeEnum.Command:
            return AspectType.COMMAND
        case AspectTypeEnum.Heroism:
            return AspectType.HEROISM
        case AspectTypeEnum.Villainy:
            return AspectType.VILLAINY
        case AspectTypeEnum.Cunning:
            return AspectType.CUNNING
        case AspectTypeEnum.Vigilance:
            return AspectType.VIGILANCE
        case AspectTypeEnum.Aggression:
            return AspectType.AGGRESSION
        default:
            throw new Error(`Unknown aspect: ${aspect}`)
    }
}

export const mapApiInventoryToInventoryDetails = (apiInventory: ApiInventoryDetails): InventoryDetails => {
    return {
        variantId: apiInventory.variantId,
        inventory: apiInventory.inventory || 0,
        missing: {
            count: apiInventory.missing?.count || 0,
            isDefault: apiInventory.missing?.isDefault || true
        },
        extraCount: apiInventory.extraCount
    };
};

const VARIANT_INVENTORY_KEYS = {
    standardInventory: CardVariantType.STANDARD,
    foilInventory: CardVariantType.FOIL,
    hyperspaceInventory: CardVariantType.HYPERSPACE,
    hyperspaceFoilInventory: CardVariantType.HYPERSPACE_FOIL,
    showcaseInventory: CardVariantType.SHOWCASE
} as const;

export const mapApiInventoryToUserCardInventory = (
    apiInventory: ApiUserCardInventory,
    cardCache: Record<string, Card>
): UserCardInventory => {
    const card = cardCache[apiInventory.id];

    const variantInventories = Object.entries(VARIANT_INVENTORY_KEYS).reduce((acc, [key, variantType]) => {
        const inventory = apiInventory[key as keyof typeof VARIANT_INVENTORY_KEYS];
        if (inventory) {
            acc[variantType] = mapApiInventoryToInventoryDetails(inventory);
        }
        return acc;
    }, {} as Partial<Record<CardVariantType, InventoryDetails>>);

    return {
        id: apiInventory.id,
        card: card,
        inventories: variantInventories
    };
};

export const mapApiSaleListSettingsToSaleListSettings = (apiSaleListSettings: ApiSaleListSettings): SaleListSettingsType => {
    return {
        marketPricingSettings: mapApiMarketPricingSettingsToSaleListMarketPricingSettings(apiSaleListSettings.marketPricingSettings),
        banner: apiSaleListSettings.banner ?? null
    };
}


export const mapApiMarketPricingSettingsToSaleListMarketPricingSettings = (apiMarketPricingSettings: ApiMarketPricingSettings): MarketPricingSettingsType | null => {
    if (!apiMarketPricingSettings) return null;

    return {
        percentageAdjustment: apiMarketPricingSettings.percentageAdjustment,
        rounding: {
            type: mapApiSaleListRoundingTypeToSaleListRoundingType(apiMarketPricingSettings.rounding.type),
            value: apiMarketPricingSettings.rounding.value as RoundingValue
        }
    };
}

export const mapApiSaleListToSaleList = (apiSaleList: ApiSaleList | undefined, cardCache: Record<string, Card>): SaleListType | null => {
    if (!apiSaleList) return null;
    if (!apiSaleList?.items) return {
        id: apiSaleList?.id ?? '',
        settings: mapApiSaleListSettingsToSaleListSettings(apiSaleList?.settings),
        cards: []
    };

    const cardMap = new Map<string, SaleCardData>();

    apiSaleList.items.forEach((item) => {
        if (!item) return;

        const { card, variantType } = item.cardVariant;
        const cardVariantType = mapCardVariantType(variantType);
        const cardId = card.id;

        if (!cardMap.has(cardId)) {
            cardMap.set(cardId, {
                card: cardCache[cardId],
                variants: {} as Record<CardVariantType, SaleCardVariantData>
            });
        }

        const cardData = cardMap.get(cardId)!;
        if (Object.values(CardVariantType).includes(cardVariantType)) {
            cardData.variants[cardVariantType] = {
                id: item.cardVariant.id,
                saleCount: item.quantity,
                priceType: mapApiSaleListVariantPriceType(item.priceType),
                fixedPriceInCents: item.fixedPriceInCents || null,
                marketAdjustedPriceInCents: item.marketAdjustedPriceInCents || null
            };
        }
    });
    return {
        id: apiSaleList.id,
        cards: Array.from(cardMap.values()),
        settings: mapApiSaleListSettingsToSaleListSettings(apiSaleList?.settings)
    }
};

export const mapApiSaleListRoundingTypeToSaleListRoundingType = (roundingType: RoundingTypeEnum): SaleListRoundingType => {
    if (!roundingType) return SaleListRoundingType.NO_ROUNDING;

    switch (roundingType) {
        case RoundingTypeEnum.NoRounding:
            return SaleListRoundingType.NO_ROUNDING;
        case RoundingTypeEnum.Up:
            return SaleListRoundingType.UP;
        default:
            throw new Error(`Unknown rounding type: ${roundingType}`)
    }
}

export const mapApiSaleListVariantPriceType = (priceType: PriceTypeEnum): SaleListVariantPriceType => {
    switch (priceType) {
        case PriceTypeEnum.Fixed:
            return SaleListVariantPriceType.FIXED;
        case PriceTypeEnum.Market:
            return SaleListVariantPriceType.MARKET;
        case PriceTypeEnum.Contact:
            return SaleListVariantPriceType.CONTACT;
        default:
            throw new Error(`Unknown price type: ${priceType}`)
    }
}

export const mapSaleListVariantPriceTypeToApi = (priceType: SaleListVariantPriceType): PriceTypeEnum => {
    switch (priceType) {
        case SaleListVariantPriceType.FIXED:
            return PriceTypeEnum.Fixed;
        case SaleListVariantPriceType.MARKET:
            return PriceTypeEnum.Market;
        case SaleListVariantPriceType.CONTACT:
            return PriceTypeEnum.Contact;
    }
}

export const mapApiCardSetCodeToCardSetCode = (apiCardSetCode: ApiCardSetCodeEnum): CardSetCodeEnum => {
    switch (apiCardSetCode) {
        case ApiCardSetCodeEnum.Sor:
            return CardSetCodeEnum.SOR
        case ApiCardSetCodeEnum.Shd:
            return CardSetCodeEnum.SHD
        case ApiCardSetCodeEnum.Twi:
            return CardSetCodeEnum.TWI
        default:
            throw new Error(`Unknown card set code: ${apiCardSetCode}`)
    }
}

export const mapCardSetCodeToApiCardSetCode = (cardSetCode: CardSetCodeEnum): ApiCardSetCodeEnum => {
    switch (cardSetCode) {
        case CardSetCodeEnum.SOR:
            return ApiCardSetCodeEnum.Sor
        case CardSetCodeEnum.SHD:
            return ApiCardSetCodeEnum.Shd
        case CardSetCodeEnum.TWI:
            return ApiCardSetCodeEnum.Twi
    }
}

export const mapApiWantListToWantList = (apiWantList: ApiWantList | undefined, cardCache: Record<string, Card>): WantListType | null => {
    if (!apiWantList) return null;
    if (!apiWantList?.items?.nodes) return {
        id: apiWantList.id ?? '',
        cards: []
    };

    const cardMap = new Map<string, WantListCardData>();

    apiWantList.items.nodes.forEach((item) => {
        if (!item) return;

        const { card, variantType } = item.cardVariant;
        const cardVariantType = mapCardVariantType(variantType);
        const cardId = card.id;

        if (!cardMap.has(cardId)) {
            cardMap.set(cardId, {
                id: item.id,
                card: cardCache[cardId],
                variants: {} as Partial<Record<CardVariantType, WantListCardVariantData>>
            });
        }

        const cardData = cardMap.get(cardId)!;
        if (Object.values(CardVariantType).includes(cardVariantType)) {
            cardData.variants[cardVariantType] = {
                id: item.cardVariant.id,
                wantCount: item.quantity
            };
        }
    });

    return {
        id: apiWantList.id,
        cards: Array.from(cardMap.values())
    };
};

export const mapApiOfferStatusToOfferStatus = (status: OfferStatusEnum): OfferStatusType => {
    switch (status) {
        case OfferStatusEnum.Pending:
            return OfferStatusType.PENDING;
        case OfferStatusEnum.Accepted:
            return OfferStatusType.ACCEPTED;
        case OfferStatusEnum.Rescinded:
            return OfferStatusType.RESCINDED;
        case OfferStatusEnum.Declined:
            return OfferStatusType.DECLINED;
        default:
            throw new Error(`Unknown offer status: ${status}`);
    }
};

export const mapApiOfferItemToOfferItem = (apiOfferItem: ApiOfferItem, cardCache: Record<string, Card>): OfferItem => ({
    id: apiOfferItem.id,
    variant: {
        id: apiOfferItem.cardVariant.id,
        variantType: mapCardVariantType(apiOfferItem.cardVariant.variantType),
        card: cardCache[apiOfferItem.cardVariant.card.id]
    },
    quantity: apiOfferItem.quantity,
    priceType: mapApiSaleListVariantPriceType(apiOfferItem.priceType),
    fixedPriceInCents: apiOfferItem.fixedPriceInCents || 0
});

export const mapApiOfferToOffer = (apiOffer: ApiOffer, cardCache: Record<string, Card>): Offer => ({
    id: apiOffer.id,
    initiator: mapApiUserToUser(apiOffer.initiator),
    recipient: mapApiUserToUser(apiOffer.recipient),
    offerType: mapApiOfferTypeToOfferType(apiOffer.offerType),
    status: mapApiOfferStatusToOfferStatus(apiOffer.status),
    offerItems: apiOffer.offerItems.map(item => mapApiOfferItemToOfferItem(item, cardCache)),
    notes: apiOffer.notes.map(mapApiOfferNoteToOfferNote),
    createdAt: apiOffer.createdAt,
    updatedAt: apiOffer.updatedAt
});

export const mapApiOfferTypeToOfferType = (offerType: OfferTypeEnum): OfferType => {
    switch (offerType) {
        case OfferTypeEnum.Buy:
            return OfferType.BUY;
        case OfferTypeEnum.Sell:
            return OfferType.SELL;
    }
}

export const mapApiUserToUser = (apiUser: ApiUser): User => ({
    id: apiUser.id,
    handle: apiUser.handle ?? ''
});

export const mapApiOfferNoteToOfferNote = (apiOfferNote: ApiOfferNote): OfferNote => ({
    id: apiOfferNote.id,
    content: apiOfferNote.content,
    user: mapApiUserToUser(apiOfferNote.user),
    createdAt: apiOfferNote.createdAt
});
