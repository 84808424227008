import React from "react"
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home";
import CardSets from "../components/card_set/CardSets";
import CardSetView from "../components/card_set/CardSetView";
import CardPageView from "../components/card/CardPageView";
import TradingHub from "../components/TradingHub";
import UserProfile from "../components/user/profile/UserProfile";
import Login from "../components/auth/Login";
import ProtectedRoute from "../components/auth/ProtectedRoute";
import CollectionPage from "../components/collection/inventory/CollectionPage";
import WantListPage from "../components/collection/want_list/WantListPage";
import NotFound from "../components/layout/NotFound";
import SharedCollections from "../components/collection/SharedCollections";
import SaleList from "../components/collection/sale_list/SaleListPage";
import Users from "../components/admin/user/Users";
import AdminLayout from "../components/admin/AdminLayout";
import AdminDashboard from "../components/admin/AdminDashboard";
import ContactUs from "../components/ContactUs";
import UserEdit from "../components/admin/user/UserEdit";
import OfferDetails from '../components/trade/OfferDetails';
import UserPreferences from "../components/user/preferences/UserPreferences";

const AppRoutes = () => (
    <Routes>
        <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="users" element={<Users />} />
            <Route path="users/:id/edit" element={<UserEdit />} />
        </Route>

        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/card-sets" element={<CardSets />} />
        <Route path="/card-sets/:cardSetCode" element={<CardSetView />} />
        <Route path="/cards/:id" element={<CardPageView />} />
        <Route path="/collections" element={<SharedCollections />} />
        <Route path="/collections/:handle" element={<CollectionPage />} />
        <Route path="/collections/:handle/want" element={<WantListPage />} />
        <Route path="/collections/:handle/sell" element={<SaleList />} />
        <Route path="/trading" element={<TradingHub />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route element={<ProtectedRoute />}>
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/preferences" element={<UserPreferences />} />
            <Route path="/collections/my" element={<CollectionPage />} />
            <Route path="/collections/my/edit" element={<CollectionPage />} />
            <Route path="/collections/my/want" element={<WantListPage />} />
            <Route path="/collections/my/want/edit" element={<WantListPage />} />
            <Route path="/collections/my/sell" element={<SaleList />} />
            <Route path="/collections/my/sell/edit" element={<SaleList />} />
            <Route path="/trading/offers/:offerId" element={<OfferDetails />} />
        </Route>
        <Route path="*" element={<NotFound />} />
    </Routes>
);

export default AppRoutes;
