import React from 'react';
import { Badge } from 'react-bootstrap';

interface MissingBadgeProps {
    count: number;
    className?: string;
}

const MissingBadge: React.FC<MissingBadgeProps> = ({ count, className = '' }) => {
    if (count <= 0) return null;

    return (
        <Badge
            bg="missing"
            className={`missing-count ${className}`}
            data-tooltip="Missing"
        >
            {count}
        </Badge>
    );
};

export default MissingBadge;
