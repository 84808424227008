import React, { useEffect, useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import { useGetUserCardSetCollectionQuery } from '../../../../../graphql/generated/graphql'
import CardTableHeader from './CardTableHeader'
import CardTableRow from './CardTableRow'
import { CardSetCodeEnum } from '../../../../../types/cardSetType'
import { CardVariantType } from '../../../../../types/cardType'
import { WantListType } from '../../../../../types/wantListTypes'
import { mapCardSetCodeToApiCardSetCode } from '../../../../../utils/apiMapperUtils'
import { useCards } from '../../../../../contexts/CardContext'

interface CardTableProps {
    selectedSet: CardSetCodeEnum | null
    searchTerm: string
    showMissingOnly: boolean
    effectiveHandle: string | undefined
    onItemUpdated: () => void
    existingWantList: WantListType
}

const VARIANT_COLUMNS = [
    CardVariantType.STANDARD,
    CardVariantType.FOIL,
    CardVariantType.HYPERSPACE,
    CardVariantType.HYPERSPACE_FOIL,
    CardVariantType.SHOWCASE,
] as const

const CardTable: React.FC<CardTableProps> = ({
    selectedSet,
    searchTerm,
    showMissingOnly,
    effectiveHandle,
    onItemUpdated,
    existingWantList
}) => {
    const { ensureSetLoaded, cardsById, cardsBySetCode, isLoadingSet } = useCards()

    useEffect(() => {
        if (selectedSet) {
            ensureSetLoaded(selectedSet)
        }
    }, [selectedSet, ensureSetLoaded])

    const { data, loading: queryLoading } = useGetUserCardSetCollectionQuery({
        variables: {
            userHandle: effectiveHandle,
            cardSetCode: mapCardSetCodeToApiCardSetCode(selectedSet!)
        },
        skip: !effectiveHandle || !selectedSet
    })

    const cards = useMemo(() => {
        if (!data?.userCardSetCollection?.nodes || !selectedSet || !cardsBySetCode[selectedSet]) return []

        return data.userCardSetCollection.nodes
            .filter(Boolean)
            .map(node => {
                if (!node) return null

                const wantCard = existingWantList.cards.find(c => c.card.id === node.id)
                const card = cardsById[node.id]

                if (!card) return null

                return {
                    id: node.id,
                    name: card.name,
                    standardCardNumber: card.standardCardNumber || 0,
                    variants: Object.entries({
                        [CardVariantType.STANDARD]: node.standardInventory,
                        [CardVariantType.FOIL]: node.foilInventory,
                        [CardVariantType.HYPERSPACE]: node.hyperspaceInventory,
                        [CardVariantType.HYPERSPACE_FOIL]: node.hyperspaceFoilInventory,
                        [CardVariantType.SHOWCASE]: node.showcaseInventory,
                    }).reduce((acc, [type, inventory]) => {
                        if (inventory?.variantId) {
                            acc[type as CardVariantType] = {
                                id: inventory.variantId.toString(),
                                missingCount: inventory.missing?.count || 0,
                                wantCount: wantCard?.variants[type as CardVariantType]?.wantCount || 0,
                            }
                        }
                        return acc
                    }, {} as Record<CardVariantType, {
                        id: string
                        missingCount: number
                        wantCount: number
                    }>)
                }
            })
            .filter(Boolean)
    }, [data?.userCardSetCollection?.nodes, cardsBySetCode, cardsById, selectedSet, existingWantList.cards])

    const filteredCards = useMemo(() => {
        if (!cards) return []

        return cards.filter(card => {
            if (!card) return false
            const nameMatch = card.name.toLowerCase().includes(searchTerm.toLowerCase())
            if (showMissingOnly) {
                return nameMatch && Object.values(card.variants).some(v => v.missingCount > 0)
            }
            return nameMatch
        })
    }, [cards, searchTerm, showMissingOnly])

    if (!selectedSet) {
        return (
            <div className="text-center p-5">
                <h3>Please select a card set to begin.</h3>
            </div>
        )
    }

    const isLoading = queryLoading || (selectedSet && isLoadingSet(selectedSet))

    if (isLoading) {
        return (
            <div className="text-center p-5">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    if (filteredCards.length === 0) {
        return (
            <div className="text-center p-5">
                <h3>
                    {searchTerm
                        ? 'No cards found matching your search criteria.'
                        : 'No cards available in this set.'}
                </h3>
            </div>
        )
    }

    return (
        <div className="want-list-card-picker-table">
            <table>
                <CardTableHeader variantColumns={VARIANT_COLUMNS} />
                <tbody>
                    {filteredCards.map(card => (
                        <CardTableRow
                            key={card!.id}
                            card={card}
                            variantColumns={VARIANT_COLUMNS}
                            wantListId={existingWantList.id}
                            showMissingOnly={showMissingOnly}
                            onItemUpdated={onItemUpdated}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default CardTable
