import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { useGetUserQuery, useUpdateUserMutation } from '../../../graphql/generated/graphql'
import LoadingSpinner from '../../layout/LoadingSpinner'
import ErrorDisplay from '../../layout/ErrorDisplay'

interface FormData {
    email: string
    handle: string
    originalEmail: string
    originalHandle: string
}

const UserEdit: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const { data, loading, error } = useGetUserQuery({ variables: { id: id! } })
    const [updateUser] = useUpdateUserMutation()

    const [formError, setFormError] = useState('')
    const [formData, setFormData] = useState<FormData>({
        email: '',
        handle: '',
        originalEmail: '',
        originalHandle: ''
    })

    // Load initial data
    useEffect(() => {
        if (data?.user) {
            setFormData({
                email: data.user.email,
                handle: data.user.handle || '',
                originalEmail: data.user.email,
                originalHandle: data.user.handle || ''
            });
        }
    }, [data]);

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error.message} />;
    if (!data?.user) return <ErrorDisplay message="User not found" />;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData(prev => ({ ...prev, [id]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setFormError('');

        try {
            const { email, handle, originalEmail, originalHandle } = formData;
            const response = await updateUser({
                variables: {
                    input: {
                        userId: id!,
                        ...(email !== originalEmail && { email }),
                        ...(handle !== originalHandle && { handle })
                    }
                }
            });

            if (response.data?.updateUser?.user) {
                navigate('/admin/users');
            } else {
                setFormError('Failed to update user');
            }
        } catch (error) {
            setFormError(error instanceof Error ? error.message : 'An error occurred');
        }
    };

    return (
        <div className="section">
            <h2 className="section__title">Edit User</h2>
            <div className="shared-list__item">
                {formError && (
                    <div className="alert alert-danger mb-4">{formError}</div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="email" className="form-label">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="handle" className="form-label">
                            Handle
                        </label>
                        <input
                            type="text"
                            id="handle"
                            value={formData.handle}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="No handle set"
                        />
                    </div>

                    <div className="d-flex gap-3">
                        <Button type="submit" variant="primary">
                            Save Changes
                        </Button>
                        <Button
                            variant="outline-danger"
                            onClick={() => navigate('/admin/users')}
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UserEdit;
