import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CardPicker from './card_picker/CardPicker';
import EditWantListItemsTable from './EditWantListItemsTable';
import { WantListType } from '../../../../types/wantListTypes';

interface WantListEditorProps {
    wantList: WantListType
    effectiveHandle: string | undefined
    onItemsUpdated?: () => void
}


const WantListEditor: React.FC<WantListEditorProps> = ({
    wantList,
    effectiveHandle,
    onItemsUpdated
}) => {
    const [cardPickerModalOpen, setCardPickerModalOpen] = useState(false);
    const handleCloseModal = () => {
        setCardPickerModalOpen(false)
    }

    const handleItemsUpdated = () => {
        onItemsUpdated?.()
    }

    return (
        <div>
            <div className="d-flex justify-content-end mb-3">
                <Button
                    onClick={() => setCardPickerModalOpen(true)}
                    variant="add"
                >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add Cards
                </Button>
            </div>

            <EditWantListItemsTable
                wantList={wantList}
                onItemUpdated={handleItemsUpdated}
            />
            <CardPicker
                show={cardPickerModalOpen}
                onHide={handleCloseModal}
                effectiveHandle={effectiveHandle}
                onItemUpdated={handleItemsUpdated}
                existingWantList={wantList}
            />
        </div>
    )
}

export default WantListEditor
