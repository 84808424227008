import React, { useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { getCardVariantTypeDisplay } from '../../../utils/cardUtils';
import { useCardSets } from '../../../contexts/CardSetContext';
import { SaleListVariantPriceType } from '../../../types/saleListTypes';
import { CardItem, VariantItem } from './OfferModal';

interface CardListProps {
    cards: Array<CardItem>;
    selectedVariantIds: string[];
    onAddCard: (card: CardItem, variant: VariantItem) => void;
    mode: 'buy' | 'sell';
}

const renderPrice = (priceType: SaleListVariantPriceType, fixedPriceInCents: number | null, marketPriceInCents: number | null) => {
    switch (priceType) {
        case SaleListVariantPriceType.FIXED:
            return `$${(fixedPriceInCents! / 100).toFixed(2)}`;
        case SaleListVariantPriceType.MARKET:
            return marketPriceInCents ? `$${(marketPriceInCents / 100).toFixed(2)} (Market)` : 'Market Price';
        case SaleListVariantPriceType.CONTACT:
            return 'Contact for Price';
    }
};

const CardList: React.FC<CardListProps> = ({ cards, selectedVariantIds, onAddCard, mode }) => {
    const { cardSets } = useCardSets();
    const [collapsedSets, setCollapsedSets] = useState<Set<string>>(new Set());

    const setNames = useMemo(() => {
        return cardSets?.reduce((acc, set) => {
            if (set?.id) acc[set.id] = set.name;
            return acc;
        }, {} as Record<string, string>) ?? {};
    }, [cardSets]);

    const cardsBySet = cards.reduce((acc, card) => {
        const setId = card.setId;
        if (!acc[setId]) acc[setId] = [];
        acc[setId].push(card);
        return acc;
    }, {} as Record<string, CardItem[]>);

    Object.values(cardsBySet).forEach(setCards => {
        setCards.sort((a, b) => a.standardFullCardNumber.localeCompare(b.standardFullCardNumber));
    });

    return (
        <div className="offer-section">
            <h3 className="offer-section__title">Available Cards</h3>
            <div className="offer-section__content">
                {Object.entries(cardsBySet).map(([setId, setCards]) => (
                    <section key={setId}>
                        <h4
                            className={`section__title section__title--collapsible ${collapsedSets.has(setId) ? 'collapsed' : ''}`}
                            onClick={() => {
                                setCollapsedSets(prev => {
                                    const next = new Set(prev);
                                    if (next.has(setId)) {
                                        next.delete(setId);
                                    } else {
                                        next.add(setId);
                                    }
                                    return next;
                                });
                            }}
                        >
                            {setNames[setId] || `Set ${setId}`}
                        </h4>
                        {!collapsedSets.has(setId) && (
                            <div className="section__content">
                                {setCards.map(card => (
                                    <div key={card.id} className="offer-card">
                                        <div className="offer-card__header">
                                            <span className="offer-card__number">
                                                {card.standardFullCardNumber}
                                            </span>
                                            <h4 className="offer-card__name">{card.name}</h4>
                                        </div>

                                        <div className="offer-card__variants">
                                            {card.variants.map(variant => (
                                                <div
                                                    key={variant.id}
                                                    className={`available-variant-row ${selectedVariantIds.includes(variant.id) ? 'available-variant-row--selected' : ''
                                                        } ${variant.isWanted ? 'available-variant-row--wanted' : ''}`}
                                                >
                                                    <div className="available-variant-row__info">
                                                        <span className="variant-type">
                                                            {getCardVariantTypeDisplay(variant.type)}
                                                            {variant.quantity > 1 && ` (${variant.quantity})`}
                                                            {variant.isWanted && <span className="wanted-badge" title="In your want list">★</span>}
                                                        </span>
                                                        {mode === 'buy' && (
                                                            <span className="price-info">
                                                                {renderPrice(variant.priceType, variant.fixedPriceInCents, variant.marketPriceInCents)}
                                                            </span>
                                                        )}
                                                    </div>

                                                    {!selectedVariantIds.includes(variant.id) && (
                                                        <Button
                                                            variant="outline-primary"
                                                            size="sm"
                                                            className="btn-icon"
                                                            onClick={() => onAddCard(card, variant)}
                                                        >
                                                            <FontAwesomeIcon icon={faArrowRight} />
                                                        </Button>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </section>
                ))}
            </div>
        </div>
    );
};

export default CardList;
