import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CardSetCodeEnum } from '../../../../../types/cardSetType'
import { WantListType } from '../../../../../types/wantListTypes'
import CardSetSelector from '../../../_shared/card_picker/CardSetSelector'
import CardSearch from './CardSearch'
import CardTable from './CardTable'

interface CardPickerProps {
    show: boolean
    onHide: () => void
    effectiveHandle: string | undefined
    onItemUpdated: () => void
    existingWantList: WantListType
}

const CardPicker = ({
    show,
    onHide,
    effectiveHandle,
    onItemUpdated,
    existingWantList
}: CardPickerProps) => {

    const [selectedSet, setSelectedSet] = useState(() => {
        const saved = localStorage.getItem('wantListCardPickerLastSet')
        return saved ? saved as CardSetCodeEnum : CardSetCodeEnum.SOR
    })
    const [searchTerm, setSearchTerm] = useState('')
    const [showMissingOnly, setShowMissingOnly] = useState(true)

    const onSelectSet = (set: CardSetCodeEnum) => {
        setSelectedSet(set)
        localStorage.setItem('wantListCardPickerLastSet', set)
    }

    const onSearchChange = (term: string) => {
        setSearchTerm(term)
    }

    const onShowMissingOnlyChange = (show: boolean) => {
        setShowMissingOnly(show)
    }

    return (
        <Modal show={show} onHide={onHide} size="xl" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Add Cards to Want List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <CardSetSelector
                        selectedSet={selectedSet}
                        onSelectSet={onSelectSet}
                    />
                </div>
                <div className="mb-4">
                    <CardSearch
                        searchTerm={searchTerm}
                        onSearchChange={onSearchChange}
                        showMissingOnly={showMissingOnly}
                        onShowMissingOnlyChange={onShowMissingOnlyChange}
                        selectedSet={selectedSet}
                    />
                </div>
                <CardTable
                    selectedSet={selectedSet}
                    searchTerm={searchTerm}
                    showMissingOnly={showMissingOnly}
                    effectiveHandle={effectiveHandle}
                    onItemUpdated={onItemUpdated}
                    existingWantList={existingWantList}
                />
            </Modal.Body>
        </Modal>
    )
}

export default CardPicker
