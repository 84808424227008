import React from 'react';
import InventoryEdit from './edit/InventoryEdit';
import { VariantColumn } from './InventoryCardTable';
import CardHover from '../../card/CardHover';
import { UpdateCollectionVariantInventoryMutation } from '../../../graphql/generated/graphql';
import MissingBadge from '../_shared/MissingBadge';
import { InventoryDetails, UserCardInventory } from '../../../types/inventoryTypes';

interface InventoryCardTableRowProps {
    cardInventory: UserCardInventory;
    isEditMode: boolean;
    isOwner: boolean;
    onInventoryUpdate: (variantId: number, field: 'inventory' | 'missing', newValue: number | null, oldValue: number | null) => Promise<UpdateCollectionVariantInventoryMutation>;
    onCardSelect?: (card: UserCardInventory) => void;
    variantColumns: VariantColumn[];
    wantListVariantIds: Set<string> | null;
}

export const InventoryCardTableRow: React.FC<InventoryCardTableRowProps> = ({
    cardInventory,
    isEditMode,
    isOwner,
    onInventoryUpdate,
    onCardSelect,
    variantColumns,
    wantListVariantIds
}) => {
    const handleInventoryChange = async (
        variantId: number,
        field: "inventory" | "missing",
        newValue: number | null,
        oldValue: number | null
    ): Promise<UpdateCollectionVariantInventoryMutation> => {
        return onInventoryUpdate(variantId, field, newValue, oldValue);
    };

    const handleCardClick = () => {
        if (!isEditMode && onCardSelect) {
            onCardSelect(cardInventory);
        }
    };

    const renderInventoryDetails = (inventoryDetails: InventoryDetails | undefined) => {
        if (!inventoryDetails) return '-';

        if (isOwner && isEditMode) {
            return (
                <div className="inventory-edit">
                    <InventoryEdit
                        variantId={inventoryDetails.variantId}
                        initialInventory={inventoryDetails.inventory}
                        initialMissing={{ count: inventoryDetails.missing.count, isDefault: inventoryDetails.missing.isDefault }}
                        onInventoryUpdate={handleInventoryChange}
                    />
                </div>
            );
        } else {
            const isWanted = wantListVariantIds?.has(inventoryDetails.variantId.toString());
            const showWantedBadge = isWanted && inventoryDetails.inventory > 0;

            return (
                <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center gap-2">
                        <span className="inventory-count">
                            {inventoryDetails.inventory}
                        </span>
                        {isOwner && (inventoryDetails.missing.count > 0) && (
                            <>
                                <div className="inventory-divider"></div>
                                <MissingBadge
                                    count={inventoryDetails.missing.count}
                                />
                            </>
                        )}
                        {showWantedBadge && (
                            <span className="wanted-badge" title="In your want list">★</span>
                        )}
                    </div>
                </div>
            );
        }
    };

    return (
        <tr
            onClick={isEditMode ? undefined : handleCardClick}
            className={`${isEditMode ? 'no-hover' : 'clickable'}`}
        >
            <td className="card-number-cell">
                <div className="standard-number">{cardInventory.card.standardCardNumber}</div>
                {cardInventory.card.hyperspaceCardNumber && (
                    <div className="variant-number">HS: {cardInventory.card.hyperspaceCardNumber}</div>
                )}
                {cardInventory.card.showcaseCardNumber && (
                    <div className="variant-number">SC: {cardInventory.card.showcaseCardNumber}</div>
                )}
            </td>
            <td>
                {isEditMode ? (
                    <span>{cardInventory.card.name}</span>
                ) : (
                    <CardHover cardId={cardInventory.card.id}>
                        <span>{cardInventory.card.name}</span>
                    </CardHover>
                )}
            </td>
            {variantColumns.map(({ type }) => {
                const inventoryDetails = cardInventory.inventories[type];
                return (
                    <td key={type} className="inventory-details">
                        {renderInventoryDetails(inventoryDetails)}
                    </td>
                );
            })}
        </tr>
    );
};
