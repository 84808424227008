import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export interface MatchingCardProps {
    count: number;
    icon: IconDefinition;
}

interface SharedListCardProps {
    handle: string;
    updatedAt: string;
    publiclyShared: boolean;
    matchingCardProps?: MatchingCardProps;
    onClick: () => void;
}

const SharedListCard: React.FC<SharedListCardProps> = ({
    handle,
    updatedAt,
    publiclyShared,
    matchingCardProps,
    onClick,
}) => (
    <Card
        className="shared-list__item hover-card clickable"
        onClick={onClick}
    >
        {matchingCardProps && (
            <div className="position-absolute top-0 end-0 p-2">
                <span className="badge bg-primary d-flex align-items-center gap-1">
                    {matchingCardProps.count}
                    <FontAwesomeIcon
                        icon={matchingCardProps.icon}
                        size="sm"
                    />
                </span>
            </div>
        )}
        <Card.Body>
            <div className="d-flex align-items-center mb-2">
                <FontAwesomeIcon
                    icon={publiclyShared ? faGlobeAmericas : faUserFriends}
                    title={publiclyShared ? "Shared with everyone" : "Shared privately"}
                    className="me-2"
                />
                <Card.Title className="shared-list__name mb-0">
                    {handle}
                </Card.Title>
            </div>
            <Card.Text className="shared-list__info mb-0">
                Last updated: {new Date(updatedAt).toLocaleDateString()}
            </Card.Text>
        </Card.Body>
    </Card>
);

export default SharedListCard;
