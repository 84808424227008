import React from 'react';
import { CardVariant, CardVariantType } from "../../types/cardType";

const VARIANT_PREFIXES: Record<CardVariantType, string> = {
    [CardVariantType.STANDARD]: 'S',
    [CardVariantType.FOIL]: 'F',
    [CardVariantType.HYPERSPACE]: 'HS',
    [CardVariantType.HYPERSPACE_FOIL]: 'HSF',
    [CardVariantType.SHOWCASE]: 'SC'
};

const VARIANT_ORDER: CardVariantType[] = [
    CardVariantType.STANDARD,
    CardVariantType.FOIL,
    CardVariantType.HYPERSPACE,
    CardVariantType.HYPERSPACE_FOIL,
    CardVariantType.SHOWCASE
];

export enum CardMarketPricesPrefixType {
    FULL = 'full',
    COMPACT = 'compact'
}

interface CardMarketPricesProps {
    variants: Partial<Record<CardVariantType, CardVariant>>;
    prefixType?: CardMarketPricesPrefixType;
    className?: string;
}

export const CardMarketPrices: React.FC<CardMarketPricesProps> = ({
    variants,
    prefixType = CardMarketPricesPrefixType.COMPACT,
    className
}) => {
    const getPrefix = (variantType: CardVariantType): string => {
        if (prefixType === CardMarketPricesPrefixType.FULL) {
            return VARIANT_PREFIXES[variantType] + ': ';
        }

        // Compact mode
        return [CardVariantType.FOIL, CardVariantType.HYPERSPACE_FOIL].includes(variantType)
            ? 'Foil: '
            : '';
    };

    const sortedPrices = VARIANT_ORDER
        .map(variantType => {
            const variant = variants[variantType];
            return variant ? {
                prefix: getPrefix(variantType),
                price: variant.currentMarketPrice?.priceCents
            } : null;
        })
        .filter((item): item is { prefix: string; price: number } => item !== null);

    return (
        <div className={`game-card__market-prices ${className || ''}`}>
            {sortedPrices.map(({ prefix, price }) => (
                <div className="price-item" key={prefix || price}>
                    {prefix}${price ? (price / 100).toFixed(2) : 'N/A'}
                </div>
            ))
            }
        </div >
    );
};

export default CardMarketPrices;
