import { useState, useMemo, useCallback } from 'react';
import { Card, CardVariantType } from '../types/cardType';
import { UserCardInventory } from '../types/inventoryTypes';
import { CardDetailKeys, SortColumn } from './useSortedCardInventory'

type SortableItem = Card | UserCardInventory;
export type SortDirection = 'asc' | 'desc';

const getCardValue = (card: Card, key: CardDetailKeys): string | number => {
    return card[key]?.toString() ?? '';
};

const getInventoryValue = (item: UserCardInventory, key: SortColumn): string | number => {
    if (key.endsWith('Inventory')) {
        const type = key.replace('Inventory', '') as CardVariantType;
        const inventoryDetails = item.inventories[type];
        return inventoryDetails?.inventory ?? 0;
    }
    return getCardValue(item.card, key as CardDetailKeys);
};

const getSortValue = (item: Card | UserCardInventory, key: SortColumn): string | number => {
    if ('card' in item) {
        return getInventoryValue(item, key);
    }
    return getCardValue(item, key as CardDetailKeys);
};

const compare = (a: string | number, b: string | number, direction: SortDirection): number => {
    if (typeof a === 'number' && typeof b === 'number') {
        return direction === 'asc' ? a - b : b - a;
    }
    return direction === 'asc' ? String(a).localeCompare(String(b)) : String(b).localeCompare(String(a));
};

export function useSort<T extends SortableItem>(
    items: T[],
    defaultSortColumn: SortColumn,
    defaultDirection: SortDirection = 'asc'
) {
    const [sortColumn, setSortColumn] = useState(defaultSortColumn);
    const [sortDirection, setSortDirection] = useState<SortDirection>(defaultDirection);

    const handleSort = useCallback((column: SortColumn) => {
        setSortColumn(column);
        setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    }, []);

    const sortedItems = useMemo(() => {
        return [...items].sort((a, b) => {
            const aValue = getSortValue(a, sortColumn);
            const bValue = getSortValue(b, sortColumn);
            return compare(aValue, bValue, sortDirection);
        });
    }, [items, sortColumn, sortDirection]);

    return { sortedItems, sortColumn, sortDirection, handleSort };
}
