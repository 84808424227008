import React, { ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react"
import { Card } from "../types/cardType"
import {
    Card as ApiCard,
    useGetCardSetVariantsLazyQuery,
    useGetCardLazyQuery,
    useGetCardsLazyQuery
} from "../graphql/generated/graphql"
import { CardSetCodeEnum } from "../types/cardSetType"
import { mapApiCardToCard, mapCardSetCodeToApiCardSetCode } from "../utils/apiMapperUtils"

// Track loading state for each operation
type LoadingState = {
    cards: Set<string>
    sets: Set<CardSetCodeEnum>
    batches: Set<string>
}

interface CardContextType {
    // Data
    cardsById: Record<string, Card>
    cardsBySetCode: Record<CardSetCodeEnum, Card[]>

    // Loading states
    isLoadingCard: (cardId: string) => boolean
    isLoadingCards: (cardIds: string[]) => boolean
    isLoadingSet: (setCode: CardSetCodeEnum) => boolean

    // Actions
    ensureCardLoaded: (cardId: string) => Promise<void>
    ensureCardsLoaded: (cardIds: string[]) => Promise<void>
    ensureSetLoaded: (setCode: CardSetCodeEnum) => Promise<void>

    // Error state
    error: Error | null
}

const CardContext = createContext<CardContextType | undefined>(undefined)

export function CardProvider({ children }: { children: ReactNode }) {
    // State
    const [cardsById, setCardsById] = useState<Record<string, Card>>({})
    const [cardsBySetCode, setCardsBySetCode] = useState<Record<CardSetCodeEnum, Card[]>>({} as Record<CardSetCodeEnum, Card[]>)
    const [loadingState, setLoadingState] = useState<LoadingState>({
        cards: new Set(),
        sets: new Set(),
        batches: new Set()
    })
    const [error, setError] = useState<Error | null>(null)

    // GraphQL queries
    const [getCardSetCards] = useGetCardSetVariantsLazyQuery()
    const [getCard] = useGetCardLazyQuery()
    const [getCards] = useGetCardsLazyQuery()

    // Loading state management
    const startLoading = useCallback((type: keyof LoadingState, id: string) => {
        setLoadingState(prev => ({
            ...prev,
            [type]: new Set(Array.from(prev[type]).concat(id))
        }))
    }, [])

    const stopLoading = useCallback((type: keyof LoadingState, id: string) => {
        setLoadingState(prev => {
            const next = new Set(prev[type])
            next.delete(id)
            return { ...prev, [type]: next }
        })
    }, [])

    // API calls
    const fetchCard = useCallback(async (cardId: string) => {
        const { data } = await getCard({ variables: { id: cardId } })
        if (!data?.card) return null
        return mapApiCardToCard(data.card as ApiCard, data.card.cardSet.id)
    }, [getCard])

    const fetchCards = useCallback(async (cardIds: string[]) => {
        const { data } = await getCards({ variables: { ids: cardIds } })
        if (!data?.cards?.nodes) return []
        return data.cards.nodes
            .filter((card): card is NonNullable<typeof card> => Boolean(card))
            .map(card => mapApiCardToCard(card as ApiCard, card.cardSet?.id ?? ''))
    }, [getCards])

    const fetchCardSet = useCallback(async (setCode: CardSetCodeEnum) => {
        const { data } = await getCardSetCards({
            variables: { cardSetCode: mapCardSetCodeToApiCardSetCode(setCode) }
        })
        return data?.cardSet?.cards?.nodes
            ?.filter((card): card is NonNullable<typeof card> => Boolean(card))
            .map(card => mapApiCardToCard(card as ApiCard, data.cardSet?.id ?? '')) ?? []
    }, [getCardSetCards])

    // Public actions
    const ensureCardLoaded = useCallback(async (cardId: string) => {
        if (cardsById[cardId] || loadingState.cards.has(cardId)) return

        startLoading('cards', cardId)
        try {
            const card = await fetchCard(cardId)
            if (card) {
                setCardsById(prev => ({ ...prev, [cardId]: card }))
            }
        } catch (err) {
            setError(err as Error)
        } finally {
            stopLoading('cards', cardId)
        }
    }, [cardsById, fetchCard, loadingState.cards, startLoading, stopLoading])

    const ensureCardsLoaded = useCallback(async (cardIds: string[]) => {
        const unloadedIds = cardIds.filter(id =>
            !cardsById[id] && !loadingState.cards.has(id))

        if (unloadedIds.length === 0) return

        const batchId = `batch_${Date.now()}`
        startLoading('batches', batchId)

        try {
            const cards = await fetchCards(unloadedIds)
            setCardsById(prev => ({
                ...prev,
                ...Object.fromEntries(cards.map(card => [card.id, card]))
            }))
        } catch (err) {
            setError(err as Error)
        } finally {
            stopLoading('batches', batchId)
        }
    }, [cardsById, fetchCards, loadingState.cards, startLoading, stopLoading])

    const ensureSetLoaded = useCallback(async (setCode: CardSetCodeEnum) => {
        if (cardsBySetCode[setCode] || loadingState.sets.has(setCode)) return

        startLoading('sets', setCode)
        try {
            const cards = await fetchCardSet(setCode)
            setCardsBySetCode(prev => ({ ...prev, [setCode]: cards }))
            setCardsById(prev => ({
                ...prev,
                ...Object.fromEntries(cards.map(card => [card.id, card]))
            }))
        } catch (err) {
            setError(err as Error)
        } finally {
            stopLoading('sets', setCode)
        }
    }, [cardsBySetCode, fetchCardSet, loadingState.sets, startLoading, stopLoading])

    // Loading state checks
    const isLoadingCard = useCallback((cardId: string) =>
        loadingState.cards.has(cardId), [loadingState])

    const isLoadingCards = useCallback((cardIds: string[]) =>
        cardIds.some(id => loadingState.cards.has(id)) ||
        loadingState.batches.size > 0, [loadingState])

    const isLoadingSet = useCallback((setCode: CardSetCodeEnum) =>
        loadingState.sets.has(setCode), [loadingState])

    const value = useMemo(() => ({
        cardsById,
        cardsBySetCode,
        isLoadingCard,
        isLoadingCards,
        isLoadingSet,
        ensureCardLoaded,
        ensureCardsLoaded,
        ensureSetLoaded,
        error
    }), [
        cardsById,
        cardsBySetCode,
        isLoadingCard,
        isLoadingCards,
        isLoadingSet,
        ensureCardLoaded,
        ensureCardsLoaded,
        ensureSetLoaded,
        error
    ])

    return <CardContext.Provider value={value}>{children}</CardContext.Provider>
}

export function useCards() {
    const context = useContext(CardContext)
    if (!context) {
        throw new Error('useCards must be used within a CardProvider')
    }
    return context
}
