import React from 'react';
import { Card } from 'react-bootstrap';
import { useGetSharedWantListsQuery } from '../../graphql/generated/graphql';
import SharedListGrid from './SharedListGrid';
import { Icons } from '../../constants/icons';

const EmptyState = () => (
    <Card className="banner">
        <h2>No Shared Want Lists</h2>
        <p>No one has shared their want lists yet.</p>
    </Card>
);

const SharedWantLists: React.FC = () => (
    <SharedListGrid
        useQueryHook={useGetSharedWantListsQuery}
        dataKey="sharedWantLists"
        EmptyState={EmptyState}
        getNavigationPath={(handle) => `/collections/${handle}/want`}
        matchingCardIcon={Icons.SaleList}
        matchingCountField="matchingSaleListItemCount"
    />
);

export default SharedWantLists;
