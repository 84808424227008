export interface CardSet {
    id: string;
    name: string;
    code: CardSetCodeEnum;
    imageUrl: string;
    description: string;
}
export enum CardSetCodeEnum {
    SOR = 'SOR',
    SHD = 'SHD',
    TWI = 'TWI',
}
