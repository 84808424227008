import { CardVariantType } from '../types/cardType';

export function getCardVariantTypeDisplay(variantType: CardVariantType): string {
    switch (variantType) {
        case CardVariantType.STANDARD:
            return 'Standard';
        case CardVariantType.FOIL:
            return 'Foil';
        case CardVariantType.HYPERSPACE:
            return 'Hyperspace';
        case CardVariantType.HYPERSPACE_FOIL:
            return 'Hyperspace Foil';
        case CardVariantType.SHOWCASE:
            return 'Showcase';
        default:
            return 'Unknown';
    }
}

export const orderedVariantTypes: CardVariantType[] = [
    CardVariantType.STANDARD,
    CardVariantType.FOIL,
    CardVariantType.HYPERSPACE,
    CardVariantType.HYPERSPACE_FOIL,
    CardVariantType.SHOWCASE
];


