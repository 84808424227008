import React, { useEffect, useMemo, useState } from 'react';
import ErrorDisplay from '../../../layout/ErrorDisplay';
import SaleCard from './SaleCard';
import EmptySaleList from './EmptySaleList';
import { useAuth } from '../../../../contexts/AuthContext';
import { SaleCardData, SaleListGroupBy, SaleListType } from '../../../../types/saleListTypes';
import { ApolloError } from '@apollo/client';
import { compareCards } from '../../../../utils/saleListCardUtils';
import { useGetUserWantListsQuery } from '../../../../graphql/generated/graphql';
import { Button, Form } from 'react-bootstrap';
import { useCardSets } from '../../../../contexts/CardSetContext';
import ListGroupControl from '../../_shared/ListGroupControl';
import { useSearchParams } from 'react-router-dom';
import GroupedSaleCards from './GroupedSaleCards';
import CardModal from '../../../card/CardModal';
import { Card, ArenaType } from '../../../../types/cardType';
import CardFilters from '../../../filters/CardFilters';
import { useCardFilters } from '../../../../hooks/useCardFilters';

interface SaleListDisplayProps {
    effectiveHandle: string | undefined;
    isOwnSaleList: boolean;
    saleList: SaleListType;
    error: ApolloError | undefined;
    onWantedCardsLoad: (wantedCardVariants: Set<string>) => void;
}

const SaleListDisplay: React.FC<SaleListDisplayProps> = ({ effectiveHandle, isOwnSaleList, saleList, error, onWantedCardsLoad }) => {
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState<Card | null>(null);
    const { isAuthenticated, user } = useAuth();
    const { cardSets } = useCardSets();
    const [collapsedSets, setCollapsedSets] = useState<Set<string>>(new Set());
    const { data: wantListData, loading: wantListLoading } = useGetUserWantListsQuery({
        skip: !isAuthenticated || isOwnSaleList
    });
    const [showWantedOnly, setShowWantedOnly] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { filteredCards: filteredByTypeCards, filters, updateFilter, rarities, cardTypes, arenas, costs, traits, keywords } = useCardFilters(saleList.cards.map(card => card.card));

    const groupBy = (searchParams.get('groupBy') as SaleListGroupBy) || SaleListGroupBy.CardNumber;


    const setNames = useMemo(() => {
        return cardSets?.reduce((acc, set) => {
            if (set?.id) acc[set.id] = set.name;
            return acc;
        }, {} as Record<string, string>) ?? {};
    }, [cardSets]);

    const wantedCardVariants = useMemo(() => {
        if (!wantListData?.userWantLists?.[0]) return new Set<string>();

        const variantIds = new Set<string>();
        wantListData.userWantLists[0].items.nodes?.forEach((item) => {
            const variantId = item?.cardVariant?.id;
            if (variantId) variantIds.add(variantId);
        });

        return variantIds;
    }, [wantListData]);

    useEffect(() => {
        onWantedCardsLoad(wantedCardVariants);
    }, [wantedCardVariants, onWantedCardsLoad]);

    const filteredCards = useMemo(() => {
        const cards = saleList.cards.filter(card =>
            filteredByTypeCards.some(filteredCard => filteredCard.id === card.card.id)
        );

        if (wantListLoading) return cards;
        if (!showWantedOnly || isOwnSaleList) return cards;

        return cards.map(saleCard => ({
            ...saleCard,
            variants: Object.fromEntries(
                Object.entries(saleCard.variants).filter(([, variant]) =>
                    wantedCardVariants.has(variant.id)
                )
            )
        })).filter(card => Object.keys(card.variants).length > 0);
    }, [saleList.cards, showWantedOnly, isOwnSaleList, wantedCardVariants, wantListLoading, filteredByTypeCards]);

    if (error) return <ErrorDisplay message={error.message} />;
    if (saleList.cards.length === 0) {
        return <EmptySaleList isOwnProfile={isOwnSaleList} user={effectiveHandle} isAuthenticated={isAuthenticated} />;
    }

    const toggleSetSection = (setId: string) => {
        setCollapsedSets(prev => {
            const next = new Set(prev);
            if (next.has(setId)) {
                next.delete(setId);
            } else {
                next.add(setId);
            }
            return next;
        });
    };

    const groupCardsBySet = (cards: SaleCardData[]) =>
        cards.reduce((acc, card) => {
            const setId = card.card.cardSetId;
            if (!acc[setId]) acc[setId] = [];
            acc[setId].push(card);
            return acc;
        }, {} as Record<string, SaleCardData[]>);

    const groupByOptions = [
        { value: SaleListGroupBy.CardNumber, display: 'Card Number' },
        { value: SaleListGroupBy.VariantType, display: 'Variant Type' },
    ];

    const handleCardClick = (saleCard: SaleCardData) => {
        setSelectedCard(saleCard.card);
    };

    const handleCloseModal = () => {
        setSelectedCard(null);
    };

    return (
        <div className="list-container">
            {saleList.settings.banner && (
                <div className="card banner mb-5">
                    <p>{saleList.settings.banner}</p>
                </div>
            )}
            <div className="d-flex mb-3">
                <Button
                    onClick={() => setFiltersOpen(!filtersOpen)}
                    aria-controls="game-card-filters"
                    aria-expanded={filtersOpen}
                    variant="outline-primary"
                >
                    {filtersOpen ? 'Hide Filters' : 'Show Filters'}
                </Button>
            </div>

            <CardFilters
                filters={filters}
                updateFilter={updateFilter}
                rarities={rarities || []}
                cardTypes={cardTypes}
                arenas={arenas as ArenaType[]}
                costs={costs as number[]}
                traits={traits || []}
                keywords={keywords || []}
                disabled={false}
                isOpen={filtersOpen}
                onClose={() => setFiltersOpen(false)}
            />

            <div className="d-flex flex-column flex-sm-row justify-content-between mb-3 gap-2">
                {saleList.cards.length > 0}
                <ListGroupControl
                    initialGroupBy={groupBy}
                    options={groupByOptions}
                    onChange={(newGroupBy) => {
                        setSearchParams(prev => {
                            const params = new URLSearchParams(prev);
                            params.set('groupBy', newGroupBy);
                            return params;
                        }, { replace: true });
                    }}
                />
                {!isOwnSaleList && isAuthenticated && user?.handle && (
                    <div className="d-flex justify-content-sm-end mb-3">
                        <Form.Check
                            type="switch"
                            id="wanted-only-switch"
                            label="Show Wanted Cards Only"
                            checked={showWantedOnly}
                            disabled={wantListLoading}
                            onChange={(e) => setShowWantedOnly(e.target.checked)}
                        />
                    </div>
                )}
            </div>
            {Object.entries(groupCardsBySet(filteredCards)).map(([setId, cards]) => (
                <section key={setId}>
                    <h2
                        className={`section__title section__title--collapsible ${collapsedSets.has(setId) ? 'collapsed' : ''
                            }`}
                        onClick={() => toggleSetSection(setId)}
                    >
                        {setNames[setId] || `Set ${setId}`}
                    </h2>
                    {!collapsedSets.has(setId) && (
                        <div className="section__content mb-4">
                            {groupBy === SaleListGroupBy.VariantType ? (
                                <GroupedSaleCards
                                    cards={cards.sort(compareCards)}
                                    wantListVariantIds={wantedCardVariants}
                                    onCardClick={handleCardClick}
                                />
                            ) : (
                                <div className="list-grid">
                                    {cards
                                        .sort(compareCards)
                                        .map(card => (
                                            <SaleCard
                                                key={card.card.id}
                                                saleCard={card}
                                                wantListVariantIds={wantedCardVariants}
                                                onCardClick={handleCardClick}
                                            />
                                        ))}
                                </div>
                            )}
                        </div>
                    )}
                </section>
            ))}
            {selectedCard && (
                <CardModal
                    card={selectedCard}
                    loading={false}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default SaleListDisplay;
