import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import CurrencyInput from 'react-currency-input-field';

interface FixedPriceInputProps {
    fixedPriceInCents: number | null;
    onChange: (fixedPriceInCents: number | null) => void;
    className?: string;
}

const FixedPriceInput: React.FC<FixedPriceInputProps> = ({
    fixedPriceInCents,
    onChange,
    className = ''
}) => {
    const [localValue, setLocalValue] = useState<string>(((fixedPriceInCents || 0) / 100).toFixed(2));

    return (
        <InputGroup className={`fixed-price-input ${className}`}>
            <InputGroup.Text>
                <FontAwesomeIcon icon={faDollarSign} />
            </InputGroup.Text>
            <CurrencyInput
                className="form-control"
                decimalScale={2}
                allowNegativeValue={false}
                placeholder="0.00"
                maxLength={6}
                value={localValue}
                onValueChange={(value: string | undefined) => {
                    const numValue = parseFloat(value || '0');
                    if (numValue <= 50000) {
                        setLocalValue(value || '0');
                    }
                }}
                onBlur={(e) => {
                    const value = e.target.value;
                    const numValue = parseFloat(value || '0');
                    if (numValue <= 50000) {
                        onChange(Math.round(numValue * 100));
                        setLocalValue(numValue.toFixed(2));
                    }
                }}
            />
        </InputGroup>
    );
};

export default FixedPriceInput;
