import { Card, CardVariantType } from "./cardType";

export enum WantListGroupBy {
    CardNumber = 'CARD_NUMBER',
    VariantType = 'VARIANT_TYPE'
}

export interface WantListType {
    id: string;
    cards: WantListCardData[];
}

export interface CardVariantData {
    id: string;
    wantCount: number;
}

export interface WantListCardData {
    id: string;
    card: Card;
    variants: Partial<Record<CardVariantType, CardVariantData>>;
}
