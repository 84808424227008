import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import CardList from './CardList';
import { SelectedCards } from './SelectedCards';
import { CardVariantType } from '../../../types/cardType';
import { SaleListVariantPriceType } from '../../../types/saleListTypes';

export interface CardItem {
    id: string;
    name: string;
    setId: string;
    standardFullCardNumber: string;
    variants: Array<VariantItem>;
}

export interface VariantItem {
    id: string;
    isWanted: boolean;
    type: CardVariantType;
    priceType: SaleListVariantPriceType;
    fixedPriceInCents: number | null;
    marketPriceInCents: number | null;
    quantity: number;
}

export interface SelectedCard {
    cardId: string;
    cardName: string;
    cardNumber: string;
    variantId: string;
    variantType: CardVariantType;
    quantity: number;
    priceType: SaleListVariantPriceType;
    fixedPriceInCents: number | null;
    marketPriceInCents: number | null;
    maxQuantity: number;
}

interface OfferModalProps {
    show: boolean;
    onHide: () => void;
    title: string;
    availableCards: Array<CardItem>;
    selectedCards: Array<SelectedCard>;
    onAddCard: (card: CardItem, variant: VariantItem) => void;
    onRemoveCard: (variantId: string) => void;
    onQuantityChange?: (variantId: string, quantity: number) => void;
    onPriceChange?: (variantId: string, priceType: SaleListVariantPriceType, fixedPriceInCents: number | null) => void;
    onSubmit: () => void;
    mode: 'buy' | 'sell';
    onNoteChange?: (note: string) => void;
}

const OfferModal: React.FC<OfferModalProps> = ({
    show,
    onHide,
    title,
    availableCards,
    selectedCards,
    onAddCard,
    onRemoveCard,
    onQuantityChange,
    onPriceChange,
    onNoteChange,
    onSubmit,
    mode
}) => {
    const [note, setNote] = useState('');

    return (
        <Modal show={show} onHide={onHide} size="lg" className="offer-modal" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="offer-sections">
                    <div className="offer-section left">
                        <div className="scrollable-content">
                            <CardList
                                cards={availableCards}
                                selectedVariantIds={selectedCards.map(c => c.variantId)}
                                onAddCard={onAddCard}
                                mode={mode}
                            />
                        </div>
                    </div>

                    <div className="divider" />

                    <div className="offer-section right">
                        <div className="scrollable-content">
                            <SelectedCards
                                cards={selectedCards}
                                onRemove={onRemoveCard}
                                onQuantityChange={onQuantityChange}
                                onPriceChange={onPriceChange}
                                mode={mode}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Form.Group className="w-100 mb-3">
                    <Form.Label>Note</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={note}
                        onChange={e => {
                            setNote(e.target.value);
                            onNoteChange?.(e.target.value);
                        }}
                        placeholder="Add a note to your offer..."
                    />
                </Form.Group>

                <div className="d-flex justify-content-end">
                    <Button
                        variant="outline-primary"
                        onClick={onSubmit}
                        disabled={selectedCards.length === 0}
                    >
                        Create Offer
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default OfferModal;
