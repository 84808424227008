import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import MarketSettingsControl from './MarketSettingsControl';
import { SaleListType, SaleListSettingsType, MarketPricingSettingsType, RoundingValue, SaleListRoundingType } from '../../../../types/saleListTypes';

interface EditableSaleListSettingsProps {
    saleList: SaleListType;
    onDirtyStateChange: (isDirty: boolean, settings?: SaleListSettingsType) => void;
    errors?: Record<string, string>;
}

const EditableSaleListSettings: React.FC<EditableSaleListSettingsProps> = ({
    saleList,
    onDirtyStateChange,
    errors = {}
}) => {
    const [bannerMessage, setBannerMessage] = useState(saleList.settings.banner || '');
    const [marketSettings, setMarketSettings] = useState<MarketPricingSettingsType>({
        percentageAdjustment: saleList.settings.marketPricingSettings?.percentageAdjustment ?? 0,
        rounding: {
            type: saleList.settings.marketPricingSettings?.rounding.type ?? SaleListRoundingType.NO_ROUNDING,
            value: (saleList.settings.marketPricingSettings?.rounding.value ?? 0) as RoundingValue
        }
    });

    const originalValues = useMemo(() => ({
        banner: saleList.settings.banner ?? '',
        percentageAdjustment: saleList.settings.marketPricingSettings?.percentageAdjustment ?? 0,
        rounding: {
            type: saleList.settings.marketPricingSettings?.rounding.type ?? SaleListRoundingType.NO_ROUNDING,
            value: (saleList.settings.marketPricingSettings?.rounding.value ?? 0) as RoundingValue
        }
    }), [saleList]);

    useEffect(() => {
        const hasChanges =
            bannerMessage !== originalValues.banner ||
            marketSettings.percentageAdjustment !== originalValues.percentageAdjustment ||
            marketSettings.rounding.type !== originalValues.rounding.type ||
            marketSettings.rounding.value !== originalValues.rounding.value;

        onDirtyStateChange(
            hasChanges,
            hasChanges ? { banner: bannerMessage, marketPricingSettings: marketSettings } : undefined
        );
    }, [bannerMessage, marketSettings, originalValues, onDirtyStateChange]);

    return (
        <div className="d-flex flex-column gap-4">
            {errors.general && (
                <div className="alert alert-danger">{errors.general}</div>
            )}

            <MarketSettingsControl
                settings={marketSettings}
                onSettingsChange={setMarketSettings}
                error={errors.marketPricingSettings}
            />

            <div className="border rounded p-3">
                <Form.Group>
                    <h6>Banner Message</h6>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={bannerMessage}
                        onChange={(e) => setBannerMessage(e.target.value)}
                        placeholder="Enter your banner message..."
                        maxLength={1000}
                        isInvalid={!!errors.banner}
                    />
                    {errors.banner ? (
                        <Form.Control.Feedback type="invalid">
                            {errors.banner}
                        </Form.Control.Feedback>
                    ) : (
                        <Form.Text className="text-muted">
                            {bannerMessage.length}/1000 characters
                        </Form.Text>
                    )}
                </Form.Group>
            </div>
        </div>
    );
};

export default EditableSaleListSettings;
