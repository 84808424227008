import React, { useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Container, Nav, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faGauge, faGear } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../contexts/AuthContext'
import { RoleEnum } from '../../graphql/generated/graphql'

interface NavLinkProps {
    to: string
    icon: typeof faUsers
    text: string
    isActive: boolean
    onClick: () => void
}

const NavLink: React.FC<NavLinkProps> = ({ to, icon, text, isActive, onClick }) => (
    <Nav.Link
        as={Link}
        to={to}
        className={`admin-nav-link ${isActive ? 'active' : ''}`}
        onClick={onClick}
    >
        <FontAwesomeIcon icon={icon} className="me-2" />
        <span className="nav-text">{text}</span>
    </Nav.Link>
)

const MobileHeader: React.FC<{ isNavOpen: boolean; toggleNav: () => void }> = ({
    isNavOpen,
    toggleNav,
}) => (
    <div className="admin-header d-sm-none">
        <Button
            variant="link"
            onClick={toggleNav}
            className={`admin-nav-toggle ${isNavOpen ? 'active' : ''}`}
            aria-label="Toggle navigation"
            aria-expanded={isNavOpen}
        >
            <FontAwesomeIcon icon={faGear} className={isNavOpen ? 'rotate-90' : ''} />
        </Button>
        <h1 className="admin-title">Admin Panel</h1>
    </div>
)

const AdminLayout: React.FC = () => {
    const { isAuthenticated, user } = useAuth()
    const location = useLocation()
    const [isNavOpen, setIsNavOpen] = useState(false)

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    if (!user?.roles?.includes(RoleEnum.Admin)) {
        return <Navigate to="/" replace />
    }

    const navItems = [
        { to: '/admin', icon: faGauge, text: 'Dashboard' },
        { to: '/admin/users', icon: faUsers, text: 'Users' },
    ]

    const renderNavLinks = () => (
        <>
            {navItems.map((item) => (
                <NavLink
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    text={item.text}
                    isActive={location.pathname === item.to}
                    onClick={() => setIsNavOpen(false)}
                />
            ))}
        </>
    )

    return (
        <div className="admin-wrapper">
            <MobileHeader isNavOpen={isNavOpen} toggleNav={() => setIsNavOpen(!isNavOpen)} />

            {/* Mobile Navigation */}
            <div className="d-sm-none">
                {isNavOpen && <Nav className="admin-nav-mobile flex-column">{renderNavLinks()}</Nav>}
            </div>

            {/* Desktop Navigation */}
            <nav className="admin-sidebar d-none d-sm-block">
                <Nav className="admin-nav flex-column">{renderNavLinks()}</Nav>
            </nav>

            <main className="admin-main">
                <Container fluid>
                    <Outlet />
                </Container>
            </main>
        </div>
    )
}

export default AdminLayout
