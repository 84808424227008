import React from 'react';
import { Nav } from 'react-bootstrap';
import { CardSet, CardSetCodeEnum } from '../../types/cardSetType';
interface CardSetTabsProps {
    cardSets: CardSet[];
    selectedCardSet: CardSetCodeEnum;
    onSelectCardSet: (code: CardSetCodeEnum) => void;
    disabled?: boolean;
}

const CardSetTabs: React.FC<CardSetTabsProps> = ({ cardSets, selectedCardSet, onSelectCardSet, disabled }) => {
    return (
        <Nav variant="tabs" className="custom-tabs mb-3">
            {cardSets.map((set) => (
                <Nav.Item key={set.code}>
                    <Nav.Link
                        active={selectedCardSet === set.code}
                        onClick={() => onSelectCardSet(set.code)}
                        disabled={disabled}
                        className={`custom-tab ${selectedCardSet === set.code ? 'active' : ''}`}
                    >
                        {set.name}
                    </Nav.Link>
                </Nav.Item>
            ))}
        </Nav>
    );
};

export default CardSetTabs;
