import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faEye, faShare } from '@fortawesome/free-solid-svg-icons';
import WantListDisplay from './display/WantListDisplay';
import WantListEditor from './edit/WantListEditor';
import { useAuth } from '../../../contexts/AuthContext';
import {
    WantList as ApiWantList,
    useGetUserWantListsQuery,
    useGetWantListShareSettingsLazyQuery,
    useUpdateWantListSharingMutation
} from '../../../graphql/generated/graphql';
import ShareCollectionModal from '../_shared/ShareListModal';
import { useShareSettings } from '../../../hooks/useShareSettings';
import CreateSellOfferModal from '../../trade/CreateSellOfferModal';
import OfferBanner from '../_shared/OfferBanner';
import HandleRequiredBanner from '../_shared/HandleRequiredBanner';
import { useCards } from '../../../contexts/CardContext';
import { mapApiWantListToWantList } from '../../../utils/apiMapperUtils';

const WantListPage: React.FC = () => {
    // Hooks and state
    const { handle } = useParams<{ handle: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, user } = useAuth();
    const [isEditMode, setIsEditMode] = useState(location.pathname.endsWith('/edit'));
    const [showOfferModal, setShowOfferModal] = useState(false);
    const [getWantListShareSettings] = useGetWantListShareSettingsLazyQuery();
    const [updateWantListSharing] = useUpdateWantListSharingMutation();
    const { cardsById, ensureCardsLoaded, isLoadingCards } = useCards();


    // Path and authorization checks
    const isMyWantList = /^\/collections\/my\/want(\/edit)?$/.test(location.pathname);
    const isOwnWantList = isMyWantList || (isAuthenticated && user?.handle === handle);
    const effectiveHandle = isMyWantList ? user?.handle : handle;
    const displayHandle = isOwnWantList ? 'My' : `${effectiveHandle}'s`;
    const pageTitle = isEditMode ? 'Edit Want List' : `${displayHandle} Want List`;

    // GraphQL queries and mutations
    const { data, loading: wantListLoading, refetch } = useGetUserWantListsQuery({
        variables: { userHandle: effectiveHandle },
        skip: !effectiveHandle,
    });

    useEffect(() => {
        if (!data?.userWantLists?.[0]) return;

        const cardIds = Array.from(new Set(data.userWantLists[0].items.nodes?.map(item =>
            item?.cardVariant.card.id).filter(Boolean))) as string[];
        ensureCardsLoaded(cardIds);
    }, [data?.userWantLists, ensureCardsLoaded]);

    const wantList = useMemo(() => {
        if (!data?.userWantLists?.[0]) return null;

        const cardIds = data.userWantLists[0].items.nodes
            ?.map(item => item?.cardVariant.card.id)
            .filter((id): id is string => !!id);

        if (cardIds && cardIds.length > 0) {
            // Ensure all cards are loaded before proceeding
            if (isLoadingCards(cardIds)) return null;

            // Check if all cards exist in cardsById
            const allCardsLoaded = cardIds.every(id => cardsById[id]);
            if (!allCardsLoaded) return null;
        }
        return mapApiWantListToWantList(data.userWantLists[0] as ApiWantList, cardsById);
    }, [data?.userWantLists, cardsById, isLoadingCards]);



    const {
        showShareModal,
        setShowShareModal,
        shareError,
        isSharingLoading,
        shareSettings,
        handleShowShareModal,
        handleSaveSharing
    } = useShareSettings({
        getShareSettings: async (id?: string) => {
            if (!id) return { data: { sharedWithAll: false, sharedWithUsers: [] } };

            const result = await getWantListShareSettings({
                variables: { wantListId: id },
                fetchPolicy: 'network-only'
            });
            return {
                data: {
                    sharedWithAll: result.data?.wantList?.shareSettings?.sharedWithAll || false,
                    sharedWithUsers: (result.data?.wantList?.shareSettings?.sharedWithUsers || []).map(user => ({
                        id: String(user.id),
                        handle: user.handle || '',
                        email: user.email
                    }))
                }
            };
        },
        updateSharing: async (variables) => {
            if (!wantList?.id) return { data: { success: false } };

            const result = await updateWantListSharing({
                variables: {
                    input: {
                        wantListId: wantList.id,
                        ...variables.input
                    }
                }
            });
            return { data: result.data?.updateWantListSharing || { success: false } };
        },
        id: wantList?.id,
        idFieldName: 'wantListId'
    });

    // Authentication effect
    useEffect(() => {
        if (isMyWantList && !isAuthenticated) {
            navigate('/login', { state: { from: location.pathname } });
        }
        setIsEditMode(location.pathname.endsWith('/edit'));
    }, [isMyWantList, isAuthenticated, navigate, location.pathname]);

    // Event listener effect
    useEffect(() => {
        const handleWantListUpdated = () => refetch();
        window.addEventListener('want-list-updated', handleWantListUpdated);
        return () => window.removeEventListener('want-list-updated', handleWantListUpdated);
    }, [refetch]);

    // Event handlers
    const toggleEditMode = () => {
        const newMode = !isEditMode;
        setIsEditMode(newMode);
        navigate(newMode ? `/collections/my/want/edit` : `/collections/my/want`);
    };

    const handleItemsUpdated = useCallback(() => {
        window.dispatchEvent(new Event('want-list-updated'));
    }, []);

    const handleCreateOffer = () => {
        setShowOfferModal(true);
    };

    if (isMyWantList && !user?.handle) {
        return (
            <Container className="mt-4">
                <HandleRequiredBanner listType="want" />
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            {wantListLoading || !wantList ? (
                <div className="d-flex justify-content-center my-5">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                    {!isOwnWantList && wantList.cards.length > 0 && (
                        <OfferBanner onCreateOffer={handleCreateOffer} listType="want" />
                    )}
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>{pageTitle}</h2>
                        <div className="d-flex align-items-center gap-2">
                            {isOwnWantList && (
                                <Button
                                    onClick={toggleEditMode}
                                    variant="outline-primary"
                                    className="btn-icon"
                                    title={isEditMode ? "View Mode" : "Edit Mode"}
                                >
                                    <FontAwesomeIcon icon={isEditMode ? faEye : faPencilAlt} />
                                </Button>
                            )}
                            {isOwnWantList && !isEditMode && (
                                <Button
                                    onClick={handleShowShareModal}
                                    disabled={wantListLoading}
                                    variant="outline-primary"
                                    className="btn-icon"
                                    title="Share List"
                                >
                                    <FontAwesomeIcon icon={faShare} />
                                </Button>
                            )}
                        </div>
                    </div>
                    <div style={{ display: isEditMode ? 'block' : 'none' }}>
                        <WantListEditor
                            effectiveHandle={effectiveHandle}
                            onItemsUpdated={handleItemsUpdated}
                            wantList={wantList}
                        />
                    </div>
                    <div style={{ display: isEditMode ? 'none' : 'block' }}>
                        <WantListDisplay
                            effectiveHandle={effectiveHandle}
                            isOwnWantList={isOwnWantList}
                            wantList={wantList}
                        />
                    </div>
                    <ShareCollectionModal
                        show={showShareModal}
                        onHide={() => setShowShareModal(false)}
                        initialSharedWithAll={shareSettings.sharedWithAll}
                        onSave={handleSaveSharing}
                        isSaving={isSharingLoading}
                        error={shareError}
                        sharedWithUsers={shareSettings.sharedWithUsers}
                        shareableLink={`${window.location.origin}/collections/${effectiveHandle}/want`}
                    />
                    <CreateSellOfferModal
                        show={showOfferModal}
                        onHide={() => setShowOfferModal(false)}
                        recipientHandle={effectiveHandle!}
                        wantList={wantList}
                    />
                </>
            )}
        </Container>
    );
};

export default WantListPage;
