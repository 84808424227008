import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getCardVariantTypeDisplay } from '../../../../utils/cardUtils';
import { useAspectImages } from '../../../../hooks/useAspectImages';
import { useSaleList } from './SaleListContext';
import SaleCardPriceInput from './SaleCardPriceInput';
import { SaleListItemConditionEnum, useUpsertSaleListVariantMutation } from '../../../../graphql/generated/graphql';
import { CardVariantData, SaleCardData, SaleListVariantPriceType } from '../../../../types/saleListTypes';
import { CardVariantType } from '../../../../types/cardType';
import { mapSaleListVariantPriceTypeToApi } from '../../../../utils/apiMapperUtils';

interface EditableSaleCardProps {
    saleCard: SaleCardData;
    saleListId: string;
    onItemUpdated: () => void;
}

const EditableSaleCard: React.FC<EditableSaleCardProps> = ({ saleCard, saleListId, onItemUpdated }) => {
    const [updateSaleListVariantCount] = useUpsertSaleListVariantMutation();
    const { getAspectImageUrl, loading: aspectsLoading } = useAspectImages();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [variantToRemove, setVariantToRemove] = useState<{ id: string; type: CardVariantType } | null>(null);
    const { variants, updateVariant, removeVariant } = useSaleList();

    const handleUpdate = async (
        variantId: string,
        newQuantity: number,
        priceType: SaleListVariantPriceType,
        fixedPriceInCents: number | null
    ) => {
        if (newQuantity < 1) return;

        const updatedVariant = {
            saleCount: newQuantity,
            priceType,
            fixedPriceInCents
        };

        updateVariant(variantId, updatedVariant);

        try {
            await updateSaleListVariantCount({
                variables: {
                    saleListId,
                    cardVariantId: variantId,
                    quantity: newQuantity,
                    priceType: mapSaleListVariantPriceTypeToApi(priceType),
                    fixedPriceInCents,
                    condition: SaleListItemConditionEnum.NearMint
                }
            });
            onItemUpdated();
        } catch (error) {
            const variant = Object.values(saleCard.variants).find(v => v.id === variantId);
            if (variant) {
                updateVariant(variantId, variant);
            }

            console.error('Failed to update sale list item:', error);
        }
    };

    const handleConfirmRemove = async () => {
        if (!variantToRemove) return;

        const variantData = variants[variantToRemove.id] ||
            saleCard.variants[variantToRemove.type] ||
            Object.values(saleCard.variants).find(v => v.id === variantToRemove.id);

        if (!variantData) {
            console.error('Variant data not found', { variantToRemove, variants, saleCard });
            setShowConfirmModal(false);
            return;
        }

        try {
            removeVariant(variantToRemove.id);
            await updateSaleListVariantCount({
                variables: {
                    saleListId,
                    cardVariantId: variantToRemove.id,
                    quantity: 0,
                    priceType: mapSaleListVariantPriceTypeToApi(variantData.priceType),
                    fixedPriceInCents: variantData.fixedPriceInCents,
                    condition: SaleListItemConditionEnum.NearMint
                }
            });
            onItemUpdated();
        } catch (error) {
            updateVariant(variantToRemove.id, variantData);
            console.error('Failed to remove variant:', error);
        } finally {
            setShowConfirmModal(false);
        }
    };

    return (
        <>
            <article className="editable-sale-card">
                <header className="sale-card__header">
                    <span className="sale-card__number">{saleCard.card.standardCardNumber}</span>
                    <h3 className="sale-card__name">{saleCard.card.name}</h3>
                    <div className="sale-card__aspects">
                        {!aspectsLoading && saleCard.card.aspects?.map((aspect, index) => (
                            <img key={index} src={getAspectImageUrl(aspect)} alt={aspect} className="aspect-icon" />
                        ))}
                    </div>
                </header>

                <div className="sale-card__variants">
                    {(Object.entries(saleCard.variants) as [CardVariantType, CardVariantData][]).map(([variantType, variant]) => {
                        const variantState = variants[variant.id] || variant;
                        const marketPrice = saleCard.card.variants[variantType]?.currentMarketPrice ? {
                            priceCents: variant.marketAdjustedPriceInCents || 0,
                            recordedAt: saleCard.card.variants[variantType]?.currentMarketPrice?.recordedAt || ''
                        } : null;
                        return (
                            <div key={variant.id} className="variant" >
                                <div className="variant__header">
                                    <span>{getCardVariantTypeDisplay(variantType)}</span>
                                    <Button variant="outline-danger" className="btn-icon" onClick={() => {
                                        setVariantToRemove({ id: variant.id, type: variantType });
                                        setShowConfirmModal(true);
                                    }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>

                                <div className="variant__controls">
                                    <SaleCardPriceInput
                                        priceType={variantState.priceType}
                                        fixedPriceInCents={variantState.fixedPriceInCents}
                                        onChange={(priceType, fixedPriceInCents) => handleUpdate(
                                            variant.id,
                                            variantState.saleCount,
                                            priceType,
                                            fixedPriceInCents
                                        )}
                                        variantId={variant.id}
                                        marketPrice={marketPrice || null}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </article >

            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove the {variantToRemove && getCardVariantTypeDisplay(variantToRemove.type)} variant of {saleCard.card.name} from your sale list?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirmRemove}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditableSaleCard;
