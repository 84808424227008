import { useAspects } from './useAspects';
import { AspectType } from '../types/aspectTypes';
import { getFormattedImageUrl } from '../utils/imageUtils';

export const useAspectImages = () => {
    const { aspectImageMap, loading } = useAspects();

    return {
        getAspectImageUrl: (aspectType: AspectType | null | undefined): string => {
            if (!aspectType || loading) return '';
            const image = aspectImageMap[aspectType];
            return image ? getFormattedImageUrl(image, 40) : '';
        },
        loading
    };
};
