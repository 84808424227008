import React from 'react';
import { Form } from 'react-bootstrap';

interface GroupByOption<T> {
    value: T;
    display: string;
}

interface ListGroupControlProps<T extends string | number> {
    initialGroupBy: T;
    onChange: (groupBy: T) => void;
    options: GroupByOption<T>[];
}

const ListGroupControl = <T extends string | number>({
    initialGroupBy,
    onChange,
    options
}: ListGroupControlProps<T>) => {
    return (
        <Form.Group className="d-flex align-items-center gap-2">
            <Form.Label className="mb-0">Group by:</Form.Label>
            <Form.Select
                size="sm"
                value={initialGroupBy}
                onChange={(e) => onChange(e.target.value as T)}
                style={{ width: 'auto' }}
            >
                {options.map(({ value, display }) => (
                    <option key={value} value={value}>
                        {display}
                    </option>
                ))}
            </Form.Select>
        </Form.Group>
    );
};

export default ListGroupControl;
