import React, { useState, useRef, useEffect, useMemo } from 'react';
import { getFormattedImageUrl } from '../../utils/imageUtils';
import classNames from 'classnames';
import { CardImageType, OrientationType } from '../../types/cardType';

interface CardImageProps {
    image: CardImageType | null | undefined;
    name: string;
    className?: string;
    onClick?: null | (() => void);
}

const CardImage: React.FC<CardImageProps> = ({ image, name, className, onClick = null }) => {
    const [imageError, setImageError] = useState(false);
    const [width, setWidth] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth * 1.5);
            }
        };

        const resizeObserver = new ResizeObserver(updateWidth);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => resizeObserver.disconnect();
    }, []);

    const { imageUrl, isLandscape } = useMemo(() => ({
        imageUrl: image?.url ? getFormattedImageUrl(image.url, width) : null,
        isLandscape: image?.orientation === OrientationType.LANDSCAPE
    }), [image, width]);

    const containerClasses = classNames(
        'game-card-image-container',
        {
            'landscape': isLandscape,
            'portrait': !isLandscape,
            'clickable': onClick,
            'mobile-friendly': true
        },
        className
    );

    const imageClasses = classNames(
        'game-card-image',
        { 'cursor-pointer': onClick }
    );

    const placeholderClasses = classNames(
        'game-card-image-placeholder',
        { 'game-card-image-skeleton': !imageUrl }
    );

    return (
        <div
            ref={containerRef}
            className={containerClasses}
            onClick={onClick || undefined}
            role={onClick ? 'button' : undefined}
            tabIndex={onClick ? 0 : undefined}
        >
            {!imageUrl || imageError ? (
                <div className={placeholderClasses}>
                    <span>{name}</span>
                </div>
            ) : (
                <img
                    src={imageUrl}
                    alt={name}
                    onError={() => setImageError(true)}
                    className={imageClasses}
                    loading="lazy"
                />
            )}
        </div>
    );
};

export default CardImage;
