import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { useAuth } from '../../../contexts/AuthContext';
import LoadingSpinner from '../../layout/LoadingSpinner';
import CollectionSettings from './CollectionSettings';

const UserPreferences: React.FC = () => {
    const { user } = useAuth();

    if (!user) return <LoadingSpinner />;

    return (
        <Container className="mt-4">
            <h1 className="section__title mb-4">User Preferences</h1>

            <Card>
                <Card.Body>
                    <Card.Title>Collection Settings</Card.Title>
                    <CollectionSettings />
                </Card.Body>
            </Card>
        </Container>
    );
};

export default UserPreferences;
