import React, { useState, useMemo } from 'react';
import { Modal, Button, Spinner, Nav } from 'react-bootstrap';
import CardImage from './CardImage';
import { uniqBy, capitalize } from 'lodash';
import { Card, CardVariantType, OrientationType, CardVariant } from '../../types/cardType';
import CardMarketPrices from './CardMarketPrices';

interface CardModalProps {
    card: Card | null | undefined;
    loading: boolean;
    onClose: () => void;
}

const CardModal: React.FC<CardModalProps> = ({ card, loading, onClose }) => {
    const [activeVariant, setActiveVariant] = useState(0);
    const [showBackImage, setShowBackImage] = useState(false);

    const filteredVariants = useMemo(() => {
        if (!card?.variants) return [];

        const uniqueVariants = uniqBy(Object.values(card.variants), 'images.front.url');
        return uniqueVariants.map(variant => {
            const allVariantsForImage = Object.values(card.variants).filter(v =>
                v.images.front.url === variant.images.front.url
            );
            const nonFoilVariant = allVariantsForImage.find(v =>
                v.variantType === CardVariantType.STANDARD ||
                v.variantType === CardVariantType.HYPERSPACE
            );
            return nonFoilVariant || variant;
        });
    }, [card?.variants]);

    const getImageOrientation = (variant: CardVariant): string => {
        const frontIsLandscape = variant.images.front.orientation === OrientationType.LANDSCAPE;
        const backIsLandscape = variant.images.back?.orientation === OrientationType.LANDSCAPE;
        return showBackImage && variant.images.back
            ? (backIsLandscape ? 'landscape' : 'portrait')
            : (frontIsLandscape ? 'landscape' : 'portrait');
    };

    const renderPrices = (variant: CardVariant) => {
        if (!card?.variants) return null;

        const variants: Partial<Record<CardVariantType, CardVariant>> = {};

        switch (variant.variantType) {
            case CardVariantType.STANDARD: {
                const standardFoil = Object.values(card.variants).find(v => v.variantType === CardVariantType.FOIL);
                variants[CardVariantType.STANDARD] = variant;
                if (standardFoil) variants[CardVariantType.FOIL] = standardFoil;
                break;
            }
            case CardVariantType.HYPERSPACE: {
                const hyperspaceFoil = Object.values(card.variants).find(v => v.variantType === CardVariantType.HYPERSPACE_FOIL);
                variants[CardVariantType.HYPERSPACE] = variant;
                if (hyperspaceFoil) variants[CardVariantType.HYPERSPACE_FOIL] = hyperspaceFoil;
                break;
            }
            case CardVariantType.SHOWCASE:
                variants[CardVariantType.SHOWCASE] = variant;
                break;
        }

        return <CardMarketPrices variants={variants} className="modal-prices" />;
    };

    const renderCardImage = (variant: CardVariant) => {
        const frontIsLandscape = variant.images.front.orientation === OrientationType.LANDSCAPE;
        const backIsLandscape = variant.images.back?.orientation === OrientationType.LANDSCAPE;
        const sameOrientation = frontIsLandscape === backIsLandscape;

        const orientation = getImageOrientation(variant);
        const orientationClass = sameOrientation ? 'same-orientation' : 'different-orientation';
        const flipperClass = `card-flipper ${showBackImage ? 'flipped' : ''} ${orientationClass}`;

        return (
            <div className={`game-card-modal__image ${orientation}`}>
                <div className={flipperClass}>
                    <div className="card-face front">
                        <CardImage
                            image={variant.images.front}
                            name={card?.name || ''}
                        />
                    </div>
                    {variant.images.back && (
                        <div className={`card-face back ${orientationClass}`}>
                            <CardImage
                                image={variant.images.back}
                                name={`${card?.name} (Back)`}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <Modal show centered onHide={onClose} dialogClassName="game-card-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Loading...</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Spinner animation="border" variant="primary" />
                </Modal.Body>
            </Modal>
        );
    }

    if (!card || filteredVariants.length === 0) {
        return (
            <Modal show centered onHide={onClose} dialogClassName="game-card-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>No card data available</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <Modal show centered onHide={onClose} dialogClassName="game-card-modal">
            <Modal.Header closeButton>
                <Modal.Title>{card.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="game-card-modal__content">
                    {filteredVariants.length > 1 && (
                        <Nav className="custom-tabs">
                            {filteredVariants.map((variant, index) => (
                                <Nav.Item key={index}>
                                    <Nav.Link
                                        className={`custom-tab ${activeVariant === index ? 'active' : ''}`}
                                        onClick={() => setActiveVariant(index)}
                                    >
                                        {capitalize(variant.variantType || '')}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    )}
                    {renderCardImage(filteredVariants[activeVariant])}
                    <div className="mt-3">
                        {renderPrices(filteredVariants[activeVariant])}
                    </div>
                    {card.images.back && (
                        <Button
                            size="sm"
                            onClick={() => setShowBackImage(!showBackImage)}
                            className="mt-3"
                        >
                            {showBackImage ? "Show Front" : "Show Back"}
                        </Button>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CardModal;
