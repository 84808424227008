import React from 'react';
import { getCardVariantTypeDisplay } from '../../../../utils/cardUtils';
import { CardVariantData, SaleCardData } from '../../../../types/saleListTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { CardVariantType } from '../../../../types/cardType';
import { useAspectImages } from '../../../../hooks/useAspectImages';

interface SaleCardProps {
    saleCard: SaleCardData;
    wantListVariantIds: Set<string>;
    variantType?: CardVariantType;
    onCardClick?: (saleCard: SaleCardData) => void;
}

const SaleCard: React.FC<SaleCardProps> = ({ saleCard, wantListVariantIds, variantType, onCardClick }) => {
    const { getAspectImageUrl, loading: aspectsLoading } = useAspectImages();

    const handleCardClick = async () => {
        onCardClick?.(saleCard);
    };

    const renderQuantityBadge = () => {
        if (!variantType) return null;

        const count = saleCard.variants[variantType]?.saleCount;
        if (!count || count <= 1) return null;

        return (
            <div className="sale-count-badge">
                ×{count}
            </div>
        );
    };

    const renderVariants = () => {
        if (variantType) {
            const variantData = saleCard.variants[variantType];
            if (!variantData?.saleCount) return null;

            return (
                <div className="sale-card__variant">
                    {renderPrice(
                        variantData.priceType,
                        variantData.fixedPriceInCents,
                        variantData.marketAdjustedPriceInCents
                    )}
                </div>
            );
        }

        return (
            <div className="sale-card__variants">
                {(Object.entries(saleCard.variants) as [CardVariantType, CardVariantData][]).map(([variantType, variantData]) => (
                    <div key={variantData.id} className={`sale-card__variant ${wantListVariantIds.has(variantData.id) ? 'sale-card__variant--wanted' : ''}`}>
                        <div className="variant-info">
                            <span className="variant-count">{variantData.saleCount}x</span>
                            <span className="variant-type">
                                {getCardVariantTypeDisplay(variantType)}
                                {wantListVariantIds.has(variantData.id) && <span className="wanted-badge" title="In your want list">★</span>}
                            </span>
                        </div>
                        <div className="text-right">
                            {renderPrice(variantData.priceType, variantData.fixedPriceInCents, variantData.marketAdjustedPriceInCents)}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderPrice = (priceType: string, fixedPriceInCents?: number | null, marketAdjustedPriceInCents?: number | null) => {
        switch (priceType) {
            case 'FIXED':
                return (
                    <span className="variant-price">
                        <FontAwesomeIcon icon={faDollarSign} />
                        {(fixedPriceInCents! / 100).toFixed(2)}
                    </span>
                );
            case 'MARKET':
                if (!marketAdjustedPriceInCents) return <span className="variant-price">Market Price</span>;
                return <span className="variant-price">
                    <span className="whitespace-nowrap"><FontAwesomeIcon icon={faDollarSign} />{(marketAdjustedPriceInCents / 100).toFixed(2)}</span> (Market)
                </span>;
            case 'CONTACT':
                return <span className="variant-price">Contact for Price</span>;
            default:
                return null;
        }
    };

    return (
        <article
            className={`sale-card sale-card--grouped ${variantType &&
                saleCard.variants[variantType]?.id &&
                wantListVariantIds.has(saleCard.variants[variantType].id)
                ? 'sale-card--wanted'
                : ''
                } ${onCardClick ? 'clickable' : ''}`}
            onClick={onCardClick ? handleCardClick : undefined}>
            <header className="sale-card__header">
                <span className="sale-card__number" aria-label="Card Number">
                    {saleCard.card.standardCardNumber}
                </span>
                <h3 className="sale-card__name">
                    {saleCard.card.name}
                    {variantType &&
                        saleCard.variants[variantType]?.id &&
                        wantListVariantIds.has(saleCard.variants[variantType].id) && (
                            <span className="wanted-badge" title="In your want list">★</span>
                        )}
                </h3>
                <div className="sale-card__aspects">
                    {!aspectsLoading && saleCard.card.aspects?.map((aspect, index) => (
                        <img
                            key={index}
                            src={getAspectImageUrl(aspect)}
                            alt={aspect}
                            title={aspect}
                            className="aspect-icon"
                        />
                    ))}
                </div>
                {renderQuantityBadge()}
            </header>
            {renderVariants()}
        </article>
    );
};

export default SaleCard;
