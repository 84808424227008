import React, { useState } from 'react';
import { useCreateOfferMutation, OfferTypeEnum } from '../../graphql/generated/graphql';
import OfferModal, { VariantItem, CardItem, SelectedCard } from './_shared/OfferModal';
import { SaleListVariantPriceType } from '../../types/saleListTypes';
import { CardVariantData } from '../../types/wantListTypes';
import { WantListType } from '../../types/wantListTypes';
import { mapSaleListVariantPriceTypeToApi } from '../../utils/apiMapperUtils';
import { CardVariantType } from '../../types/cardType';
interface CreateSellOfferModalProps {
    show: boolean;
    onHide: () => void;
    recipientHandle: string;
    wantList: WantListType;
}

const CreateSellOfferModal: React.FC<CreateSellOfferModalProps> = ({
    show,
    onHide,
    recipientHandle,
    wantList
}) => {
    const [createOffer] = useCreateOfferMutation();
    const [selectedCards, setSelectedCards] = useState<Array<SelectedCard>>([]);
    const [note, setNote] = useState('');

    const availableCards: Array<CardItem> = wantList.cards.map(wantCard => ({
        id: wantCard.id,
        name: wantCard.card.name,
        standardFullCardNumber: wantCard.card.standardCardNumber,
        setId: wantCard.card.cardSetId,
        variants: (Object.entries(wantCard.variants) as [CardVariantType, CardVariantData][]).map(([variantType, variant]) => ({
            id: variant.id,
            isWanted: false,
            type: variantType,
            quantity: variant.wantCount,
            priceType: SaleListVariantPriceType.MARKET,
            fixedPriceInCents: null,
            marketPriceInCents: wantCard.card.variants[variantType]?.currentMarketPrice?.priceCents || null
        }))
    }));

    const handleAddCard = (card: CardItem, variant: VariantItem) => {
        setSelectedCards(prev => [...prev, {
            cardId: card.id,
            cardName: card.name,
            cardNumber: card.standardFullCardNumber,
            variantId: variant.id,
            variantType: variant.type,
            quantity: 1,
            priceType: SaleListVariantPriceType.MARKET,
            fixedPriceInCents: null,
            marketPriceInCents: variant.marketPriceInCents,
            maxQuantity: variant.quantity
        }]);
    };

    const handleSubmit = async () => {
        try {
            const result = await createOffer({
                variables: {
                    input: {
                        recipientHandle,
                        offerType: OfferTypeEnum.Sell,
                        note,
                        offerItems: selectedCards.map(card => ({
                            variantId: card.variantId,
                            quantity: card.quantity,
                            priceType: mapSaleListVariantPriceTypeToApi(card.priceType),
                            fixedPriceInCents: card.priceType === SaleListVariantPriceType.MARKET ? card.marketPriceInCents : card.fixedPriceInCents
                        }))
                    }
                }
            });

            if (result.data?.createOffer?.offer) {
                onHide();
            } else if (result.data?.createOffer?.errors) {
                // Handle validation errors
                console.error('Validation errors:', result.data.createOffer.errors);
                // TODO show errors in modal
            }
        } catch (error) {
            // Handle GraphQL/network errors
            console.error('Failed to create offer:', error);
        }
    };

    return (
        <OfferModal
            show={show}
            onHide={onHide}
            title="Create Sell Offer"
            availableCards={availableCards}
            selectedCards={selectedCards}
            onAddCard={handleAddCard}
            onRemoveCard={(variantId) =>
                setSelectedCards(prev => prev.filter(c => c.variantId !== variantId))
            }
            onQuantityChange={(variantId, quantity) =>
                setSelectedCards(prev => prev.map(c =>
                    c.variantId === variantId ? { ...c, quantity } : c
                ))
            }
            onPriceChange={(variantId, priceType, fixedPriceInCents) =>
                setSelectedCards(prev => prev.map(c =>
                    c.variantId === variantId
                        ? { ...c, priceType, fixedPriceInCents }
                        : c
                ))
            }
            mode="sell"
            onNoteChange={setNote}
            onSubmit={handleSubmit}
        />
    );
};

export default CreateSellOfferModal;
