import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UpdateCollectionVariantInventoryDocument, UpdateCollectionVariantInventoryMutation, useUpdateWantListVariantCountMutation } from '../../../graphql/generated/graphql';
import { useSortedCardInventory } from '../../../hooks/useSortedCardInventory';
import { InventoryCardTableHeader } from './InventoryCardTableHeader';
import { InventoryCardTableRow } from './InventoryCardTableRow';
import { getCardVariantTypeDisplay, orderedVariantTypes } from '../../../utils/cardUtils';
import CardModal from '../../card/CardModal';
import { UserCardInventory } from '../../../types/inventoryTypes';
import { CardVariantType } from '../../../types/cardType';
import { Button, Modal } from 'react-bootstrap';
import { WantListInfo } from './CollectionPage';
export interface InventoryCardTableProps {
    cards: UserCardInventory[];
    isEditMode: boolean;
    isOwner: boolean;
    wantListInfo: WantListInfo | null;
    onInventoryUpdate: () => void;
    onWantListUpdate: () => void;
}

export interface VariantColumn {
    type: CardVariantType;
    label: string;
    sortKey: `${CardVariantType}Inventory`;
}

export const variantColumns: VariantColumn[] = orderedVariantTypes.map(type => ({
    type,
    label: getCardVariantTypeDisplay(type),
    sortKey: `${type}Inventory` as const,
}));

const InventoryCardTable: React.FC<InventoryCardTableProps> = ({
    cards,
    isEditMode,
    isOwner,
    wantListInfo,
    onInventoryUpdate,
    onWantListUpdate
}) => {
    const { sortedItems, sortColumn, sortDirection, handleSort } = useSortedCardInventory(cards);
    const [updateCollectionVariantInventory] = useMutation(UpdateCollectionVariantInventoryDocument);
    const [selectedCard, setSelectedCard] = useState<UserCardInventory | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [variantToRemove, setVariantToRemove] = useState<number | null>(null);
    const [updateWantListVariantCount] = useUpdateWantListVariantCountMutation();

    const promptRemovalFromWantList = (variantId: number) => {
        setVariantToRemove(variantId);
        setShowRemoveModal(true);
    };

    const handleRemoveFromWantList = async () => {
        if (!variantToRemove || !wantListInfo) return;
        try {
            await updateWantListVariantCount({
                variables: {
                    wantListId: wantListInfo.id,
                    cardVariantId: variantToRemove.toString(),
                    quantity: 0
                }
            });
            onWantListUpdate();
        } catch (error) {
            console.error('Failed to remove from want list:', error);
        } finally {
            setShowRemoveModal(false);
            setVariantToRemove(null);
        }
    };

    const handleCardClick = (card: UserCardInventory) => {
        setSelectedCard(card);
    };

    const handleCloseModal = () => {
        setSelectedCard(null);
    };

    const handleInventoryUpdate = async (variantId: number, field: 'inventory' | 'missing', newValue: number | null, oldValue: number | null): Promise<UpdateCollectionVariantInventoryMutation> => {
        try {
            let missingOverrideCount: number | null = null;
            let inventory: number | null = null;

            if (field === 'missing') {
                missingOverrideCount = newValue;
            } else {
                inventory = newValue;
            }
            console.log(`Updating ${field} for variant ${variantId} from ${oldValue} to ${newValue}`);
            const result = await updateCollectionVariantInventory({
                variables: {
                    variantId,
                    inventory,
                    missingOverrideCount,
                }
            });

            if (result.errors) {
                console.error('Error updating inventory:', result.errors);
            } else {
                onInventoryUpdate();
            }

            const resultData = result.data as UpdateCollectionVariantInventoryMutation;
            if (wantListInfo?.variantIds.has(variantId.toString()) &&
                resultData.updateCollectionVariantInventory?.collectionItemInventory?.missing.count === 0) {
                promptRemovalFromWantList(variantId);
            }
            return resultData;
        } catch (error) {
            console.error('Error updating inventory:', error);
            throw error;
        }
    };

    return (
        <div className="inventory-table">
            <table>
                <InventoryCardTableHeader
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onSort={handleSort}
                />
                <tbody>
                    {sortedItems.map((cardInventory) => (
                        <InventoryCardTableRow
                            key={cardInventory.id}
                            cardInventory={cardInventory}
                            isEditMode={isEditMode}
                            isOwner={isOwner}
                            onInventoryUpdate={handleInventoryUpdate}
                            variantColumns={variantColumns}
                            onCardSelect={() => handleCardClick(cardInventory)}
                            wantListVariantIds={wantListInfo?.variantIds || null}
                        />
                    ))}
                </tbody>
            </table>
            {selectedCard && (
                <CardModal
                    card={selectedCard.card}
                    loading={false}
                    onClose={handleCloseModal}
                />
            )}
            <Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)} className="modal-dark">
                <Modal.Header closeButton>
                    <Modal.Title>Remove from Want List?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You no longer need this card. Would you like to remove it from your want list?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-cancel" onClick={() => setShowRemoveModal(false)}>
                        Keep on List
                    </Button>
                    <Button variant="outline-danger" onClick={handleRemoveFromWantList}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default InventoryCardTable;
