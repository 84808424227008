import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { SaleListItemConditionEnum, useUpsertSaleListVariantMutation } from '../../../../../graphql/generated/graphql';
import { useSaleList } from '../SaleListContext';
import SaleCardPriceInput from '../SaleCardPriceInput';
import { getCardVariantTypeDisplay } from '../../../../../utils/cardUtils';
import { CardVariantType } from '../../../../../types/cardType';
import { SaleListVariantPriceType } from '../../../../../types/saleListTypes';
import { mapSaleListVariantPriceTypeToApi } from '../../../../../utils/apiMapperUtils';
import { CardTableCardVariant } from './CardTable';
import { SaleListCardFilterMode } from '../../../../../types/saleListTypes';

interface CardVariantCellProps {
    variant: CardTableCardVariant;
    variantType: CardVariantType;
    cardName: string;
    saleListId: string;
    filterMode: SaleListCardFilterMode;
    onItemUpdated: () => void;
}

const FILTER_CHECKS = {
    [SaleListCardFilterMode.Extra]: (variant: CardTableCardVariant) => variant.extraCount > 0,
    [SaleListCardFilterMode.Own]: (variant: CardTableCardVariant) => variant.inventoryCount > 0
} as const;

const CardVariantCell: React.FC<CardVariantCellProps> = ({
    variant,
    variantType,
    cardName,
    saleListId,
    filterMode,
    onItemUpdated
}) => {
    const [updateSaleListVariantCount] = useUpsertSaleListVariantMutation();
    const [showModal, setShowModal] = useState(false);
    const [priceType, setPriceType] = useState<SaleListVariantPriceType>(SaleListVariantPriceType.FIXED);
    const [fixedPriceInCents, setFixedPriceInCents] = useState<number | null>(0);
    const { updateVariant } = useSaleList();

    if (!variant || (filterMode !== SaleListCardFilterMode.All && !FILTER_CHECKS[filterMode](variant))) {
        return <td className="sale-list-variant">—</td>;
    }

    if (variant.saleCount > 0) {
        return <td className="sale-list-variant added">Added</td>;
    }

    const handlePriceChange = (newPriceType: SaleListVariantPriceType, newFixedPriceInCents: number | null) => {
        setPriceType(newPriceType);
        setFixedPriceInCents(newFixedPriceInCents);
    };

    const handleSubmit = async () => {
        const newVariant = {
            saleCount: 1,
            priceType,
            fixedPriceInCents,
            condition: SaleListItemConditionEnum.NearMint
        };

        updateVariant(variant.id, newVariant);

        try {
            const response = await updateSaleListVariantCount({
                variables: {
                    saleListId,
                    cardVariantId: variant.id,
                    quantity: 1,
                    priceType: mapSaleListVariantPriceTypeToApi(priceType),
                    fixedPriceInCents,
                    condition: SaleListItemConditionEnum.NearMint
                }
            });

            if (response.data?.upsertSaleListVariant?.saleListItem) {
                onItemUpdated();
                setShowModal(false);
            }
        } catch (error) {
            updateVariant(variant.id, variant);
            console.error('Failed to update sale list:', error);
        }
    };

    const resetModal = () => {
        setShowModal(false);
        setPriceType(SaleListVariantPriceType.FIXED);
        setFixedPriceInCents(0);
    };

    return (
        <td className="sale-list-variant">
            <Button
                variant="outline-add"
                size="sm"
                onClick={() => setShowModal(true)}
                title="Add to sale list"
            >
                Add
            </Button>

            <Modal show={showModal} onHide={resetModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add to Sale List
                        <small className="modal-subtitle">
                            {cardName} - {getCardVariantTypeDisplay(variantType)}
                        </small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="variant-edit-modal">
                        <div className="variant-price-section">
                            <SaleCardPriceInput
                                priceType={priceType}
                                fixedPriceInCents={fixedPriceInCents}
                                onChange={handlePriceChange}
                                variantId={variant.id}
                                marketPrice={variant.marketPrice}
                            />
                        </div>

                        <div className="variant-actions mt-3">
                            <Button
                                variant="outline-cancel"
                                onClick={resetModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outline-primary"
                                onClick={handleSubmit}
                            >
                                Add to Sale List
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </td>
    );
};

export default CardVariantCell;
