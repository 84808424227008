import React, { useEffect, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { useGetUserCardSetCollectionQuery } from '../../../../../graphql/generated/graphql';
import CardTableHeader from './CardTableHeader';
import CardTableRow from './CardTableRow';
import { CardSetCodeEnum } from '../../../../../types/cardSetType';
import { SaleListType, SaleListVariantPriceType } from '../../../../../types/saleListTypes';
import { Card, CardVariantType, VariantMarketPrice } from '../../../../../types/cardType';
import { useCards } from '../../../../../contexts/CardContext';
import { mapCardSetCodeToApiCardSetCode } from '../../../../../utils/apiMapperUtils';
import { SaleListCardFilterMode } from '../../../../../types/saleListTypes';

interface CardTableProps {
    selectedSet: CardSetCodeEnum | null;
    searchTerm: string;
    filterMode: SaleListCardFilterMode;
    effectiveHandle: string | undefined;
    onItemUpdated: () => void;
    existingSaleList: SaleListType;
}

export interface CardTableCardVariant {
    id: string;
    fixedPriceInCents: number | null;
    priceType: SaleListVariantPriceType;
    extraCount: number;
    saleCount: number;
    inventoryCount: number;
    marketPrice: VariantMarketPrice | null;
}

export interface CardTableCard {
    id: string;
    name: string;
    standardCardNumber: number;
    variants: Record<CardVariantType, CardTableCardVariant>;
}

const filterChecks = {
    [SaleListCardFilterMode.Extra]: (variants: CardTableCardVariant[]) =>
        variants.some(v => v.extraCount > 0),
    [SaleListCardFilterMode.Own]: (variants: CardTableCardVariant[]) =>
        variants.some(v => v.inventoryCount > 0),
    [SaleListCardFilterMode.All]: () => true
} as const

const CardTable: React.FC<CardTableProps> = ({
    selectedSet,
    searchTerm,
    filterMode,
    effectiveHandle,
    onItemUpdated,
    existingSaleList
}) => {
    const { ensureSetLoaded, cardsById, isLoadingSet } = useCards();

    useEffect(() => {
        if (selectedSet) {
            ensureSetLoaded(selectedSet)
        }
    }, [selectedSet, ensureSetLoaded])

    const { data, loading: queryLoading, error } = useGetUserCardSetCollectionQuery({
        variables: {
            userHandle: effectiveHandle,
            cardSetCode: mapCardSetCodeToApiCardSetCode(selectedSet!)
        },
        skip: !effectiveHandle || !selectedSet
    });

    const saleCardLookup = useMemo(() => {
        return existingSaleList.cards.reduce((acc, card) => {
            acc[card.card.id] = card;
            return acc;
        }, {} as Record<string, typeof existingSaleList.cards[0]>);
    }, [existingSaleList]);

    const cards = useMemo(() => {
        if (!data?.userCardSetCollection?.nodes) return null;

        const cardIds = data.userCardSetCollection.nodes.map(node => node?.id).filter((id): id is string => !!id);
        const allCardsLoaded = cardIds.every(id => cardsById[id]);
        if (!allCardsLoaded) return null;

        return data.userCardSetCollection.nodes.map(node => {
            if (!node) return null;

            // Find matching sale card if it exists
            const saleCard = saleCardLookup[node.id];

            // Create variant map only for variants that exist
            const variants: Partial<Record<CardVariantType, CardTableCardVariant>> = {};

            const variantMappings = [
                { inventory: node.standardInventory, type: CardVariantType.STANDARD },
                { inventory: node.foilInventory, type: CardVariantType.FOIL },
                { inventory: node.hyperspaceInventory, type: CardVariantType.HYPERSPACE },
                { inventory: node.hyperspaceFoilInventory, type: CardVariantType.HYPERSPACE_FOIL },
                { inventory: node.showcaseInventory, type: CardVariantType.SHOWCASE }
            ];

            variantMappings.forEach(({ inventory, type }) => {
                if (inventory?.variantId) {
                    variants[type] = {
                        id: inventory.variantId.toString(),
                        extraCount: inventory.extraCount || 0,
                        saleCount: saleCard?.variants[type]?.saleCount || 0,
                        inventoryCount: inventory.inventory || 0,
                        fixedPriceInCents: saleCard?.variants[type]?.fixedPriceInCents || null,
                        priceType: saleCard?.variants[type]?.priceType || SaleListVariantPriceType.MARKET,
                        marketPrice: saleCard
                            ? saleCard.card.variants[type]?.currentMarketPrice || null
                            : cardsById[node.id]?.variants[type]?.currentMarketPrice || null
                    };
                }
            });

            const card = saleCard ? saleCard.card : cardsById[node.id] as Card;
            return {
                id: node.id,
                name: card.name,
                standardCardNumber: card.standardCardNumber || 0,
                variants
            };
        }).filter((card): card is CardTableCard => card !== null);
    }, [data?.userCardSetCollection, cardsById, saleCardLookup]);

    const filteredCards = useMemo(() => {
        if (!cards) return null;

        return cards.filter(card => {
            const nameMatch = card.name.toLowerCase().includes(searchTerm.toLowerCase());
            const variants = Object.values(card.variants);
            return nameMatch && filterChecks[filterMode](variants);
        });
    }, [cards, searchTerm, filterMode]);

    const variantColumns = [
        CardVariantType.STANDARD,
        CardVariantType.FOIL,
        CardVariantType.HYPERSPACE,
        CardVariantType.HYPERSPACE_FOIL,
        CardVariantType.SHOWCASE
    ];

    if (!selectedSet) {
        return (
            <div className="text-center p-5">
                <h3>Please select a card set to begin.</h3>
            </div>
        );
    }

    if (queryLoading || !cards || !filteredCards || isLoadingSet(selectedSet!)) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    if (error) return <div>Error: {error.message}</div>;
    if (filteredCards.length === 0) {
        return (
            <div className="text-center p-5">
                <h3>
                    {searchTerm
                        ? 'No cards found matching your search criteria.'
                        : 'No cards available in this set.'}
                </h3>
            </div>
        )
    }

    const saleListId = existingSaleList?.id;

    return (
        <div className="sale-list-card-picker-table">
            <table>
                <CardTableHeader variantColumns={variantColumns} />
                <tbody>
                    {filteredCards.map((card) => (
                        <CardTableRow
                            key={card.id}
                            card={card}
                            variantColumns={variantColumns}
                            saleListId={saleListId || ''}
                            filterMode={filterMode}
                            onItemUpdated={onItemUpdated}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CardTable;
