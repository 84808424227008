import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCardSets } from '../contexts/CardSetContext';
import { useAuth } from '../contexts/AuthContext';
import HomeBannerCard from './HomeBannerCard';
import { CardSetSkeleton } from './shared/CardSetCard';
import CardSetCard from './shared/CardSetCard';

const Home: React.FC = () => {
    const { cardSets, loading, error } = useCardSets();
    const { isAuthenticated, user } = useAuth();

    const renderBannerContent = () => (
        isAuthenticated ? (
            <>
                <div className="flex justify-between items-start">
                    <div>
                        <Card.Title as="h3" className="mb-3">
                            Welcome back, {user?.name}! 👋
                        </Card.Title>
                    </div>
                </div>
            </>
        ) : (
            <>
                <Card.Title as="h3">
                    Start Your Collection Journey!
                </Card.Title>
                <Card.Text>
                    Want to track your card collection, share your want list, and trade with the community?{' '}
                    <Link to="/login" className="nav__link">Log in</Link> to get started!
                </Card.Text>
            </>
        )
    );

    const renderCardSets = () => {
        if (loading) {
            <div className="grid grid--cards">
                {[...Array(3)].map((_, index) => (
                    <CardSetSkeleton key={index} />
                ))}
            </div>
        }

        if (error) {
            return (
                <div className="banner banner--error">
                    Error: {error.message}
                </div>
            );
        }

        return (
            <div className="grid grid--cards">
                {cardSets?.slice(0, 3).map((set) => (
                    <CardSetCard
                        key={set.id}
                        cardSet={set}
                    />
                ))}
            </div>
        );
    };

    return (
        <Container>
            <HomeBannerCard>
                {renderBannerContent()}
            </HomeBannerCard>

            <section className="section">
                <h2 className="section__title">Explore Card Sets</h2>
                {renderCardSets()}
            </section>
        </Container>
    );
};

export default Home;
