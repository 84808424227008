export type AspectDetailsType = {
    value: AspectType;
    label: string;
    image: string;
}

export enum AspectType {
    COMMAND = 'Command',
    HEROISM = 'Heroism',
    VILLAINY = 'Villainy',
    CUNNING = 'Cunning',
    VIGILANCE = 'Vigilance',
    AGGRESSION = 'Aggression'
}
