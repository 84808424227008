import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../constants/icons';
import { useAuth, User } from '../../contexts/AuthContext';
import NavLoginButton from '../auth/NavLoginButton';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface NavItem {
    to: string;
    icon: IconDefinition;
    label: string;
    requiresAuth?: boolean;
    group: 'main' | 'collection' | 'trading';
}

const navItems: NavItem[] = [
    { to: '/card-sets', icon: Icons.CardSets, label: 'Card Sets', group: 'main' },

    { to: '/collections/my', icon: Icons.Collection, label: 'My Collection', requiresAuth: true, group: 'collection' },
    { to: '/collections/my/want', icon: Icons.WantList, label: 'My Want List', requiresAuth: true, group: 'collection' },
    { to: '/collections/my/sell', icon: Icons.SaleList, label: 'My Sale List', requiresAuth: true, group: 'collection' },

    { to: '/collections', icon: Icons.SharedCollections, label: 'Shared Collections', group: 'trading' },
    { to: '/trading', icon: Icons.Trading, label: 'Trading', group: 'trading' }
];

const NavSeparator = () => (
    <div className="nav-separator d-none d-lg-block" />
);

const NavLink: React.FC<NavItem> = ({ to, icon, label }) => (
    <Nav.Link as={Link} to={to}>
        <FontAwesomeIcon icon={icon} fixedWidth className="me-2" />
        {label}
    </Nav.Link>
);

const UserDropdownDesktop: React.FC<{ user: User | null; onLogout: () => void }> = ({ user, onLogout }) => (
    <div className="d-none d-lg-block">
        <Dropdown align="end">
            <Dropdown.Toggle variant="link" className="nav-link p-0 d-flex align-items-center gap-2" id="profile-dropdown">
                <div className="rounded-circle bg-secondary d-flex align-items-center justify-content-center"
                    style={{ width: '2.5rem', height: '2.5rem' }}>
                    <span className="text-white">{user?.name?.[0]?.toUpperCase()}</span>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-dark p-2">
                <div className="px-3 py-2 mb-2 border-bottom border-secondary">
                    <div className="fw-medium">{user?.name}</div>
                    <div className="text-muted small">{user?.email}</div>
                </div>
                <Dropdown.Item as={Link} to="/profile">
                    <FontAwesomeIcon icon={Icons.Profile} fixedWidth className="me-2" />
                    Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/preferences">
                    <FontAwesomeIcon icon={Icons.Settings} fixedWidth className="me-2" />
                    Preferences
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={onLogout} className="text-danger">
                    <FontAwesomeIcon icon={Icons.SignOut} fixedWidth className="me-2" />
                    Logout
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </div>
);

const UserDropdownMobile: React.FC<{ user: User | null; onLogout: () => void }> = ({ user, onLogout }) => (
    <div className="d-lg-none border-top border-secondary mt-2 pt-2">
        <div className="px-3 py-2 d-flex align-items-center gap-2">
            <div>
                <div className="fw-medium">{user?.name}</div>
                <div className="text-muted small">{user?.email}</div>
            </div>
        </div>
        <Nav.Link as={Link} to="/profile" className="mt-2">
            <FontAwesomeIcon icon={Icons.Profile} fixedWidth className="me-2" />
            Profile
        </Nav.Link>
        <Nav.Link as={Link} to="/preferences" className="mt-2">
            <FontAwesomeIcon icon={Icons.Settings} fixedWidth className="me-2" />
            Preferences
        </Nav.Link>
        <Nav.Link onClick={onLogout} className="text-danger">
            <FontAwesomeIcon icon={Icons.SignOut} fixedWidth className="me-2" />
            Logout
        </Nav.Link>
    </div>
);

const Header: React.FC = () => {
    const { isAuthenticated, logout, user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [expanded, setExpanded] = useState(false);

    const handleLogout = async () => {
        await logout();
        navigate('/', { replace: true });
    };

    const closeNav = () => setExpanded(false);

    return (
        <Navbar bg="dark" variant="dark" expand="lg" expanded={expanded} onToggle={setExpanded}>
            <Container>
                <Navbar.Brand as={Link} to="/">SWU Dashboard</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" onClick={closeNav}>
                        {navItems
                            .filter(item => !item.requiresAuth || isAuthenticated)
                            .map((item, index, filteredItems) => (
                                <React.Fragment key={item.to}>
                                    <NavLink {...item} />
                                    {index < filteredItems.length - 1 &&
                                        filteredItems[index + 1].group !== item.group &&
                                        <NavSeparator />}
                                </React.Fragment>
                            ))}
                    </Nav>
                    <Nav onClick={closeNav}>
                        {isAuthenticated ? (
                            <>
                                <UserDropdownDesktop user={user} onLogout={handleLogout} />
                                <UserDropdownMobile user={user} onLogout={handleLogout} />
                            </>
                        ) : (
                            <NavLoginButton from={location.pathname} />
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
