import { Card, CardVariantType } from './cardType';


export enum SaleListRoundingType {
    NO_ROUNDING = 'NO_ROUNDING',
    UP = 'UP'
}

export enum SaleListCardFilterMode {
    All = 'all',
    Own = 'own',
    Extra = 'extra'
}

export interface SaleListSettingsType {
    banner: string | null;
    marketPricingSettings: MarketPricingSettingsType | null;
}

export type RoundingValue = 0 | 25 | 50 | 100;

export interface MarketPricingSettingsType {
    percentageAdjustment: number;
    rounding: {
        type: SaleListRoundingType;
        value: RoundingValue;
    };
}

export interface SaleListType {
    id: string;
    cards: SaleCardData[];
    settings: SaleListSettingsType;
}

export enum SaleListVariantPriceType {
    FIXED = 'FIXED',
    MARKET = 'MARKET',
    CONTACT = 'CONTACT'
}

export enum SaleListGroupBy {
    CardNumber = 'CARD_NUMBER',
    VariantType = 'VARIANT_TYPE'
}

export interface CardVariantData {
    id: string;
    saleCount: number;
    priceType: SaleListVariantPriceType;
    fixedPriceInCents: number | null;
    marketAdjustedPriceInCents: number | null;
}

export interface SaleCardData {
    card: Card;
    variants: Partial<Record<CardVariantType, CardVariantData>>;
}
