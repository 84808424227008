import React, { useMemo, useState } from 'react';
import EditableWantCard from './EditableWantCard';
import { WantListCardData, WantListType } from '../../../../types/wantListTypes';
import { useCardSets } from '../../../../contexts/CardSetContext';
import { compareCards } from '../../../../utils/wantListCardUtils';

interface EditWantListItemsTableProps {
    wantList: WantListType;
    onItemUpdated?: () => void;
}

const EditWantListItemsTable: React.FC<EditWantListItemsTableProps> = ({
    wantList,
    onItemUpdated
}) => {
    const { cardSets } = useCardSets();
    const [collapsedSets, setCollapsedSets] = useState<Set<string>>(new Set());

    const setNames = useMemo(() => {
        return cardSets?.reduce((acc, set) => {
            if (set?.id) acc[set.id] = set.name;
            return acc;
        }, {} as Record<string, string>) ?? {};
    }, [cardSets]);

    const toggleSet = (setId: string) => {
        setCollapsedSets(prev => {
            const next = new Set(prev);
            if (next.has(setId)) {
                next.delete(setId);
            } else {
                next.add(setId);
            }
            return next;
        });
    };

    const cardsBySet = wantList.cards.reduce((acc, card) => {
        const setId = card.card.cardSetId;
        if (!acc[setId]) acc[setId] = [];
        acc[setId].push(card);
        return acc;
    }, {} as Record<string, WantListCardData[]>);

    if (wantList.cards.length === 0) {
        return (
            <div className="text-center p-4">
                <p className="mb-0">No cards in want list yet.</p>
            </div>
        );
    }

    return (
        <div className="list-container">
            {Object.entries(cardsBySet).map(([setId, cards]) => (
                <section key={setId}>
                    <h2
                        className={`section__title section__title--collapsible ${collapsedSets.has(setId) ? 'collapsed' : ''
                            }`}
                        onClick={() => toggleSet(setId)}
                    >
                        {setNames[setId] || `Set ${setId}`}
                    </h2>
                    {!collapsedSets.has(setId) && (
                        <div className="section__content mb-4">

                            <div className="list-grid">
                                {cards
                                    .sort(compareCards)
                                    .map(card => (
                                        <EditableWantCard key={card.id} wantedCard={card} wantListId={wantList.id} onItemUpdated={onItemUpdated || (() => { })} />
                                    ))}
                            </div>

                        </div>
                    )}
                </section>
            ))}
        </div>
    );
};

export default EditWantListItemsTable;
