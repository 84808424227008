import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { useGetUsersQuery, User } from '../../../graphql/generated/graphql'
import LoadingSpinner from '../../layout/LoadingSpinner'
import ErrorDisplay from '../../layout/ErrorDisplay'

// Types
interface TableHeaderProps {
    headers: string[]
}

interface UserRowProps {
    user: Partial<User>
    onEdit: (e: React.MouseEvent, userId: string) => void
}

const TableHeader: React.FC<TableHeaderProps> = ({ headers }) => (
    <thead className="table-header">
        <tr>
            {headers.map(header => (
                <th key={header} style={header === 'Actions' ? { width: '80px' } : undefined}>
                    {header}
                </th>
            ))}
        </tr>
    </thead>
)

const UserRow: React.FC<UserRowProps> = ({ user, onEdit }) => (
    <tr key={user?.id}>
        <td>{user?.email}</td>
        <td>
            {user?.handle ? (
                <span>{user.handle}</span>
            ) : (
                <span className="text-muted">—</span>
            )}
        </td>
        <td>
            {user?.roles?.map(role => (
                <span key={role} className="game-card-hover-badge">
                    {role}
                </span>
            ))}
        </td>
        <td>
            <Button
                variant="outline-primary"
                size="sm"
                onClick={(e) => user?.id && onEdit(e, user.id.toString())}
                className="btn-icon"
            >
                <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
        </td>
    </tr>
)

const Users: React.FC = () => {
    const { data, loading, error } = useGetUsersQuery()
    const navigate = useNavigate()

    if (loading) return <LoadingSpinner />
    if (error) return <ErrorDisplay message={error.message} />

    const handleEditClick = (e: React.MouseEvent, userId: string) => {
        e.stopPropagation()
        navigate(`/admin/users/${userId}/edit`)
    }

    const tableHeaders = ['Email', 'Handle', 'Roles', 'Actions']

    return (
        <div className="section">
            <h2 className="section__title">Users</h2>
            <div className="table-container">
                <table className="table">
                    <TableHeader headers={tableHeaders} />
                    <tbody>
                        {data?.users?.nodes?.map(user => (
                            <UserRow
                                key={user?.id}
                                user={user!}
                                onEdit={handleEditClick}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Users
