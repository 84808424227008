import {
    faHome,
    faLayerGroup,
    faBook,
    faExchange,
    faUser,
    faCog,
    faSearch,
    faEye,
    faEnvelope,
    faSignOut,
    faStar,
    faDollarSign,
    faShare
} from '@fortawesome/free-solid-svg-icons';

export const Icons = {
    Home: faHome,
    CardSets: faLayerGroup,
    Collection: faBook,
    SharedCollections: faShare,
    WantList: faStar,
    SaleList: faDollarSign,
    Trading: faExchange,
    Profile: faUser,
    Settings: faCog,
    Search: faSearch,
    View: faEye,
    Contact: faEnvelope,
    SignOut: faSignOut
};
